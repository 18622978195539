import { Divider, Popover } from '@material-ui/core';
import React from 'react';
import { MacaronOption, MacaronOptionsContainer } from './styles';
import DuplicateIcon from './icons/DuplicateIcon';
import TrashIcon from './icons/TrashIcon';
import { Phrase } from '../Phrase';

const MacaronOptionsPopover = (props) => {
    const {macaronId, isOpen, anchorRef, onClose, onDuplicate, onDelete, content = {}} = props;

    const { general = {}, macaron = {} } = content;
    const { colors = {} } = general;
    const { texts = {}} = macaron;
    const backgroundColor = colors.backgroundColor;
    const textColor = colors.generalTextColor;


    const duplicateText = texts.duplicate || {};
    const deleteText = texts.delete || {};

    return <Popover
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
      id={macaronId}
      open={isOpen}
      anchorEl={anchorRef}
      onClose={onClose}
      BackdropProps={{style: {backgroundColor: '#000000cc'}}}
    >
      <MacaronOptionsContainer backgroundColor={backgroundColor}>
      {
            onDuplicate && <>
            <MacaronOption onClick={onDuplicate} textColor={textColor}>
                <DuplicateIcon stroke={textColor}/>
                <span>
                    <Phrase Key={duplicateText ? duplicateText.translationKey : '4f487a77-3bfe-4a01-8d96-2336496f25f7'} default={duplicateText.text || ''}/>
                </span>
            </MacaronOption>
            <Divider/>
            </> 
        }
        <MacaronOption onClick={onDelete} textColor={textColor}>
            <TrashIcon stroke={textColor}/>
            <span>
            <Phrase Key={deleteText ? deleteText.translationKey : 'f4a429c9-e594-429e-8900-d910ca991eb3'} default={deleteText.text || ''}/>
            </span>
        </MacaronOption>
        
      </MacaronOptionsContainer>
    </Popover>
}

export default MacaronOptionsPopover;