import styled from "styled-components";
import "../../styles/Snapshot.scss";

/**
 * overlayColor = 'black' / 'white'
 */
export const ModalLayout = styled.div`
  background-color: ${(props) => (props.overlayColor === "black" ? "rgba(0, 0, 0, 0.65)" : "rgba(255, 255, 255, 0.65)")};
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
`;

export const HelpDrawerLayout = styled.div`
  background-color: ${(props) => (props.overlayColor === "black" ? "rgba(0, 0, 0, 0.3)" : "rgba(255, 255, 255, 0.9)")};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
`;

export const MessageStyle = styled.div`
  display: flex;
  height: 35%;
  width: 90%;
  background: ${(props) => props.back.messageBackgroundColor};
  border-radius: 20px;
  z-index: 1001;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #dcdcdc;
`;
