import React from "react";

import { FlowButton } from "../styles/IntroStyle";
import { Phrase } from "../Phrase";
import MacaronIcon from "./icons/MacaronIcon";

const MacaroonFlow = (props) => {
  const { data, onMacaronFlowClick, isMacaronSelected } = props;
  const { home, general } = data || {};

  const btn = (home.buttons.snap) || (home && home.buttons && home.buttons.macaron) || {};
  const btnText = (home && home.buttons && home.buttons.macaron) || {};
  const pageBackgroundColor = general.colors.backgroundColor

  return (
    <FlowButton isSelected={isMacaronSelected} onClick={onMacaronFlowClick} data={btn} pageBackgroundColor={pageBackgroundColor}>
      <MacaronIcon stroke={btn.iconColor}/>
    <Phrase Key={btnText.translationKey} default={btnText.text || ''} />
    </FlowButton>
  );
}

export default MacaroonFlow;