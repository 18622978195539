import React, { useState, useEffect } from "react";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";

const SliderToolTip = createSliderWithTooltip(Slider);
export const RangeSilder = ({ min, max, value, onChange, newColors }) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (!Number.isInteger(value)) return;
    setCurrentValue(value);
  }, [value]);

  let timerId;

  const beforeChange = () => {
    clearTimeout(timerId);
  };
  const onSlideChange = (v) => {
    setCurrentValue(v);
  };

  const onSlideAfterChange = () => {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      onChange(currentValue);
    }, 300);
  };

  return (
    <SliderWrapper>
      <Label {...newColors}>{min}</Label>
      <StyledSlider min={min} max={max} value={currentValue} onChange={onSlideChange} onBeforeChange={beforeChange} onAfterChange={onSlideAfterChange} {...newColors} />
      <Label {...newColors}>{max}</Label>
    </SliderWrapper>
  );
};

const SliderWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  .rc-slider {
    width: 70% !important;
  }
`;

const Label = styled.div`
  color: ${({ generalTextColor }) => generalTextColor || "black"};
`;

const StyledSlider = styled(SliderToolTip)`
  .rc-slider-handle {
    background-color: ${({ iconsColor }) => iconsColor || "white"} !important;
    border: 0.5px solid ${({ buttonBackground }) => buttonBackground || "white"} !important;
  }
  .rc-slider-handle:hover {
    background-color: ${({ iconsColor }) => iconsColor || "white"} !important;
    border-color: ${({ buttonBackground }) => buttonBackground || "white"} !important;
  }
  .rc-slider-track {
    background-color: ${({ iconsColor }) => iconsColor || "white"} !important;
    border: 1px solid #9f9393 !important;
    height: 6px !important;
  }
  .rc-slider-rail {
    background-color: ${({ buttonBackground }) => buttonBackground || "white"} !important;
    border: 1px solid #9f9393 !important;
    height: 6px !important;
  }
`;
