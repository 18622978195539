import React from "react";

import { FramesModeSettings } from "./FramesMode";
import { ImageModeSettings } from "./ImageMode";
import { TextModeSettings } from "./TextMode";

export function EditMode({ data }) {
  const { editMode } = data;

  switch (editMode) {
    case "edit":
      return <ImageModeSettings data={{ ...data }} />;
    case "texts":
      return <TextModeSettings data={{ ...data }} />;
    case "frames":
      return <FramesModeSettings data={{ ...data }} />;
    default:
      return null;
  }
}
