import axios from 'axios';

export function sendEvent(data) {
    return axios.post(`/api/v1/events`, { ...data })
        .then(response => response.data)
        .catch(err => console.log(err))
}

export function sendFeedback(data) {
    return axios.post(`/api/v1/feedback`, { ...data })
        .then(response => response.data)
        .catch(err => console.log(err))
}