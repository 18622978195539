import styled from "styled-components";

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  bottom: 0px;
  background: ${({ bgColor }) => bgColor};
  background-image: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ borderColor }) => borderColor};

  border-radius: 40px;
  padding: 0 12px;
`;

export const OuterButton = styled(Button)`
  box-sizing: border-box;
  background: "transparent";
`;
