import React, { useContext } from "react";
import { TranslationsContext } from "../context";

export function Phrase(props) {
  const { data, changeWith } = props.param || {};
  const { translations } = useContext(TranslationsContext);

    const {replaceValues = []} = props;
    let text = !translations || !translations[props.Key] ? props.default : translations[props.Key];
    replaceValues.forEach(({searchValue, replaceWith}) => {
      text = text.replace(searchValue, replaceWith);
    })
    if (text && changeWith && data) {
      const lines = text.split(changeWith);

      return <span>
        {
          lines.map((line, i) => (
            <span key={i}>
              {line} {i === 0 && data}
            </span>
          ))
        }
      </span>
    } else {
      const lines = text.split("\n");
      if (lines.length < 2) return <span>{lines}</span>;

      return <span>
        {lines.map((line, i) => (
        <span key={i}>
          {line}
          <br />
        </span>
      ))}
      </span>
    }
}
