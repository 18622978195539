export function isBase64(str) {
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return window.btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

export const checkDevice = () => {
  let cpu = navigator.hardwareConcurrency;
  let memory = navigator.deviceMemory;
  return `${cpu} ${memory}`;
};

export const isAndroid = () => {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("android") > -1;
};

export function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

export function downloadBase64File(base64Data, filename) {
  let element = document.createElement("a");
  element.setAttribute("href", base64Data);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function base64StringToFile(base64String, filename) {
  let arr = base64String.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function canvasToDataStream(canvas) {
  const stream = canvas.toDataURL();
  const binary = atob(stream.split(",")[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/png" });
}

export function image64toCanvasRef(canvasRef, image64, pixelCrop) {
  const canvas = canvasRef; // document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");
  const image = new Image();
  image.src = image64;
  image.onload = function () {
    ctx.drawImage(image, pixelCrop.x, pixelCrop.y, 360, 270);
  };
}

export function imageCanvasToGray(canvasRef, image64, pixelCrop) {
  const canvas = canvasRef;
  const ctx = canvas.getContext("2d");
  ctx.draggable = true;
  const imageData = ctx.getImageData(0, 0, 360, 360 * 0.75);

  for (let i = 0; i < imageData.data.length; i += 4) {
    imageData.data[i + 0] = imageData.data[i + 1] = imageData.data[i + 2] = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
  }
  ctx.putImageData(imageData, 0, 0);
}

export function scaleImageOnCanvas(canvasRef, scaleX, scaleY) {
  const canvas = canvasRef;
  const context = canvas.getContext("2d");
  context.scale(scaleX, scaleY);
}

export function fetch_retry(url, options, n) {
  return fetch(url, options).catch(function (error) {
    if (n === 1) {
      return error;
    }
    return fetch_retry(url, options, n - 1);
  });
}

export function debounce(fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

export function setLocStorage(value) {
  if (navigator.cookieEnabled) {
    let string = JSON.stringify(value);
    let base = window.btoa(encodeURIComponent(string));
    localStorage.setItem("ripples", base);
  }
}

export function getLocStorage() {
  let json = "";
  if (navigator.cookieEnabled) {
    let base = localStorage.getItem("ripples");
    let string = decodeURIComponent(window.atob(base));
    json = JSON.parse(string);
  }

  return json;
}

export function setCookie(cname, cvalue, exdays) {
  let cookieData = getCookie(cname);
  let newData = Object.assign({}, cookieData, cvalue);
  let b64Value = window.btoa(JSON.stringify(newData));

  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + b64Value + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookieHasData = !!decodedCookie && decodedCookie.split(";").length > 0;
  if (cookieHasData) {
    let cnameInCookie = decodedCookie.split(";").filter((e) => e.includes(cname))[0];
    let cnameValue = cnameInCookie && cnameInCookie.split("=")[1];
    return cnameValue ? JSON.parse(window.atob(cnameValue)) : {};
  }

  return {};
}

export function rotate(cx, cy, x, y, angle) {
  var radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) + sin * (y - cy) + cx,
    ny = cos * (y - cy) - sin * (x - cx) + cy;
  return [nx, ny];
}
