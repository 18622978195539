import React, { useContext } from "react";
import {
  HelpContnetStyle,
  CloseBtn,
  HelpContnetTitleStyle,
  HelpContnetBodyStyle,
  HelpContnetBodyRowStyle,
  HelpContnetBodyIconStyle,
  HelpContnetDividerStyle,
} from "../../styles/editStyle";
import { HelpDrawerLayout } from "../../styles/messageStyle";
import { STextBold, SText } from "../../styles/textStyle";
import { Phrase } from "../../Phrase";
import { ContentContext } from "../../../context";
import { getIcon } from "../../TabActions";
import { Animated } from "react-animated-css";

const HelpSection = (props) => {
  const { content } = useContext(ContentContext);
  const { openCloseHelp } = props && props.actions;
  const { isHelpCardOpen } = props && props.data;

  if (!content || !content.general || !content.edit) {
    window.history.back();
    return null;
  }

  const general = content.general;

  // colors
  const buttonBackground = content.home.buttons.snap ? content.home.buttons.snap.backgroundColor : content.home.buttons.library.backgroundColor;
  const newColors = { ...general.colors, buttonBackground };
  const newTextColor = newColors.backgroundColor && ["#fff", "#ffffff", "white"].includes(newColors.backgroundColor) ? "#000" : newColors.backgroundColor;

  const bodyTexts = [
    {
      icon: "edit",
      default: `Position your photo and set a desirable filter`,
      translationKey: "55cf9c3a-0a34-4e10-884c-52d35ac65acf",
    },
    {
      icon: "frames",
      default: `Add / change a frame decoration`,
      translationKey: "61404b6e-0a2c-4b71-8012-172df5795652",
    },
    {
      icon: "texts",
      default: `Add some text to your design in various styles`,
      translationKey: "497806b0-e8c6-4b49-8528-4256e24aed0c",
    },
  ];
  //Text

  return (
    <HelpDrawerLayout overlayColor={"black"}>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={isHelpCardOpen}
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "flex-end",
        }}
      >
        <HelpContnetStyle color={newTextColor}>
          <CloseBtn onClick={() => openCloseHelp()} color={newTextColor} position="right" />
          <HelpContnetTitleStyle>
            <STextBold>
              <Phrase Key={"4a365074-fc22-4ebc-b14a-25f5ec75918d"} default={"HOW IT WORKS"} />
            </STextBold>
          </HelpContnetTitleStyle>
          <HelpContnetBodyStyle>
            {bodyTexts.map((e, i) => {
              return (
                <HelpContnetBodyRowStyle key={i}>
                  <HelpContnetBodyIconStyle>{getIcon(e.icon, { generalTextColor: newTextColor }, false)}</HelpContnetBodyIconStyle>
                  <SText align={"left"}>
                    <Phrase Key={e.translationKey} default={e.default} />
                  </SText>
                </HelpContnetBodyRowStyle>
              );
            })}
            <HelpContnetDividerStyle />
            <HelpContnetBodyRowStyle justify={"center"} top={"none"}>
              <SText align={"left"}>
                <Phrase Key={"733e72b4-b42f-480a-97d5-e8ca082cee30"} default={`When your design is ready, let us know by clicking the "LET’S PRINT" button`} />
              </SText>
            </HelpContnetBodyRowStyle>
          </HelpContnetBodyStyle>
        </HelpContnetStyle>
      </Animated>
    </HelpDrawerLayout>
  );
};

export default HelpSection;
