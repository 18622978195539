import React from 'react';
import {LibraryRipple} from '../styles/libraryStyle';

const LibraryRippleComponent = ({onRippleLoad, previewUrl, onClick, height}) => {
    return (
        <LibraryRipple
            onLoad={onRippleLoad}
            src={previewUrl}
            onClick={onClick}
            height={height}
        />
    )
}

export default LibraryRippleComponent;
