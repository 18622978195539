import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./Home";
import Locations from "./Locations";
import Library from "./Library";
import SurpriseMe from "./SurpriseMe";
import EditImage from "./EditImage";
import Result from "./Result";
import ResultDemo from "./ResultDemo";
import IFrame from "./IFrame";
import ShoutoutPreview from "./ShoutoutPreview";
import { DrawingArea } from "./edit/DrawingArea";
import {
  //
  ContentContext,
  TranslationsContext,
  MainContext,
  FramesContext,
  LibraryContext,
  ShoutoutContext,
  ActiveFrameContext,
  LocationFramesContext,
  CampaignContext,
} from "../context";
import { useOrientationDetect } from "../services/hooks";
import { Orientation } from "./Orientation";
import { getTranslations } from "../actions";
import { enableStaticScreen, disableStaticScreen } from "../services/Utils";
import MacaronSelection from "./MacaronSelection";

export default function App() {
  const [content, setContent] = useState(null);
  // todo(vmyshko): replace all obj pairs as array tuples,
  // for easier use and consistency/similarity with useState()
  const contentValue = { content, setContent };

  const [translations, setTranslations] = useState(null);
  const translationsValue = { translations, setTranslations };

  const [library, setLibrary] = useState(null);
  const libraryValue = { library, setLibrary };

  const [shoutout, setShoutout] = useState(null);
  const shoutoutValue = { shoutout, setShoutout };

  const [main, setMain] = useState({ mode: null });
  const mainValue = { main, setMain };

  const [frames, setFrames] = useState(null);
  const framesValue = { frames, setFrames };

  const [activeFrame, setActiveFrame] = useState(null);
  const selectedFrameValue = { activeFrame, setActiveFrame };

  const [campaign, setCampaign] = useState(null);
  const campaignValue = { campaign, setCampaign };

  // todo(vmyshko): global frames to request once and reuse
  const [locationFrames, setLocationFrames] = useState([]);
  const locationFramesValue = { locationFrames, setLocationFrames };

  const orientation = useOrientationDetect();

  useEffect(() => {
    getTranslations().then((trans) => {
      setTranslations(trans);
    });

    enableStaticScreen();

    return () => disableStaticScreen();
  }, []);

  useEffect(() => {
    // todo(vmyshko): i dont have locationId here to request those,
    // also other checks from hooks should be done
    // const frames = (await getFrames(locationId)) || [];
    // setLocationFrames(frames || []);
  });

  useEffect(() => {
    const skipMacaroonFrames = ["gallery", "library"].includes(main.mode);
    const availableFrames = locationFrames.filter((frame) => (skipMacaroonFrames ? !frame.isMacaroonFrame : true));

    setFrames(availableFrames);
    setActiveFrame(availableFrames[0]);
  }, [main, locationFrames]);

  return (
    // todo(vmyshko): make combiner for providers to reduce nesting
    <MainContext.Provider value={mainValue}>
      <ContentContext.Provider value={contentValue}>
        <TranslationsContext.Provider value={translationsValue}>
          <LocationFramesContext.Provider value={locationFramesValue}>
            <CampaignContext.Provider value={campaignValue}>
              <FramesContext.Provider value={framesValue}>
                <ActiveFrameContext.Provider value={selectedFrameValue}>
                  <LibraryContext.Provider value={libraryValue}>
                    <ShoutoutContext.Provider value={shoutoutValue}>
                      {orientation !== "portrait" && (
                        <Orientation
                          data={content && content.general ? content.general.colors : null}
                          locationName={
                            content && content.locationName
                              ? content.locationName.displayName
                                ? content.locationName.displayName
                                : content.locationName.name
                                ? content.locationName.name
                                : ""
                              : ""
                          }
                        />
                      )}
                      <Router>
                        <Switch>
                          <Route exact path="/" component={Locations} />
                          <Route path="/library" component={Library} />
                          <Route path="/surprise" component={SurpriseMe} />
                          <Route path="/home" component={Home} />
                          <Route path="/edit" component={EditImage} />
                          <Route path="/result" component={Result} />
                          <Route path="/draw" component={DrawingArea} />
                          <Route path="/preview" component={Home} />
                          <Route path="/result-demo" component={ResultDemo} />
                          <Route path="/shoutout_preview" component={ShoutoutPreview} />
                          <Route path="/iframe" component={IFrame} />
                          <Route path="/macaron" component={MacaronSelection} />
                        </Switch>
                      </Router>
                    </ShoutoutContext.Provider>
                  </LibraryContext.Provider>
                </ActiveFrameContext.Provider>
              </FramesContext.Provider>
            </CampaignContext.Provider>
          </LocationFramesContext.Provider>
        </TranslationsContext.Provider>
      </ContentContext.Provider>
    </MainContext.Provider>
  );
}
