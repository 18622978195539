import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Button, ButtonsRow, ModalBody, Note, Title } from './styles';
import CloseIcon from '../icons/CloseIcon';
import { Phrase } from '../../Phrase';
import TwoMoreToGoIcon from '../icons/TwoMoreToGoIcon';
import { Loader } from '../../styles/loader';

const MacaronsModalLeft = (props) => {
  const {isOpen, onConfirm, onClose, macaronAmount, macaronsToGo, isPrintLoading, content = {}} = props;

  const { general = {}, macaron = {} } = content;
    const { colors = {} } = general;
    const {texts = {} } = macaron;
    const backgroundColor = colors.backgroundColor;
    const textColor = colors.generalTextColor;
    const buttonBackground = content.home.buttons.snap ? content.home.buttons.snap.backgroundColor : content.home.buttons.library.backgroundColor;
    const buttonTextColor = content.home.buttons.snap ? content.home.buttons.snap.textColor : content.home.buttons.library.textColor;

    const keepDesigningText = texts.keepDesigning || {};
    const thatsAllIWantText = texts.thatsAllIWant || {};

  return (
      <Modal
        open={isOpen}
        onClose={onClose}
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        BackdropProps={{style: {backgroundColor: '#000000cc'}}}
      >
        <ModalBody backgroundColor={backgroundColor} shadowColor={textColor}>
            <CloseIcon fill={textColor} style={{alignSelf: 'flex-end' }} onClick={onClose}/>
            <Note style={{fontSize: '14px', color: textColor}}>
              <Phrase Key={'87fa30f6-b03f-441b-8723-08482b77e3c8'} default={`${macaronsToGo} more to go!`} replaceValues={[{searchValue: '{{x}}', replaceWith: macaronsToGo}]}/>
            </Note>
            <Title textColor={textColor} style={{marginTop: 0, fontWeight: 'bold'}}>
              <Phrase 
              Key={'8e7613e7-dfdc-46e6-a84b-d8301df2b113'} 
              default={`You've only designed ${macaronAmount - macaronsToGo} macaron out of ${macaronAmount}`}
              replaceValues={[{searchValue: '{{X}}', replaceWith: macaronAmount - macaronsToGo}, {searchValue: '{{Y}}', replaceWith: macaronAmount}]}/>
            </Title>
            <TwoMoreToGoIcon stroke={textColor}/>
            <ButtonsRow style={{marginTop: '30px', textTransform: 'uppercase', flexDirection: 'column', marginBottom: '20px'}}>
                <Button onClick={onClose} backgroundColor={buttonBackground} borderColor={buttonBackground} textColor={buttonTextColor} style={{width: '95%', marginBottom: '20px'}}>
                    <Phrase Key={keepDesigningText.translationKey} default={keepDesigningText.text || ''}/>
                </Button>
                <Button onClick={onConfirm} backgroundColor={backgroundColor} textColor={buttonBackground} borderColor={buttonBackground} style={{width: '95%'}}>
                {isPrintLoading ? 
                <Loader color={buttonBackground} /> :
                <Phrase Key={thatsAllIWantText.translationKey} default={thatsAllIWantText.text || ''}/>

                }
                </Button>
            </ButtonsRow>
        </ModalBody>
      </Modal>
  );
}

export default MacaronsModalLeft;