import React from "react";
import styled from "styled-components";

import { device } from "./breaks";
import { getNavigatorLanguage } from "../../services/Utils";
import "../../styles/Snapshot.scss";

export const MainWrapper = styled.div`
  @media ${device.mobileL} {
    display: flex;
    flex-direction: column;
    z-index: 10;
  }

  background-color: ${props => props.backgroundColor || 'white'};
  height: 100%;
`;

export const HeaderWrapper = styled.div`
  height: 34%;
  width: 100%;
  display: block;
  flex-direction: column;
`;

export const Header = styled.div`
  justify-self: center;
  display: flex;
  width: auto;
  height: 80%;
  min-width: 100%;
  min-height: 100%;
  background: url(${(props) => props.location}) no-repeat center;
  background-size: ${(props) => props.size || "cover"};
`;

export const VideoHeader = styled.video`
  width: ${() => window.innerWidth}px;
  object-fit: cover;
  height: 100%;
  type: "video/mp4";
`;
export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 10%;
`;
export const LogoSpan = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 15;
  #symbol {
    background-color: white;
    border-radius: 50%;
  }
  img {
    position: absolute;
    width: ${(props) => getLogoPers(props.width)};
    height: ${(props) => props.width};
    min-height: 100px;
    object-fit: contain;
    margin-top: ${(props) => getLogoTop(props.width)};
  }
`;

const getLogoPers = (value) => {
  switch (value) {
    case "100px":
      return "40%";
    case "120px":
      return "50%";
    case "140px":
      return "60%";

    default:
      return "60%";
  }
};
const getLogoTop = (value) => {
  switch (value) {
    case "100px":
      return "-3px";
    case "120px":
      return "-12px";
    case "140px":
      return "-25px";

    default:
      return "-3px";
  }
};

export const MainHomeIntro = styled.div`
  width: 100%;
  height: 66%;
  display: block;
`;

export const MainIntro = styled.div`
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${(props) => props.color};
`;

export const NoCameraMessage = styled.div`
  position: absolute;
  top: ${(props) => Math.round(window.innerHeight - props.position.top - 150)}px;
  left: ${(props) => props.position.left - 15}px;
  width: 326px;
  height: 188px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  border-radius: 20px;
  align-self: center;
`;

const MsgCloseStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: -20px;
  width: 100%;
  svg {
    fill: ${(props) => props.color || "white"};
    width: 17.05px;
    height: 17.25px;
  }
`;

export const MsgClose = (props) => {
  const { onClick, color } = props;
  return (
    <MsgCloseStyle onClick={onClick} color={color}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.1 17.3">
        <path d="M15.1 16.9L.4 2.1C-.1 1.6-.1.9.4.4S1.6-.1 2 .4l14.7 14.8c.5.5.5 1.2 0 1.7-.4.4-1.1.4-1.6 0z" />
        <path d="M.4 15.2L15.1.4c.5-.5 1.2-.5 1.6 0 .5.5.5 1.2 0 1.7L2.1 16.9c-.5.5-1.2.5-1.6 0-.5-.5-.5-1.2-.1-1.7z" />
      </svg>
    </MsgCloseStyle>
  );
};

export const CamMsgStyle = styled.p`
  font-size: 1.8rem;
  padding: 10% 10% 5% 10%;
  text-align: left;
  line-height: 1.5;
  color: ${(props) => props.color}!important;
`;

export const CamMsg = (props) => {
  return <CamMsgStyle color={props.color}>Your browser does not support camera access. Go to the camera on your device and then return here to select your image</CamMsgStyle>;
};

export const Marker = styled.svg`
  position: absolute;
  fill: ${(props) => props.background};
  width: 31.4px;
  height: 25.1px;
  left: 20%;
  top: 95%;
`;

export const Print = styled.div`
  padding-top: 11%;
  padding-bottom: 10px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.mobileS} {
    padding-top: 11%;
  }
  @media ${device.mobileM} {
    padding-top: 7%;
  }
  @media ${device.mobileL} {
    padding-top: 7%;
  }
`;

export const PrintedTitle = styled.div`
  font-family: ${(props) => props.data.FontFamily || "MuktaBold"};
  align-self: center;
  letter-spacing: ${({ data }) => data.LetterSpacing || "none"};
  color: ${({ data }) => data.color};
  margin-top: 25px;
  text-align: ${({ data }) => data.align || "center"};
  text-transform: uppercase;
  line-height: 1.2;

  @media ${device.mobileS} {
    font-size: 20px;
  }
  @media ${device.mobileS} {
    font-size: ${({ data }) => (getNavigatorLanguage() !== "en" ? "1.2em" : data.FontSize || "22px")};
  }
  @media ${device.mobileM} {
    font-size: ${({ data }) => data.FontSize || "30px"};
  }
  @media ${device.mobileL} {
    font-size: ${({ data }) => data.FontSize || "35px"};
  }
`;

export const LocationName = styled.div`
  font-family: "Brandon Grotesque regular";
  align-self: center;
  color: ${(props) => props.data.color};
  padding-bottom: 10px;
  width: 80%;
  text-align: center;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  @media ${device.mobileL} {
    font-size: 16px;
  }
  @media ${device.mobileM} {
    font-size: 14px;
  }
`;

export const InstructionText = styled.div`
  align-self: center;
  font-weight: ${(props) => ((props.data.FontWeight || props.extra.FontWeight) === "bold" ? "bold" : "400")};
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  color: ${(props) => props.data.color || props.extra.color};
  @media ${device.mobileS} {
    font-size: 18px;
  }
  @media ${device.mobileM} {
    font-size: ${(props) => (getNavigatorLanguage() !== "en" ? "1.2em" : props.data.FontSize || props.extra.FontSize || "20px")};
  }
  @media ${device.mobileL} {
    font-size: ${(props) => (getNavigatorLanguage() !== "en" ? "1.2em" : props.data.FontSize || props.extra.FontSize || "24px")};
  }
`;

export const InstructionSurpriseMeText = styled(InstructionText)`
  @media ${device.mobileS} {
    font-size: 20px;
  }
  @media ${device.mobileM} {
    font-size: 22px;
  }
`;

export const ActionButtonsWrapper = styled.div`  
  display: flex;
  height: fit-content;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-self: center;
  position: relative;
  top: -10px;
  width: 80%;
`;

const getLangFontSize = (props, defaultSize) => {
  const lang = getNavigatorLanguage();
  switch (lang) {
    case "en":
      return (props.extra && props.extra.FontSize) || "18px";
    default:
      return defaultSize;
  }
};

export const FlowButton = styled.button`
  font-family: "MuktaBold";
  text-transform: uppercase;
  color: ${(props) => props.data.textColor || "#000"};
  opacity: ${(props) => props.data.opacity || "inherit"};
  border: 1px solid ${(props) => props.data.backgroundColor};
  border-radius: 16px;
  box-shadow: ${props => props.isSelected ? `0 0 0 7px ${props.pageBackgroundColor}, 0 0 0 8px ${props.data.backgroundColor}` : `none`};
  background: ${(props) => props.data.backgroundColor || "#fff"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px;
  font-weight: ${(props) => (getNavigatorLanguage() !== "en" && props.extra && props.extra.FontWeight) || "light"};
  width: 30vw;
  height: 30vw;
  @media ${device.mobileS}{
    font-size: ${(props) => () => getLangFontSize(props, "16px")};
  }

  @media ${device.mobileM}{
    min-height: 90px;
    font-size: ${(props) => () => getLangFontSize(props, "16px")};
  }
  @media ${device.mobileL}{
    min-height: 80px;
    font-size: ${(props) => () => getLangFontSize(props, "18px")};
  }
  svg {
    min-height: 35px;
    margin-bottom: 0px;
    @media ${device.mobileS} {
    }
    @media ${device.mobileM}{
      margin-bottom: 0px;
    }
    @media ${device.mobileL}{
      margin-bottom: 0px;
    }
  }
  :focus {
    outline: none;
  }
`;

export const HomeFlowButton = styled.button`
  font-family: "MuktaBold";
  color: ${(props) => props.data.textColor || "#000"};
  opacity: ${(props) => props.data.opacity || "inherit"};
  border: 1px solid ${(props) => props.data.backgroundColor || "#868686"};
  border-radius: 25px;
  background: ${(props) => props.data.backgroundColor || "#fff"};
  display: flex;
  align-items: center;
  margin: 8px;
  font-weight: ${(props) => (getNavigatorLanguage() !== "en" && props.extra && props.extra.FontWeight) || "light"};
  width: 100%;
  height: 45px;
  text-transform: uppercase;
  @media ${device.mobileS}{
    font-size: ${(props) => () => getLangFontSize(props, "16px")};
  }

  @media ${device.mobileM}{
    font-size: ${(props) => () => getLangFontSize(props, "16px")};
  }
  @media ${device.mobileL}{
    font-size: ${(props) => () => getLangFontSize(props, "18px")};
  }
  svg {
    margin-bottom: 0px;
    margin: 0 15px;
  :focus {
    outline: none;
  }
`;

export const BackButtonContainer = styled.div`
display: flex;
align-items: baseline;
font-size: 16px;
color: ${props => props.textColor || 'white'};
margin: 10px 0;
svg {
  margin-right: 10px; 
}
`;

export const PoweredByContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
color: ${props => props.textColor || 'black'};
opacity: 0.7;
font-size: 14px;
max-width: 90%;
`

const PoweredByStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 25;
  height: 15px;

  gap: 8px;

  margin-top: auto;
  padding: 20px 0;

  font-family: "Brandon Grotesque regular";
  font-size: 15px;
  position: absolute;
  bottom: 0;
`;

export function PoweredBy({ data = {}, style }) {
  const { location, width, height, background, color, ...dataStyle } = data;

  // todo(vmyshko): size is disabled, since before it was broken
  // todo(vmyshko): background applied as color, bg should be replaced by color on portal side
  return (
    <PoweredByStyle style={{ color: color || background, ...dataStyle, ...style }}>
      <svg xmlns="http://www.w3.org/2000/svg" height={15} viewBox="0 0 31 30" fill="none">
        <path
          d="M20.6894 20.0064C20.5467 20.9093 20.3088 21.7172 19.9281 22.5251C18.5006 25.6139 15.3601 27.7524 11.7438 27.7524C8.88883 27.7524 6.31934 26.4218 4.65394 24.3309C3.46436 22.7627 2.70303 20.8143 2.70303 18.7234L2.70303 11.0725C2.70303 10.5972 2.75062 10.122 2.7982 9.64682C2.7982 9.50425 2.84578 9.36169 2.84578 9.26665C2.98853 8.69639 3.17886 8.12614 3.41678 7.60341C3.94019 6.32033 4.79669 5.17982 5.84351 4.27692C7.12825 3.13641 8.74608 2.37607 10.5066 2.13847L10.5542 2.13847C10.9825 2.09095 11.3631 2.04343 11.7438 2.04343L19.4047 2.04343C24.4009 2.04343 28.4454 6.08273 28.4454 11.0725C28.4454 15.587 25.0671 19.3887 20.6894 20.0064ZM19.4047 0.142578L11.7438 0.142578C11.268 0.142578 10.7446 0.190099 10.2212 0.237621C5.65318 0.902917 1.94171 4.37196 1.03763 8.88648C0.990045 9.02904 0.990045 9.21913 0.942462 9.36169C0.847296 9.93194 0.799713 10.5022 0.799713 11.0725L0.799713 18.7234C0.799713 24.7586 5.70076 29.6532 11.7438 29.6532C16.6924 29.6532 21.0225 26.2792 22.3072 21.6222C26.9704 20.3391 30.3488 16.0147 30.3488 11.0725C30.3488 5.03726 25.4477 0.142578 19.4047 0.142578Z"
          fill="currentColor"
        />
        <path
          d="M20.4515 16.2048C20.1184 16.2523 19.7378 16.2998 19.4047 16.2998L16.978 16.2998L16.978 18.7234C16.978 20.4817 16.0739 22.0974 14.694 23.0003L14.6464 23.0478C14.5988 23.0953 14.5036 23.1428 14.4561 23.1904C14.3609 23.2379 14.3133 23.2854 14.2181 23.3329C14.1706 23.3329 14.123 23.3805 14.0754 23.3805C13.9802 23.428 13.8375 23.4755 13.7423 23.523C13.6471 23.5705 13.5044 23.6181 13.4092 23.6656C13.3616 23.6656 13.3141 23.7131 13.2665 23.7131C13.2189 23.7131 13.1713 23.7606 13.1237 23.7606C12.6955 23.8557 12.2197 23.9507 11.7438 23.9507C11.3632 23.9507 11.0301 23.9032 10.697 23.8557C8.31784 23.3805 6.50969 21.242 6.50969 18.7234L6.50969 11.0725C6.50969 10.7873 6.50969 10.5022 6.60485 10.2171C6.60485 10.1696 6.60485 10.0745 6.65244 10.027C7.08068 7.88854 8.84126 6.2253 11.0777 5.89265C11.268 5.84513 11.5059 5.84513 11.7438 5.84513L19.4047 5.84513C22.3072 5.84513 24.6388 8.17367 24.6388 11.0725C24.6388 13.5911 22.8307 15.682 20.4515 16.2048ZM19.4047 3.94429L11.7438 3.94429C11.4107 3.94429 11.1252 3.94429 10.7446 4.03933C7.79443 4.4195 5.36769 6.70051 4.74912 9.64683C4.74912 9.74187 4.70153 9.83691 4.70153 9.93196C4.65395 10.3121 4.60637 10.6923 4.60637 11.0725L4.60637 18.7234C4.60637 22.6676 7.79443 25.8516 11.7438 25.8516C15.6932 25.8516 18.8813 22.6676 18.8813 18.7234L18.8813 18.2006L19.4047 18.2006C23.3541 18.2006 26.5421 15.0167 26.5421 11.0725C26.5421 7.12821 23.3065 3.94429 19.4047 3.94429Z"
          fill="currentColor"
        />
      </svg>
      <span>Ripples</span>
    </PoweredByStyle>
  );
}
