import React from "react";

const DrinkIcon = ({stroke}) => {
    return <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.8135 11.2944V15.9877" stroke={stroke || "white"} strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.9326 13.3237V18.5979" stroke={stroke || "white"} strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.7705 10.3381V18.5982" stroke={stroke || "white"} strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.9326 3.01636V9.04145" stroke={stroke || "white"} strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.4 0.4"/>
    <path d="M38.1388 25.8257C39.7345 25.0747 41.518 25.0747 42.5505 25.9195C44.24 27.3275 44.1462 30.3312 41.6118 32.0207C38.8898 33.7103 35.6984 33.5226 34.0088 35.6814" stroke={stroke || "white"} strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M38.4208 22.2585H5.75586C5.75586 30.3309 10.6368 40.5621 21.1497 40.5621C22.276 40.5621 22.5576 40.5621 23.4024 40.5621C34.0091 40.5621 38.4208 30.3309 38.4208 22.2585Z" stroke={stroke || "white"} strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.6729 31.833C15.6115 32.8655 16.644 33.7103 17.8642 34.3674" stroke={stroke || "white"} strokeWidth="1.8" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
   
};

export default DrinkIcon;
