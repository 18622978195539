import { blur, colorDodge } from "./plugins";
import { getImageNewSize } from ".";

const Marvin = window.Marvin;
const sketchCanvas = document.getElementById("sketchCanvas");
const playCanvas = document.getElementById("play");
let imageExampleFiltersOut, thumbNailImage, thumbSize;
let vWidth = window.innerWidth;

export let copyImageForSketch = async (image) => {
  imageExampleFiltersOut = image;
  thumbNailImage = image.clone();
  thumbSize = getImageNewSize(vWidth, image.width, image.height, 160);
  Marvin.scale(thumbNailImage.clone(), thumbNailImage, thumbSize.newWidth, thumbSize.newHeight);
  playCanvas.width = thumbSize.newWidth;
  playCanvas.height = thumbSize.newHeight;
};

export const clearCanvas = () => {
  const sketchCanvas = document.getElementById("sketchCanvas");
  let ctx = sketchCanvas.getContext("2d");
  ctx.clearRect(0, 0, sketchCanvas.width, sketchCanvas.height);
};

export const sketchFilter = async (canvas, originalImage, filter) => {
  if(!originalImage) return
  let final, imageProcess2, imageProcess1;
  canvas = canvas || sketchCanvas;
  imageProcess1 = originalImage.clone();

  // Grayscale
  Marvin.grayScale(imageProcess1, imageProcess1);

  // Brightness & Contrast
  Marvin.brightnessAndContrast(imageProcess1, imageProcess1, filter["m_brightness"], filter["m_contrast"]);

  // Masking
  imageProcess2 = imageProcess1.clone();

  Marvin.brightnessAndContrast(imageProcess2, imageProcess2, filter["m_maskBrightness"], filter["m_maskContrast"]);
  // Invert
  Marvin.invertColors(imageProcess2, imageProcess2);

  // Blur
  imageProcess2 = blur(imageProcess2, filter["m_blurRadius"]);

  // Dodge
  final = colorDodge(imageProcess1, imageProcess2);
  final.draw(canvas);
};

export const sketchFilterThumb = async (canvas, filter) => {
  if(!thumbNailImage) return;
  canvas = canvas || sketchCanvas;
  let originalImage = thumbNailImage.clone()
  sketchFilter(canvas, originalImage, filter);
};

export const sketchFilterRegular = async (canvas, filter, width, height) => {
  if(!imageExampleFiltersOut) return;
  let originalImage = imageExampleFiltersOut.clone();
  Marvin.scale(imageExampleFiltersOut.clone(), originalImage, width, height);
  sketchFilter(canvas, originalImage, filter);
};

export const changeBrightnessAndContrast = (canvas, originalImage, filter) => {
  const imageProcess1 = originalImage.clone();

  // Grayscale
  Marvin.grayScale(imageProcess1, imageProcess1);

  // Brightness & Contrast
  Marvin.brightnessAndContrast(imageProcess1, imageProcess1, filter["m_brightness"], filter["m_contrast"]);
  imageProcess1.draw(canvas);
};

export const changeBrightnessAndContrastThumb = (canvas = null, filter) => {
  if(!thumbNailImage) return;
  changeBrightnessAndContrast(canvas, thumbNailImage.clone(), filter);
};

export const changeBrightnessAndContrastRegular = (canvas = null, filter, width, height) => {
  if (!imageExampleFiltersOut) return;
  canvas = canvas || sketchCanvas;

  const originalImage = imageExampleFiltersOut.clone();
  Marvin.scale(imageExampleFiltersOut.clone(), originalImage, width, height);

  changeBrightnessAndContrast(canvas, originalImage, filter)
};

export const getPreviewImages = async ({filters = [], width, height}) => {
  return filters.map((e) => {
    try {
      let data = getPreviewImage(e, width, height);
      return {id: e._id, src: data};
    } catch (error) {
      return "";
    }
  });
};

export const getPreviewImage = (filter) => {
  try {
    playCanvas.getContext("2d").clearRect(0, 0, playCanvas.width, playCanvas.height);
    if (filter.regular) {
      changeBrightnessAndContrastThumb(playCanvas, filter);
    } else {
      sketchFilterThumb(playCanvas, filter);
    }
    return playCanvas.toDataURL();
  } catch (error) {
    return "";
  }
};

export const getNewImage = (filter, width, height) => {
  try {
    playCanvas.getContext("2d").clearRect(0, 0, playCanvas.width, playCanvas.height);
    if (filter.regular) {
      changeBrightnessAndContrastRegular(playCanvas, filter, width, height);
    } else {
      sketchFilterRegular(playCanvas, filter, width, height);
    }
    return playCanvas.toDataURL();
  } catch (error) {
    return "";
  }
};

export let regularFilter = async (filter) => {
  let originalImage = imageExampleFiltersOut.clone();
  let imageProcess1 = originalImage;

  // Grayscale
  Marvin.grayScale(imageProcess1, imageProcess1);

  // Brightness & Contrast
  Marvin.brightnessAndContrast(imageProcess1, imageProcess1, filter["m_brightness"], filter["m_contrast"]);
  return imageProcess1;
};
