const twoLinesIntersect = ({ x: ax1, y: ay1 }, { x: ax2, y: ay2 }, { x: bx1, y: by1 }, { x: bx2, y: by2 }) => {
  // 4 points of 2 lines
  const v1 = (bx2 - bx1) * (ay1 - by1) - (by2 - by1) * (ax1 - bx1);
  const v2 = (bx2 - bx1) * (ay2 - by1) - (by2 - by1) * (ax2 - bx1);
  const v3 = (ax2 - ax1) * (by1 - ay1) - (ay2 - ay1) * (bx1 - ax1);
  const v4 = (ax2 - ax1) * (by2 - ay1) - (ay2 - ay1) * (bx2 - ax1);
  return v1 * v2 < 0 && v3 * v4 < 0;
};

const isPointInRect = ({ x: px, y: py }, { x, y, width, height }) => {
  return px > x && px < x + width && py > y && py < y + height;
};

export const lineIntersectRect = (line, rect) => {
  // line - array with two points, rect - x, y, width, height
  const [point1, point2] = line;
  return (
    twoLinesIntersect(point1, point2, { x: rect.x, y: rect.y }, { x: rect.x, y: rect.y + rect.height }) ||
    twoLinesIntersect(point1, point2, { x: rect.x, y: rect.y }, { x: rect.x + rect.width, y: rect.y }) ||
    twoLinesIntersect(point1, point2, { x: rect.x + rect.width, y: rect.y + rect.height }, { x: rect.x + rect.width, y: rect.y }) ||
    twoLinesIntersect(point1, point2, { x: rect.x + rect.width, y: rect.y + rect.height }, { x: rect.x, y: rect.y + rect.height }) ||
    isPointInRect(point1, rect) ||
    isPointInRect(point2, rect)
  );
};

export const getPointAngle = ({ x: cx, y: cy }, { x, y }) => {
  return (Math.atan2(y - cy, x - cx) * 180) / Math.PI;
};

export const getTouchesDistance = (touches) => {
  return Math.sqrt(Math.pow(touches[1].x - touches[0].x, 2) + Math.pow(touches[1].y - touches[0].y, 2));
};
