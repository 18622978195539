import styled from 'styled-components';

export const MacaronsContainer = styled.div`
width: 90vw;
height: 90vw;
display: flex;
flex-direction: column;
justify-content: space-between;
margin-top: 40px;
margin-bottom: 20px;
`

export const MacaronsRow = styled.div`
display: flex;
width: 100%;
position: relative;
`

export const Macaron = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
border: 1px dashed ${props => props.borderColor || 'black'};
border-radius: 50%;
`
export const MacaronImage = styled.img`
border: 1px solid black;
border-radius: 50%;
width: 100%;
height: 100%;
position: absolute;
`;

export const MacaronOptionsContainer = styled.div`
display: flex;
flex-direction: column;
background-color: ${props => props.backgroundColor || 'white'}
`;

export const MacaronOption = styled.div`
display: flex;
height: 70px;
align-items: center;
color: ${props => props.textColor || 'black'};
svg {
    margin: 0 20px;
};
span {
    margin-right: 20px;
}
`

export const SurpriseMacaron = styled.div`
border: 1px solid ${(props) => props.borderColor || 'black'};
border-radius: 50%;
background-color: transparent;
font-size: 50px;
color: black;
width: 100%;
height: 100%;
position: absolute;
display: flex;
justify-content: center;
align-items: center;
`

export const MacaronContainer = styled.div`
width: 39vw;
height: 39vw;
position: relative;
border-radius: 50%;
`;

export const SubTitle = styled.span`
font-size: 20px;
line-height: 29px;
letter-spacing: 0em;
text-align: center;
color: ${(props) => props.colors.generalTextColor || 'black'};
`;

export const SurpriseLabelContainer = styled.div`
width: 60%;
border: 2px solid ${props => props.borderColor || 'black'};
color: ${props => props.textColor || 'black'};
background-color: ${props => props.backgroundColor || '#FFFFFF'};
border-radius: 20px;
position: absolute;
display: flex;
justify-content: center;
align-items: center;
padding: 2px 15px;
font-weight bold;
letter-spacing: 1px;
bottom: -10px;
right: -5px;
rotate: -15deg;
`