import axios from "axios";

export function getLocationsLibraryById(locationId) {
  return axios
    .get(`/api/v1/library/${locationId}`, {
      responseType: "json",
    })
    .then((response) => response.data);
}

export function getLocationsLibraryByShortId(shortId) {
  return axios
    .get(`/api/v1/library/short/${shortId}`, {
      responseType: "json",
    })
    .then((response) => response.data);
}
