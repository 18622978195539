import React, { useEffect } from "react";
import queryString from "query-string";


const IFrame = (props) => {
 
  useEffect(() => {
    goToHomeScreenFromIFrame()
    //eslint-disable-next-line
  },[])

  const goToHomeScreenFromIFrame = () => {
    const { locationId, templateId } = queryString.parse(props.location.search);
    props.history.push({
      pathname: "/home",
      state: {
          templateId,
          locationId,
          iFrame: true
      },
    });
  };
  
  return (
    <></>
  );
};

export default IFrame;
