import React from "react";
import { BackBtnStyle, QuestionsStyle } from "./libraryStyle";
import styled from "styled-components";

export const LoginButtonWrapper = styled.div`
  width: 100%;
  z-index: 20;
  display: flex;
  position: absolute;
  top: 10px;
  justify-content: flex-end;
  padding-right: 15px;
  flex-direction: row;
`;

export const UserLoginButton = (props) => {
  let text = props.colors.main;
  let fill = props.colors.backgroundColor;
  let size = 35;
  return (
    <div style={{ paddingRight: "10px" }} onClick={props.onClick}>
      <svg
        viewBox="0 0 600 600"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        stroke={text}
        strokeWidth="20"
        preserveAspectRatio="xMidYMid meet"
        fill={fill}
      >
        <circle cx="300" cy="300" r="265" />
        <circle cx="300" cy="230" r="115" />
        <path d="M106.81863443903,481.4 a205,205 1 0,1 386.36273112194,0" strokeLinecap="butt" />
      </svg>
    </div>
  );
};

export const BackBtn = ({svgStyle = {}, ...props}) => {
  return (
    <BackBtnStyle {...props}>
      <svg width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg" {...svgStyle}>
        <path d="M9.10937e-09 6.25155L6.05245 0.199094C6.31791 -0.0663646 6.74264 -0.0663646 7.0081 0.199094C7.27356 0.464552 7.27356 0.889286 7.0081 1.15474L1.9113 6.25155L6.95501 11.2953C7.22047 11.5607 7.22047 11.9854 6.95501 12.2509C6.68955 12.5164 6.26482 12.5164 5.99936 12.2509L9.10937e-09 6.25155Z" />
      </svg>
    </BackBtnStyle>
  );
};

export function QuestionBtn(props) {
  const fillColor = props.colors.generalTextColor;

  return (
    <QuestionsStyle onClick={props.onClick}>
      <svg color={fillColor} width="11" height="20" viewBox="0 0 11 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.53202 14.08L5.31502 14.08C5.60502 14.08 5.92402 13.906 5.92402 13.5L5.92402 10.933C5.92402 8.555 10.506 8.236 10.506 4.437C10.506 1.769 8.47602 0 5.46002 0C1.80602 0 0.153019 2.552 0.153019 2.552C-0.0209809 2.784 -0.078981 3.103 0.153019 3.306L0.70402 3.799C0.965019 4.031 1.19702 4.031 1.45802 3.77C2.03802 3.161 3.11102 1.798 5.46002 1.798C7.34502 1.798 8.33102 2.987 8.33102 4.35C8.33102 7.627 3.98102 7.337 3.98102 10.933L3.98102 13.529C3.98102 13.848 4.24202 14.08 4.53202 14.08ZM3.22702 18.227C3.22702 19.126 3.98102 19.88 4.88002 19.88C5.80802 19.88 6.53302 19.126 6.53302 18.227C6.53302 17.299 5.80802 16.574 4.88002 16.574C3.98102 16.574 3.22702 17.299 3.22702 18.227Z"
          fill="currentColor"
        />
      </svg>
    </QuestionsStyle>
  );
}
