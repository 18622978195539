import React, { useState } from "react";

import { IntroWrapper, MainHomeIntro, Print, PrintedTitle, InstructionText } from "./styles/IntroStyle";
import { handleHistoryPush } from "../utils/history";
import { Phrase } from "./Phrase";
import ActionButtons from "./PrintSelection/ActionsButtons";
import MacaronSection from "./PrintSelection/MacaronSection";

import { GaEvents, sendGAEvent } from "../utils/googleAnalytics";
import LocationLabel from "./home/LocationLabel";

const PrintSelection = (props) => {
  const { content, onSelectOneMacaron, onDrinkFlowClick, locationName } = props;

  const [isMacaronSectionOpen, setIsMacaronSectionOpen] = useState();

  const general = content.general;
  const { wouldLikeYourRippleOnText = {}, LetsStartText: printLetsStart = {} } = content.home.texts.instructions || {};
  const { backgroundColor, generalTextColor } = general.colors || {};

  const onMacaronFlowClick = () => {
    sendGAEvent(GaEvents.CanvasSelection, {
      eventCategory: "flow",
      eventLabel: 'Macaron',
      value: 'Macaron',
      locationId: content.locationId,
    });
    setIsMacaronSectionOpen(true)
  }

  const onSelectAmount = (macaronAmount) => {
    sendGAEvent(GaEvents.MacaronAmount, {
      eventCategory: "flow",
      eventLabel: `${macaronAmount}_desings`,
      value: `${macaronAmount}_desings`,
      locationId: content.locationId,
    });
    if (macaronAmount === 1) {
      onSelectOneMacaron();
      return;
    }
    handleHistoryPush(props.history, "/macaron", {
      ...props.history.location.state,
      macaronAmount
    });
  }

  return (
      <MainHomeIntro id="mainIntro">
        <IntroWrapper id="introwrapper" color={backgroundColor}>
          <Print id="print">
            <PrintedTitle id="printed" data={printLetsStart}>
              <Phrase Key={printLetsStart.translationKey} default={printLetsStart.text} />
            </PrintedTitle>
            {!isMacaronSectionOpen && <InstructionText id="picor" data={wouldLikeYourRippleOnText} extra={wouldLikeYourRippleOnText} style={{width: '60%', marginTop: '5px', color: generalTextColor}}>
              <Phrase Key={wouldLikeYourRippleOnText.translationKey} default={wouldLikeYourRippleOnText.text ? wouldLikeYourRippleOnText.text : ''} />
            </InstructionText>}
          </Print>

          <ActionButtons content={content} onDrinkFlowClick={onDrinkFlowClick} onMacaronFlowClick={onMacaronFlowClick} isMacaronSelected={isMacaronSectionOpen}/>
          {isMacaronSectionOpen && <MacaronSection onSelectAmount={onSelectAmount}/>}
          {locationName && <LocationLabel textColor={general.colors.generalTextColor} locationName={locationName}/>}
        </IntroWrapper>
      </MainHomeIntro>
  );
}

export default PrintSelection;