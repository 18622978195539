import React, { useCallback, useEffect, useRef, useState } from "react";
import Konva from "konva";
import { Group } from "react-konva";
import lodash from "lodash";

import { EditTextPortal, onTextAreaMounted } from "../EditTextPortal";
import { addChildren, getChildren, translateChildrenOffset } from "../../../utils/konvaUtils";
import useKonvaAnimation from "../../../utils/useKonvaAnimation";
import { addAmaticHearts } from "./tools/addAmaticHearts";
import { getFontSizes, calculateFontHeight, getFontPadding, getFontMarginTop, getFontLowerCase } from "./tools/fontSizes";
import { generateRandomLines, parseText } from "./tools/text";
import useDoubleTap from "../../../utils/useDoubleTap";

const TextGroup = React.forwardRef(
  (
    {
      text,
      setActiveElementMode,
      vWidth,
      minFont,
      editContainerSize,
      activeElement,
      drawingAreaProps = {},
      isRotate,
      circleProps, //!
      textRectProps, //!
      isEditable,
      translations,
      isMacaroonFrame,
    },
    groupRef
  ) => {
    const {
      setEditMode,
      setEditTextMode,
      editTextMode,
      printButtonStyle,
      setText,
      fontColor,
      editMode,
      textClicks,
      cTextFont,
      possibleCombinations,
      setPossibleCombinations,
      colors,
      imageDrawChange,
    } = drawingAreaProps;

    const noText = text.trim() === "";
    const includeHearts = cTextFont === "Amatic";

    const textMaxLength = 90;
    const frameTextRef = useRef();
    const [wordsArr, setwordsArr] = useState([""]);

    // todo(vmyshko): fix animation sizes to textGroup.width/height? but how...
    const textAnimation = useKonvaAnimation(vWidth, editContainerSize.height / 2);
    const doubleTapOnGroup = useDoubleTap(() => {
      if (editMode !== "edit") {
        turnOnEditMode();
      }
    });

    function updateTextAreaSize(event) {
      const { target } = event;
      target.style.height = "";
      target.style.height = target.scrollHeight + "px";
    }

    const turnOnEditMode = useCallback(() => {
      setEditTextMode(true);
      setEditMode("texts");
    }, [setEditTextMode]);

    function saveTextOnEdit() {
      const message = JSON.stringify({
        type: "edit_text_closed",
      });
      window.parent.postMessage(message, "*");
      setEditTextMode(false);
      setText(parseText(frameTextRef.current.value));
    }

    function cancelTextEdit() {
      const message = JSON.stringify({
        type: "edit_text_closed",
      });
      window.parent.postMessage(message, "*");
      setEditTextMode(false);
    }

    function resetTextGroupSize() {
      const textGroup = groupRef.current;
      textGroup.scale({ x: 1, y: 1 });

      textGroup.absolutePosition({
        x: textRectProps.left,
        y: textRectProps.top,
        width: textRectProps.width,
        height: textRectProps.height,
      });

      textGroup.destroyChildren();
      textGroup.clearCache();
    }

    useEffect(() => {
      // todo(vmyshko): looks like unused, cause we have stub for default text outside, in drawingArea
      if (noText) {
        // todo(vmyshko): seems it does some cleanup process, removing old text from canvas
        const textGroup = groupRef.current;
        resetTextGroupSize();

        textGroup.getLayer().draw();
        setPossibleCombinations([]);
        translateChildrenOffset(textGroup, vWidth, vWidth);
      } else {
        const randomLines = lodash.shuffle(generateRandomLines(text));
        setPossibleCombinations(randomLines);
      }
    }, [text]);

    function getNextLine() {
      // todo(vmyshko): somewhy text fails to split on "12 123"
      return possibleCombinations[textClicks];
    }

    // todo(vmyshko): this looks unused, remove?
    function drawUnFormattedText() {
      const textGroup = groupRef.current;
      try {
        const textGroup = groupRef.current;
        resetTextGroupSize();

        textGroup.draw();
        textGroup.getLayer().draw();
      } catch (e) {
        /*in case there are no children*/
      }
      const textNodeUnformatted = new Konva.Text({
        text,
        fontSize: minFont,
        align: "center",
        width: vWidth,
        fill: fontColor,
      });

      textNodeUnformatted.y(vWidth / 2 - textNodeUnformatted.height() / 2);
      addChildren(textGroup, textNodeUnformatted, true, vWidth, vWidth);
    }

    async function drawFormattedText(animateText = true) {
      const textGroup = groupRef.current;

      const isLowerCase = getFontLowerCase(cTextFont);

      //do uppercase according to lowercase flag
      const wordsArray = isLowerCase ? wordsArr : wordsArr.map((e) => e.toUpperCase());

      resetTextGroupSize();
      // todo(vmyshko): some magic numbers to fit font into block?
      // todo(vmyshko): probably get rid of those, since it's hack to fit the wrong sizes better
      // todo(vmyshko): we can use my auto-zoom instead
      const maxHeight = circleProps.radius * 2 * 0.65;
      const maxWidth = circleProps.radius * 2 * 0.65;

      const fontPadding = getFontPadding(cTextFont);
      // todo(vmyshko): used to shift some texts
      // todo(vmyshko): probably margin needs recalc to specific font size
      const marginTop = getFontMarginTop(cTextFont);

      const fontSizes = getFontSizes(wordsArray, cTextFont, maxWidth, maxHeight);

      const textLinesNode = new Konva.Group({
        x: 0,
        y: 0,
      });
      //and now we print final result
      wordsArray.forEach((wordCurrent, i) => {
        const lineHeight = calculateFontHeight(cTextFont, fontSizes[i]);

        const relativePadding = (fontPadding / 100) * fontSizes[i];
        const relativeMarginTop = (marginTop / 100) * fontSizes[i];
        let prevTextNode = null;
        if (textLinesNode.children.length) {
          const lastIndex = textLinesNode.children.length - 1;
          prevTextNode = textLinesNode.children.toArray()[lastIndex];
        }
        const textNodeCurrent = new Konva.Text({
          text: wordCurrent,
          fontSize: fontSizes[i],
          fontFamily: cTextFont,
          align: "center",
          fill: fontColor,
          y: prevTextNode ? prevTextNode.y() + prevTextNode.height() : relativeMarginTop,
          x: 0,
          height: lineHeight + relativePadding,
        });

        textLinesNode.add(textNodeCurrent);
      });

      textGroup.add(textLinesNode);

      function getNodeSize(node) {
        // todo(vmyshko): cover hearts scenario, cover offsets + negative xy/oxoy
        // todo(vmyshko): refac to include max (x+width)
        // todo(vmyshko): refac to include max (y+height)?

        const width = Math.max(...node.children.map((child) => child.width()));

        // assuming that the last is at the bottom
        const children = node.children.toArray();
        const lastIndex = children.length - 1;
        const lastNode = children[lastIndex];
        const height = lastNode.y() + lastNode.height();

        return { width, height };
      }

      const { width: textSizeWidth, height: textSizeHeight } = getNodeSize(textLinesNode);

      let heartHypot = null;
      const textHypot = Math.hypot(textSizeWidth, textSizeHeight);
      if (!noText && includeHearts) {
        const { width: heartsSizeWidth, height: heartsSizeHeight } = await addAmaticHearts(textSizeWidth, textSizeHeight, fontColor, textLinesNode);

        heartHypot = Math.hypot(heartsSizeWidth, heartsSizeHeight);

        //debug
        // textLinesNode.add(
        //   // hearts area
        //   new Konva.Rect({
        //     x: textSizeWidth / 2,
        //     y: textSizeHeight / 2,
        //     width: heartsSizeWidth,
        //     height: heartsSizeHeight,
        //     name: "hearts size",
        //     fill: textHypot > heartHypot ? "#00ff0070" : "#ff000070",
        //     stroke: "black",
        //     strokeWidth: 1,
        //     offsetX: heartsSizeWidth / 2,
        //     offsetY: heartsSizeHeight / 2,
        //   })
        // );
      }

      {
        // todo(vmyshko): EXPERIMENTAL: change size to fit rect into circle
        // const autoZoom = false;
        // todo(vmyshko): use autoZoom only for macaroons as discussed with shay
        const autoZoom = isMacaroonFrame;

        const zoom = autoZoom ? (2 * circleProps.radius) / Math.max(textHypot, heartHypot) : 1;

        textLinesNode.scale({ x: zoom, y: zoom });
        // todo(vmyshko): get rid of zoom inside xy coords, to reduce complexity
        textLinesNode.x(textGroup.width() / 2 - (textSizeWidth * zoom) / 2);
        textLinesNode.y(textGroup.height() / 2 - (textSizeHeight * zoom) / 2);
      }

      textGroup.clearCache();

      textGroup.draw();
      textGroup.getLayer().draw();

      animateText && textAnimation(textGroup);

      // // todo(vmyshko): debug
      // textLinesNode.add(
      //   // text bounds
      //   new Konva.Rect({
      //     x: 0,
      //     y: 0,
      //     width: textSizeWidth,
      //     height: textSizeHeight,
      //     name: "textbound",
      //     fill: textHypot > heartHypot ? "#ff000070" : "#00ff0070",
      //     stroke: "black",
      //     strokeWidth: 1,
      //   })
      // );
      // // todo(vmyshko): debug
      // textGroup.add(
      //   // full editable area
      //   new Konva.Rect({
      //     x: 0,
      //     y: 0,
      //     width: textGroup.width(),
      //     height: textGroup.height(),
      //     name: "textgroup size",
      //     fill: "#ff000010",
      //     stroke: "red",
      //     strokeWidth: 1,
      //   })
      // );
    }

    useEffect(() => {
      // todo(vmyshko): refac, reduce conditions?
      if (noText || !wordsArr || !wordsArr.length) {
        return;
      }

      cTextFont === "" ? drawUnFormattedText() : drawFormattedText();
    }, [wordsArr, cTextFont, fontColor, isMacaroonFrame]);

    useEffect(() => {
      setwordsArr(getNextLine());
    }, [possibleCombinations, textClicks]);

    useEffect(() => {
      const textGroup = groupRef.current;
      Konva.hitOnDragEnabled = true;
      Konva.pixelRatio = 4;

      // todo(vmyshko): this is probably draws "invisible" labels to preload fonts
      //this is to make fonts render smooth by uploading each of them once in advance
      const fonts = ["NesspressoLucas", "Playfair", "Saira", "Amatic", "LifeisMessy", "Rustico", "actionj", "burnstown-dam", "NotoSerifJP", "NotoSansJP", "KosugiMaru"];
      for (let font of fonts) {
        const textNodeFinal = new Konva.Text({
          text: "",
          fontSize: 14,
          fontFamily: font,
          align: "center",
          width: 300,
          fill: fontColor,
          x: 0,
          y: 0,
        });
        addChildren(textGroup, textNodeFinal, true, vWidth, vWidth);
      }
    }, []);

    useEffect(() => {
      if (noText) {
        return;
      }

      const textGroup = groupRef.current;
      getChildren(textGroup).forEach((node) => {
        node.setAttr("fill", fontColor);
        node.draw();
        node.getLayer().draw();
      });
    }, [fontColor, imageDrawChange]);

    const isDraggable = isEditable && editMode !== "edit" && (activeElement !== "edit" || editMode === "texts") && !isRotate;

    return (
      <>
        <Group
          ref={groupRef}
          // size,pos
          x={textRectProps.left}
          y={textRectProps.top}
          width={textRectProps.width}
          height={textRectProps.height}
          //
          listening={isEditable && editMode !== "edit"}
          // todo(vmyshko): too much conditions?
          draggable={isDraggable}
          // events
          // todo(vmyshko): extract to funcs
          onTap={(e) => {
            if (!isEditable) return;
            doubleTapOnGroup.onClick(e);
            setActiveElementMode("texts");
          }}
          onClick={(e) => {
            if (!isEditable) return;
            doubleTapOnGroup.onClick(e);
            setActiveElementMode("texts");
          }}
          onTouchStart={() => {
            setActiveElementMode("texts");
          }}
          onDblClick={() => {
            if (editMode !== "edit" && isEditable) {
              turnOnEditMode();
            }
          }}
          onDblTap={() => {
            if (editMode !== "edit" && isEditable) {
              turnOnEditMode();
            }
          }}
        />

        {/* edit text modal */}
        {/* todo(vmyshko): should it be here? probably extract outside, and pass only text */}
        {editMode === "texts" && editTextMode && (
          <EditTextPortal
            maxLength={textMaxLength}
            defaultText={text}
            onTextAreaMounted={onTextAreaMounted(frameTextRef)}
            // onBlur={saveTextOnEdit}
            onTouchMove={saveTextOnEdit}
            onFocus={updateTextAreaSize}
            onInput={updateTextAreaSize}
            onClose={cancelTextEdit}
            printButtonStyle={printButtonStyle}
            translations={translations}
            colors={colors}
          />
        )}
      </>
    );
  }
);

export { TextGroup };
