import React, { useEffect } from "react";
import CircleTextFrame from "./CircleTextFrame";
import { CUSTOM_FRAME_TYPES } from "../../../../constants";

export default React.forwardRef(({ customFrameType, ...rest }, ref) => {
  useEffect(() => {
    ref.current && ref.current.draw();
  }, [customFrameType]);

  switch (customFrameType) {
    case CUSTOM_FRAME_TYPES.CIRCLE_TEXT_FRAME:
      return <CircleTextFrame ref={ref} {...rest} />;
    case CUSTOM_FRAME_TYPES.CIRCLE_TEXT_FRAME_REVERSED:
      return <CircleTextFrame ref={ref} reversed {...rest} />;
    default:
      return null;
  }
});
