export const Types = {
  SURVEY: "survey",
  TEXTUAL: "textual",
  IMAGE: "image",
};

export const ButtonsTypes = {
  FILLED: "filled",
  OUTLINE: "outline",
};

export const TextSizes = {
  SMALL: "S",
  MEDIUM: "M",
  LARGE: "L",
};

export const TextTypes = {
  PRINTED: "printed",
  BOLD: "bold",
  MEDIUM: "medium",
  REGULAR: "regular",
};
