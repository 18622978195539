import styled from 'styled-components';

export const WalkthroughContainer = styled.div`
width: 100vw;
height: 100vh;
background-color: rgb(0, 0, 0, 0.75);
position: absolute;
z-index: 1000;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const TabActionHelper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: fixed;
color: white;
text-align: center;
filter: drop-shadow(0px 4px 10px white);
width: 138px;
`