import React from "react";
import { Animated } from "react-animated-css";

import { ModalLayout } from "../styles/messageStyle";
import { TextualPopup, usePopup, TextualWithLinkPopup } from ".";

function ShoutOut(props) {
  let { isCardVisible, isPopUpOpen } = props.data || {};
  let { overlayColor } = props.colors;
  return (
    isPopUpOpen && (
      <ModalLayout overlayColor={overlayColor}>
        <Animated
          animationIn="zoomIn"
          animationOut="zoomOut"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={isCardVisible}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {props.children}
        </Animated>
      </ModalLayout>
    )
  );
}

function getShoutoutByType(actions, data, colors) {
  let { type } = data || {};
  switch (type) {
    case "textual":
      return <TextualPopup data={data} actions={actions} colors={colors} />;
    case "texualWithLink":
      return <TextualWithLinkPopup data={data} actions={actions} colors={colors} />;
    default:
      return null;
  }
}

// todo(vmyshko): rename to shoutoutPopup
export function PopUp(props) {
  let { actions, data, colors } = usePopup(props);

  if (!data || !data.title) return null;

  return (
    <ShoutOut data={data} colors={colors}>
      {getShoutoutByType(actions, data, colors)}
    </ShoutOut>
  );
}
