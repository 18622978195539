import React from "react";

const TrashIcon = (props) => {
    const {fill, stroke} = props;

    return <svg width="24" height="24" viewBox="0 0 24 24" fill={fill || "none"} xmlns="http://www.w3.org/2000/svg">
    <path d="M3.19678 5.8125H5.13428H20.6343" stroke={stroke || "#E86161"} strokeWidth="1.1625" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.04053 5.8125V3.875C8.04053 3.36114 8.24466 2.86833 8.60801 2.50498C8.97136 2.14163 9.46417 1.9375 9.97803 1.9375H13.853C14.3669 1.9375 14.8597 2.14163 15.223 2.50498C15.5864 2.86833 15.7905 3.36114 15.7905 3.875V5.8125M18.6968 5.8125V19.375C18.6968 19.8889 18.4926 20.3817 18.1293 20.745C17.7659 21.1084 17.2731 21.3125 16.7593 21.3125H7.07178C6.55792 21.3125 6.06511 21.1084 5.70176 20.745C5.33841 20.3817 5.13428 19.8889 5.13428 19.375V5.8125H18.6968Z" stroke={stroke || "#E86161"} strokeWidth="1.1625" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    
};

export default TrashIcon;
