import React from "react";
import Portal from "./Portal";
import { DoneButton, TitleWrapper, NewTitle, CloseBtn, TextArea } from "../../styles/editStyle";
import { STextRoboto } from "../../styles/textStyle";

export default ({
  defaultText = "",
  onClose,
  onTouchMove,
  setFontSize,
  vWidth,
  translations,
  colors,
  printButtonStyle,
  onTextAreaMounted,
  customFrameRef,
  fontSize,
  defaultFontSize,
  ...rest
}) => {
  const onInput = (e, t) => {
    const textPathNode = customFrameRef.current;

    if (!textPathNode) return;

    const text = e.currentTarget.value;

    textPathNode.text(text);
    const textPathSize = textPathNode.getClientRect({ skipTransform: true });
    const textPathWidth = textPathSize.width + fontSize;
    if (textPathWidth >= vWidth) {
      setFontSize((2 * Math.PI * (vWidth / 2)) / text.length / 1.5);
    } else if (defaultFontSize !== fontSize) {
      setFontSize(defaultFontSize);
    }
  };

  const findTranslation = (translationKey, defaultText) => {
    return translations[translationKey] || defaultText;
  };

  return (
    <Portal>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          display: "flex",
          width: "100%",
          height: "100%",
          background: `${colors.backgroundColor || "white"}`,
          zIndex: 1000,
          justifyContent: "center",
          alignItems: "center",
        }}
        onTouchMove={onTouchMove}
      >
        <div style={{ top: "1%", position: "absolute", width: "100%" }}>
          <TitleWrapper>
            <CloseBtn onClick={onClose} color={colors.generalTextColor} />
            <NewTitle colors={colors}>
              <STextRoboto>{findTranslation("6bd9d6db-9b3b-4cc8-886c-baf29dab8952", "Type your text")}</STextRoboto>
            </NewTitle>
            <div style={{ width: "10%" }} />
          </TitleWrapper>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextArea
            ref={onTextAreaMounted}
            placeholder={findTranslation("48170d4e-5831-4fbb-abb8-646fe4b21cd6", "Type something...")}
            onInput={onInput}
            defaultValue={defaultText}
            colors={colors}
            {...rest}
          />
          <DoneButton id="printButton" data={printButtonStyle} onClick={onTouchMove}>
            {findTranslation("03dd0ade-987c-4840-8c97-af3027f890d1", "I'M DONE")}
          </DoneButton>
        </div>
      </div>
    </Portal>
  );
};
