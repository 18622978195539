import axios from "axios";

export function getNearLocationsList(latitude, longitude) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`/api/v1/locations`, {
        longitude,
        latitude,
      })
      .then((result) => {
        if (result && result.status === 200 && result.data && result.data.data) {
          resolve(result.data.data);
        } else {
          reject({ code: 4 });
        }
      })
      .catch((e) => {
        console.log(`${getNearLocationsList.name} error `, e);
        reject({ code: 4 });
      });
  });
}
