import React from 'react';

const SurpriseMeIcon = ({stroke}) =>  {
    return (
      <svg width="212" height="212" viewBox="0 0 212 212" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M76.8021 58.087C60.8128 61.5346 50.1268 67.7875 50.1268 74.929C50.1268 85.7952 74.8657 94.604 105.383 94.604C135.9 94.604 160.639 85.7952 160.639 74.929C160.639 67.7875 149.953 61.5346 133.963 58.087"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M122.434 65.4879C130.784 67.0251 136.277 69.6327 136.277 72.5907C136.277 77.3174 122.253 81.1491 104.954 81.1491C87.6554 81.1491 73.6318 77.3174 73.6318 72.5907C73.6318 69.6895 78.9153 67.1254 86.9967 65.5777"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M92.2279 24.436L92.2477 24.4096L92.2645 24.3837L92.2707 24.3744C92.2767 24.3654 92.2872 24.3499 92.3022 24.3284C92.332 24.2855 92.3796 24.2186 92.4452 24.1312C92.5764 23.9562 92.7791 23.6996 93.055 23.3883C93.6074 22.7651 94.4496 21.9269 95.5949 21.087C97.8794 19.4117 101.373 17.7258 106.213 17.7258C110.221 17.7258 113.487 18.9 115.737 20.8733C117.976 22.8366 119.28 25.651 119.28 29.0998C119.28 33.8897 116.443 36.5005 113.298 38.8396C112.952 39.0972 112.597 39.3546 112.241 39.6129C111.024 40.4953 109.791 41.3897 108.818 42.3491C107.526 43.6236 106.542 45.15 106.542 47.1588L106.542 54.2951C106.542 54.578 106.444 54.7042 106.352 54.7787C106.233 54.8762 106.036 54.9465 105.81 54.9465H103.633C103.324 54.9465 103.062 54.6992 103.062 54.3757L103.062 47.1588C103.062 42.6842 105.686 40.6425 108.711 38.4544C108.806 38.3857 108.901 38.3169 108.997 38.2477C111.891 36.1599 115.155 33.8051 115.155 28.8579C115.155 26.729 114.382 24.6979 112.838 23.1998C111.29 21.6986 109.04 20.8023 106.213 20.8023C99.7441 20.8023 96.4837 24.3253 94.7736 26.1731C94.6398 26.3177 94.5155 26.4521 94.3998 26.5737C94.27 26.7023 94.172 26.7706 94.1061 26.8054C94.0442 26.8382 94.0158 26.839 94.0107 26.8392C94.0057 26.8393 93.98 26.8401 93.9216 26.8134C93.8578 26.7843 93.7611 26.7249 93.6303 26.6087L92.0999 25.2394L92.0919 25.2324C92.0163 25.1661 91.9894 25.1019 91.9955 24.9896C92.0032 24.8469 92.0733 24.6421 92.2279 24.436Z"
          fill={stroke}
        />
        <path
          d="M104.422 66.7361C102.6 66.7361 101.059 65.1944 101.059 63.3727C101.059 61.4864 102.59 60.0092 104.422 60.0092C106.318 60.0092 107.785 61.4763 107.785 63.3727C107.785 65.2046 106.308 66.7361 104.422 66.7361Z"
          fill={stroke}
        />
        <path
          d="M160.638 76.3455L149.139 181.42C148.499 187.268 145.692 192.728 140.385 195.266C133.587 198.516 122.148 202.233 105.382 202.233C88.6167 202.233 77.1776 198.516 70.3798 195.266C65.0729 192.728 62.2657 187.268 61.6257 181.42L50.1265 76.3455"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M179.746 99.409C180.691 100.368 182.355 102.912 181.453 105.424C180.326 108.563 175.243 109.501 173.36 110.748C171.477 111.994 172.073 116.219 170.237 118.332"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M21.9594 45.311C25.8092 45.5412 26.2856 50.6649 27.666 53.383C28.7044 55.4279 32.1135 63.0771 35.2901 62.5916"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          d="M175.694 65.7301C175.694 58.7952 179.592 53.8941 186.781 52.7178C188.608 52.4189 190.85 52.4137 191.332 54.5851C191.689 56.1897 191.874 58.8597 190.282 59.8367C185.814 62.5786 181.197 59.7743 182.113 54.5267C183.181 48.4101 191.523 45.774 196.701 45.774"
          stroke={stroke}
          strokeWidth="3"
          strokeLinecap="round"
        />
        <circle cx="46.0093" cy="43.6962" r="2.5" transform="rotate(-8.5586 46.0093 43.6962)" fill={stroke} />
        <circle cx="60.7749" cy="19.1224" r="2.5" transform="rotate(-8.5586 60.7749 19.1224)" fill={stroke} />
        <circle cx="38.4443" cy="107.847" r="2.5" transform="rotate(-8.5586 38.4443 107.847)" fill={stroke} />
        <circle cx="17.1831" cy="78.1604" r="2.5" transform="rotate(-8.5586 17.1831 78.1604)" fill={stroke} />
        <circle cx="192.952" cy="83.777" r="2.5" transform="rotate(-8.5586 192.952 83.777)" fill={stroke} />
        <circle cx="150.11" cy="25.1135" r="2.5" transform="rotate(-8.5586 150.11 25.1135)" fill={stroke} />
        <circle cx="179.661" cy="14.8356" r="2.5" transform="rotate(-8.5586 179.661 14.8356)" fill={stroke} />
      </svg>
    );
  }

  export default SurpriseMeIcon;