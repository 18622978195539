import React from "react";

const LeftIcon = ({style}) => {
    return <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M24.6438 45.719C24.9763 39.5652 21.8309 19.048 18.7104 7.71712" stroke="white" strokeLinecap="round"/>
    <path d="M11.6657 21.0701C13.5952 16.8455 14.6044 12.9898 15.5655 8.57235C15.8811 7.12199 16.1472 5.69086 16.8583 4.32398C17.4531 3.18074 18.4102 4.76425 18.7097 5.04376C21.7737 7.9035 24.8736 10.731 27.9883 13.5143" stroke="white" strokeLinecap="round"/>
    </svg>
    
};

export default LeftIcon;