export const urls = [
  { code: "5eec0a0", locationId: "5d95eec0a0004d14ec7b940f", address: "https://mobile.coffeeripples.com/api/client/v1/locations/5d95eec0a0004d14ec7b940f/image" },
  { code: "9lKDTGVI2sa", locationId: "5b05dd65b69aa404c02858b7", address: "https://mobile.coffeeripples.com/api/client/v1/locations/5b05dd65b69aa404c02858b7/image" },
  { code: "0e30d2d", locationId: "5a5e0e30d2d8da139c0c8ec1", address: "https://mobile.coffeeripples.com/api/client/v1/locations/5a5e0e30d2d8da139c0c8ec1/image" },
  { code: "6562ecb", locationId: "5c91eb446e1b6562ecb741d5", address: "https://mobile.coffeeripples.com/api/client/v1/locations/5c91eb446e1b6562ecb741d5/image" },
  { code: "3b41213", locationId: "583b41213a435ed06ed2c6f6", address: "https://mobile.coffeeripples.com/api/client/v1/locations/583b41213a435ed06ed2c6f6/image" },
  { code: "7e0e047", locationId: "5dc7e0e047a082117046fb12", address: "https://mobile.coffeeripples.com/api/client/v1/locations/5dc7e0e047a082117046fb12/image" },
  { code: "2117046", locationId: "5dd0ff033db7003e646a0d7a", address: "https://mobile.coffeeripplescn.com/api/client/v1/locations/5dd0ff033db7003e646a0d7a/image" },
  { code: "d3110b9", locationId: "592d3110b96f839f4cc6b268", address: "https://mobile-st.coffeeripples.com/api/client/v1/locations/592d3110b96f839f4cc6b268/image" },
  { code: "5903f22", locationId: "5c44667f15903f22d498d2ef", address: "https://mobile-st.coffeeripples.com/api/client/v1/locations/5c44667f15903f22d498d2ef/image" },
  { code: "c1ab434", locationId: "5d14c1ab434aa553b8b67ffd", address: "https://mobile-st.coffeeripples.com/api/client/v1/locations/5d14c1ab434aa553b8b67ffd/image" },
  { code: "b92e7cd", locationId: "56854717eb92e7cdd390e8c5", address: "https://mobile-st.coffeeripples.com/api/client/v1/locations/56854717eb92e7cdd390e8c5/image" },
  { code: "747d8aa", locationId: "5e1b2fd341d6747d82bc68a9", address: "https://mobile-dev.coffeeripples.com/api/client/v1/locations/5e1b2fd341d6747d82bc68a9/image" },
];

export const CUSTOM_FRAME_TYPES = {
  CIRCLE_TEXT_FRAME: "circleTextFrame",
  CIRCLE_TEXT_FRAME_REVERSED: "reversedCircleTextFrame",
};

export const CUSTOM_FRAME_TYPES_LIST = [CUSTOM_FRAME_TYPES.CIRCLE_TEXT_FRAME, CUSTOM_FRAME_TYPES.CIRCLE_TEXT_FRAME_REVERSED];
