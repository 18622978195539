const MACARON_RADIUS = 350;
const RIPPLE_SIZE = 1700;

const FIRST_MACARON_CENTER_X = 850;
const FIRST_MACARON_CENTER_Y = 346;

const SECOND_MACARON_CENTER_X = 414;
const SECOND_MACARON_CENTER_Y = 1100;

const THIRD_MACARON_CENTER_X = 1286;
const THIRD_MACARON_CENTER_Y = 1100;

const ONE_MACARON_CENTER_X = 850;
const ONE_MACARON_CENTER_Y = 850;

export const createMacaronRipple = async ({macaron1Src, macaron2Src, macaron3Src}) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // global canvas settings
    ctx.canvas.width = RIPPLE_SIZE;
    ctx.canvas.height = RIPPLE_SIZE;
    ctx.canvas.style.padding = 0;
    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.fillStyle = "white";
    ctx.beginPath();
    ctx.arc(RIPPLE_SIZE / 2, RIPPLE_SIZE / 2, RIPPLE_SIZE / 2, 0, Math.PI*2, true);
    ctx.fill();
    ctx.restore();

    
    if (macaron1Src) {
        await drewImageAsync(ctx, macaron1Src, FIRST_MACARON_CENTER_X, FIRST_MACARON_CENTER_Y, MACARON_RADIUS)
    }

    if (macaron2Src) {
        await drewImageAsync(ctx, macaron2Src, SECOND_MACARON_CENTER_X, SECOND_MACARON_CENTER_Y, MACARON_RADIUS);
    }

    if (macaron3Src) {
        await drewImageAsync(ctx, macaron3Src, THIRD_MACARON_CENTER_X, THIRD_MACARON_CENTER_Y, MACARON_RADIUS)
    }
    
    const blob = await toBlobAsync(canvas);
    return blob;
};

const drewImageAsync = async (ctx, macaron, centerX, centerY, radius) => {
    return new Promise((resolve) => {
        const image = new Image();
        image.crossOrigin="anonymous"
        image.onload = () => {
            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, 0, Math.PI*2, true);
            ctx.clip();
            ctx.drawImage(image, centerX - radius, centerY - radius, radius * 2, radius * 2);
            ctx.restore();
            resolve();
        }
        image.src = macaron;
    })
}

const toBlobAsync = async (canvas) => {
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob);
        })
    })
}

export const createOneMacaronRipple = async (macaron) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // global canvas settings
    ctx.canvas.width = RIPPLE_SIZE;
    ctx.canvas.height = RIPPLE_SIZE;
    ctx.canvas.style.padding = 0;
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    ctx.beginPath();
    ctx.arc(RIPPLE_SIZE / 2, RIPPLE_SIZE / 2, RIPPLE_SIZE / 2, 0, Math.PI*2, true);
    ctx.clip();
    ctx.restore();

    await drewImageAsync(ctx, macaron, ONE_MACARON_CENTER_X, ONE_MACARON_CENTER_Y, MACARON_RADIUS);

    const blob = await toBlobAsync(canvas);
    return blob;
}