import React from "react";

import { PopupMessageStyle, PopupTitleStyle, PopupDescriptionStyle } from "../styles/popupStyle";
import { TextTypes } from "../../primitives/types/Shoutout";
import { ShoutoutTextWrapper, ButtonsWrapper } from ".";
import { CloseBtn } from "../styles/editStyle";

export function TextualWithLinkPopup(props) {
  let { title, description, buttons, hasCloseIcon, selectedBtn, isLoading } = props.data;
  let { getDataByLocal, getButtonAction, setSelectedBtn } = props.actions;
  let { generalTextColor, backgroundColor } = props.colors;

  function buttonClick(btnSelection, btnIndex) {
    setSelectedBtn(btnIndex);
    getButtonAction({ btnSelection, actions: props.actions, data: props.data });
  }

  // todo(vmyshko): impl showThanks splash?
  return (
    <PopupMessageStyle background={backgroundColor}>
      {hasCloseIcon && (
        <CloseBtn
          position="right"
          color={generalTextColor}
          onClick={() => {
            buttonClick({ action: "close" });
          }}
        />
      )}
      {title && (
        <PopupTitleStyle>
          <ShoutoutTextWrapper actions={{ getDataByLocal }} data={{ ...title, textType: TextTypes.PRINTED, generalTextColor, ...props.colors }} />
        </PopupTitleStyle>
      )}
      {description && description.text && (
        <PopupDescriptionStyle>
          <ShoutoutTextWrapper actions={{ getDataByLocal }} data={{ ...description, textType: TextTypes.REGULAR, generalTextColor, padding: "33px", ...props.colors }} />
        </PopupDescriptionStyle>
      )}
      {buttons && buttons.list.length > 0 && <ButtonsWrapper data={{ buttons, isLoading, selectedBtn }} actions={{ buttonClick, getDataByLocal }} colors={props.colors} />}
    </PopupMessageStyle>
  );
}
