import React, { useContext } from "react";
import { FramesContext, ActiveFrameContext } from "../../../context";
import { FrameTab, FrameTabs, EditText, EditInstruction } from "../../styles/editStyle";
import useSaveLeftScrollPosition from "../../../utils/useSaveScrollLeftPosition";
import { Phrase } from "../../Phrase";

export function FramesModeSettings(props) {
  const { filterButtons, setFrame, printStatus, framesScroll, setFramesScroll, editMode, general, movePhotoStyle, textInstructions } = props.data;

  const { frames } = useContext(FramesContext);

  const { activeFrame, setActiveFrame } = useContext(ActiveFrameContext);

  const { containerRef, onScroll } = useSaveLeftScrollPosition(framesScroll, setFramesScroll);
  const editModeStyle = { ...movePhotoStyle };
  const { chooseFrame } = textInstructions;

  if (!frames || frames.length === 0) return null;

  return (
    <EditText id="editText" style={editModeStyle}>
      <EditInstruction style={movePhotoStyle}>
        <Phrase Key={chooseFrame && chooseFrame.translationKey === "" ? "" : "e96d5af7-1d04-4a15-83f2-28f034948bc6"} default={chooseFrame ? chooseFrame.text : ""} />
      </EditInstruction>
      <FrameTabs ref={containerRef} disabled={editMode !== "frames"} size={frames.length} onScroll={onScroll}>
        {frames.map((frame, i) => {
          return (
            <FrameTab
              key={frame.id}
              index={i}
              disabled={editMode !== "frames"}
              src={frame.previewUrl}
              isActive={frame.id === activeFrame.id}
              data={filterButtons ? filterButtons.general : null}
              customFrameType={frame.customFrameType}
              colors={general.colors}
              onClick={
                printStatus
                  ? () => {
                      setFrame(frame);
                      setActiveFrame(frame);
                    }
                  : null
              }
            />
          );
        })}
      </FrameTabs>
    </EditText>
  );
}
