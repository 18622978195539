import { useEffect, useState } from "react";

function getActiveElement(targetMode, editMode) {
  if (editMode === "texts") return "texts";
  if (editMode === "edit") return "edit";

  if (editMode === "frames") {
    if (targetMode === "texts") {
      return "texts";
    }

    if (targetMode === "edit") {
      return "edit";
    }

    if (targetMode === "frames") {
      return "frames";
    }
  }

  return null;
}

export default function useActiveElement(editMode) {
  const [activeElement, setActiveElement] = useState(null);

  useEffect(() => {
    setActiveElement(editMode);
  }, [editMode]);

  return [
    activeElement,
    (targetMode) => {
      const newActiveElement = getActiveElement(targetMode, editMode);
      if (newActiveElement !== activeElement) {
        setActiveElement(newActiveElement);
      }
    },
  ];
}
