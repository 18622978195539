import React from "react";

import { ModalMsg } from "./ModalMsg";
import { SText, STextPrinted, TextWrapper } from "../styles/textStyle";
import { Phrase } from "../Phrase";

export function BadLocationMsg(props) {
  const { data, back } = props;
  const { instuction1, instuction2, instuction3 } = data;
  return (
    <ModalMsg back={back}>
      <TextWrapper>
        <STextPrinted color={instuction1.color}>
          <Phrase Key={instuction1.translationKey} default={instuction1.text} />
        </STextPrinted>
        <STextPrinted color={instuction2.color}>
          <Phrase Key={instuction2.translationKey} default={instuction2.text} />
        </STextPrinted>
        <SText color={instuction3.color}>
          <Phrase Key={instuction3.translationKey} default={instuction3.text} />
        </SText>
      </TextWrapper>
    </ModalMsg>
  );
}
