function getHistoryState(history, state) {
  const { addr, shortId, campaignShortId, token, demo ,iFrame} = history.location.state || {};

  return {
    ...(state || {}),
    addr,
    shortId,
    campaignShortId,
    token,
    demo,
    iFrame
  };
}

export function handleHistoryPush(history, pathname, state) {
  history.push({
    pathname,
    state: getHistoryState(history, state),
  });
}

export function handleHistoryReplace(history, pathname, state) {
  history.replace({
    pathname,
    state: getHistoryState(history, state),
  });
}
