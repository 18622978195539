export const colorDodge = function (img, mask) {
  let MarvinImage = window.MarvinImage;
  let imgBits = img.imageData.data;
  let maskBits = mask.imageData.data;

  let newImg = new MarvinImage(img.width, img.height);
  let bits = newImg.imageData.data;

  for (let i = 1; i < bits.length; i++) {
    bits[i] = ~~((256 * imgBits[i]) / (255 - maskBits[i] + 1));
  }

  return newImg;
};
