import React from "react";
import { ActionButtonsWrapper } from "../styles/IntroStyle";
import DrinkFlow from "./DrinkFlow";
import MacaroonFlow from "./MacaroonFlow";

const ActionButtons = (props) => {
  let { content, onDrinkFlowClick, onMacaronFlowClick, isMacaronSelected } = props;
  
  return (
    <ActionButtonsWrapper id="action" numberOfButtons={2}>
      <DrinkFlow data={content} isPhoto={true} onDrinkFlowClick={onDrinkFlowClick}/>
      <MacaroonFlow data={content} isPhoto={true} onMacaronFlowClick={onMacaronFlowClick} isMacaronSelected={isMacaronSelected}/>
    </ActionButtonsWrapper>
  );
}

export default ActionButtons;