import { sendFeedback } from "../../actions";
import { setCookie } from "../../utils/Utils";

export let getButtonAction = ({ btnSelection, actions, data }) => {
  let {
    setIsCardVisible,
    setIsPopUpOpen,
    setIsLoading,
    setShowThanks,
    setUserAnswer,
  } = actions;
  let { action, text } = btnSelection || {};
  switch (action) {
    case "feedback":
      let { _id, locationId } = data || {};
      let feedbackData = {
        sourceType: "shoutout",
        sourceId: _id,
        locationId,
        answer: text,
      };
      setIsLoading(true);
      sendFeedback(feedbackData)
        .then(() => {
          setTimeout(() => {
            setCookie("shoutout", { sourceId: _id, locationId }, 60);
            setIsLoading(false);
            setShowThanks(true);
          }, 800);
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
            setIsCardVisible(false);
          }, 2000);
          setTimeout(() => {
            setShowThanks(false);
            setIsPopUpOpen(false);
            setUserAnswer();
          }, 2100);
        });

      break;
    case "privacy": 
      setCookie("privacy", { ripplesPolicy: true }, 30);
      setIsCardVisible(false);
      setTimeout(() => {
        setIsPopUpOpen(false);
      }, 900);

      break;
    case "close":
      setIsCardVisible(false);
      setTimeout(() => {
        setIsPopUpOpen(false);
      }, 900);
      break;
    default:
      break;
  }
};
