import styled from 'styled-components';

export const ModalBody = styled.div`
width: 80%;
background-color: ${props => props.backgroundColor || 'white'};
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
border-radius: 15px;
box-shadow: 0 0 0 1px ${props => props.shadowColor || '#000000'}4D;
&:focus {
    outline: none !important;
}
`;

export const Note = styled.span`
font-size: 18px;
font-weight: 390;
line-height: 26px;
letter-spacing: 0em;
text-align: center;
color: ${props => props.textColor || '#000000'}66;
`;

export const Title = styled.span`
font-size: 24px;
font-weight: 420;
line-height: 34px;
letter-spacing: 0em;
text-align: center;
color: ${props => props.textColor || 'black'};
margin-top: 15px;
`;

export const ButtonsRow = styled.div`
width: 95%;
display: flex;
justify-content: space-between;
`;

export const Button = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 42%;
height: 42px;
font-size: 18px;
font-weight: bold;
line-height: 26px;
letter-spacing: 0.08em;
text-align: center;
border: 1px solid ${props => props.borderColor || 'black'};
color: ${props => props.textColor || 'black'};
background-color: ${props => props.backgroundColor || 'white'};
border-radius: 40px;
text-transform: uppercase;
`;

export const MacaronImage = styled.img`
width: 130px;
height: 130px;
border: 1px solid ${props => props.borderColor || 'black'};
border-radius: 50%;
`;

export const MacaronSurpriseContainer = styled.div`
width: 130px;
height: 130px;
border: 1px solid ${props => props.borderColor || 'black'};
border-radius: 50%;
display: flex;
justify-content: center;
align-items: center;
`;

export const MacaronAmountDuplicate = styled.div`
width: 48px;
height: 48px;
background-color: ${props => props.backgroundColor || 'white'};
color: ${props => props.textColor || '#e86161'};
border: 1px solid ${props => props.borderColor || 'black'};
font-weight: bold;
font-size: 30px;
line-height: 37px;
letter-spacing: 0em;
text-align: center;
border-radius: 50%;
position: absolute;
right: -10px;
bottom: 0;
display: flex;
justify-content: center;
align-items: center;
`