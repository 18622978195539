import React, { useEffect } from "react";
import { Image as KonvaImage } from "react-konva";
const sketchCanvas = document.getElementById("sketchCanvas");

const fitImage = (image, vWidth) => {
  let ratio = 1;
  if (image.width > vWidth) {
    ratio = vWidth / image.width;
  } else if (image.height > vWidth) {
    ratio = vWidth / image.height;
  }
  return { width: image.width * ratio, height: image.height * ratio };
};

const getImageNewSize = (vWidth, width, height, size) => {
  let newWidth = Math.round((width / vWidth) * size);
  let newHeight = Math.round((height / vWidth) * size);
  return { newWidth, newHeight };
};

const changeObjectPosition = (newPos, size, dImageWidth, dImageHeight) => {
  if (!newPos || !size || !dImageWidth || !dImageHeight) return {};
  let vWidth = getVWidth();
  let x = Math.floor((newPos.x / window.innerWidth) * size) - dImageWidth / 2;
  let y = Math.floor((newPos.y / vWidth) * size) - dImageHeight / 2;
  return { x, y };
};

const getEditContainer = () => {
  const editContainer = document.getElementById("editContainer");
  return editContainer;
};

const getEditContainerSize = () => {
  const editContainer = document.getElementById("editContainer");
  const editContainerSize = editContainer ? editContainer.getBoundingClientRect() : { height: window.innerWidth, width: window.innerWidth };
  return editContainerSize;
};
const getVWidth = () => {
  const editContainerSize = getEditContainerSize();
  const vWidth = Math.min(editContainerSize.width, editContainerSize.height);
  return Math.floor(vWidth);
};

const Image = React.forwardRef(
  ({ imageStatus, image, setZoom, circleRef, zoom, vWidth, isDragable, activeElement, editable, isRotate, setActiveElementMode, drawingAreaProps = {} }, ref) => {
    const { editMode, fromLibrary, imagePosition, setImagePosition, filter, imageDrawChange, frameUrl } = drawingAreaProps;

    const zoomWheelFn = (e) => {
      let currentStage = ref.current.getStage();
      if (!currentStage) return;

      let scaleBy = 1.2;
      let oldScale = ref.current.scaleX();
      let newScale = 0;
      let mousePointTo = {
        x: currentStage.getPointerPosition().x / oldScale - ref.current.position().x / oldScale,
        y: currentStage.getPointerPosition().y / oldScale - ref.current.position().y / oldScale,
      };

      if (e.evt.deltaY) {
        newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      } else {
        return;
      }

      setZoom({ x: newScale, y: newScale });

      let newPos = {
        x: -(mousePointTo.x - currentStage.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - currentStage.getPointerPosition().y / newScale) * newScale,
      };
      setImagePosition(newPos);
    };

    const centerImage = () => {
      if (!circleRef.current) return;
      let imageD = fitImage(image, vWidth);
      let a = circleRef.current.position();
      ref.current.offsetX(imageD.width / 2);
      ref.current.offsetY(imageD.height / 2);
      setImagePosition({ x: a.x, y: a.y });
    };

    const moveImage = (e) => {
      setImagePosition({
        x: e.currentTarget.attrs.x,
        y: e.currentTarget.attrs.y,
      });
    };

    useEffect(() => {
      const imageNode = ref.current;
      if (!imageNode) return;
      centerImage();
      setZoom({ x: 1, y: 1 });
    }, [image, imageStatus]);

    useEffect(() => {
      if (frameUrl) return;
      const imageNode = ref.current;
      if (!imageNode) return;
      imageNode.draw();
    }, [filter, imageDrawChange]);

    function onInteraction() {
      if (!editable) return;
      // todo(vmyshko): better rename 'edit' to 'image'
      setActiveElementMode("edit");
    }

    return (
      imageStatus === "loaded" && (
        <KonvaImage
          onWheel={image && editable ? zoomWheelFn : null}
          width={fitImage(image, vWidth).width}
          height={fitImage(image, vWidth).height}
          draggable={isDragable && editMode !== "texts" && (activeElement !== "texts" || editMode === "edit") && !isRotate && editable}
          ref={ref}
          image={fromLibrary ? image : sketchCanvas}
          listening={editable && editMode !== "texts"}
          x={imagePosition.x}
          y={imagePosition.y}
          scale={zoom}
          name={"image"}
          onDragEnd={editable && moveImage}
          onTap={onInteraction}
          onClick={onInteraction}
          onTouchStart={onInteraction}
        />
      )
    );
  }
);

export { Image, fitImage, getVWidth, getEditContainerSize, getImageNewSize, changeObjectPosition, getEditContainer };
