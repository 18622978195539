import React from "react";
import styled from "styled-components";

import { device } from "./breaks";
import { getNavigatorLanguage } from "../../services/Utils";

import backgroundImage from "../../assets/images/locations_top_background.png";
import noRipplesImage from "../../assets/images/no_ripples.jpg";
import location_icon from "../../assets/images/location_icon.jpg";

import "../../styles/Snapshot.scss";

const defaultWidth = 320;
const currentWidth = Math.min(window.innerWidth, window.innerHeight)
const ratio = currentWidth / defaultWidth * 0.95
export const MainWrapper = styled.div`
  height: 100%;
  @media ${device.mobileL} {
    display: block;
    flex-direction: column;
  }
`;
export const ScrollableWrapper = styled.div`
  height: 100%;
  background-color: white;
  overflow: scroll;

  display: flex;
  flex-direction: column;
`;

export function RipplesLogo(props) {
  const { width, height } = props;

  return (
    <svg width={width || "56"} height={height || "56"} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" style={{backgroundColor:'white', borderRadius:'50%'}}>
      <path d="M0.0179694 26.441C0.377357 16.9552 5.93788 8.26996 14.1559 3.49609C17.7378 1.41364 21.6471 0.351452 25.794 0.065939C39.317 -0.868469 51.4942 8.29791 54.4672 21.4295C57.8215 36.2383 48.675 50.9053 33.9701 54.3594C17.3504 58.2648 0.656881 45.4087 0.0179694 28.3837C-0.00598979 27.7328 -0.00598979 27.0839 0.0179694 26.441ZM28.9766 8.94281C27.2695 8.94281 25.5624 8.88491 23.8593 8.96278C22.6674 9.01669 21.4554 9.13848 20.3014 9.43198C13.7386 11.0991 9.27619 16.8673 9.25024 23.6378C9.23826 26.8303 9.23027 30.0249 9.25822 33.2174C9.26621 34.1519 9.34008 35.0962 9.48384 36.0187C10.6798 43.7515 17.8735 49.1942 25.6862 48.128C31.5782 47.3254 35.5374 43.9571 37.6518 38.4246C37.8655 37.8655 38.117 37.5501 38.708 37.3484C45.3128 35.1062 49.3918 28.4675 48.4494 21.5593C47.4731 14.4155 41.3675 9.02068 34.1618 8.94681C32.4347 8.92883 30.7057 8.94481 28.9766 8.94281Z" fill="#010101"/>
      <path d="M28.9966 11.6441C30.7915 11.6441 32.5845 11.6341 34.3794 11.6461C39.8301 11.686 44.8596 16.0026 45.7341 21.3835C46.8063 27.9902 42.8949 33.7744 36.3601 35.2219C35.8789 35.3277 35.6652 35.5114 35.5494 36.0325C34.4014 41.2037 30.5 44.9214 25.3608 45.5223C18.3767 46.339 13.2494 41.5032 12.2032 36.0665C12.0355 35.1939 11.9576 34.2915 11.9497 33.401C11.9197 30.1865 11.9357 26.97 11.9377 23.7554C11.9417 17.8096 15.8949 12.9818 21.727 11.8238C22.322 11.706 22.9409 11.6581 23.5479 11.6501C25.3648 11.6281 27.1797 11.6421 28.9966 11.6421M28.9567 14.048V14.042C28.6732 14.042 28.3876 14.036 28.1041 14.042C26.0955 14.0899 24.061 13.9521 22.0824 14.2257C17.8157 14.8147 14.3755 18.9137 14.3476 23.2203C14.3256 26.8302 14.3396 30.44 14.3476 34.0499C14.3476 34.4392 14.3975 34.8346 14.4694 35.2199C15.24 39.2351 17.598 41.8865 21.5333 42.9048C25.4047 43.9071 28.6971 42.6972 31.2787 39.6643C32.7362 37.9532 33.3652 35.9027 33.4171 33.6745C33.4291 33.1854 33.5968 33.0516 34.074 33.0416C34.7688 33.0276 35.4756 32.9797 36.1504 32.83C40.9183 31.7658 44.0849 27.0957 43.4699 22.3438C42.8809 17.7896 38.9157 14.1039 34.3355 14.054C32.5406 14.034 30.7476 14.05 28.9527 14.05" fill="#010101"/>
      <path d="M30.7533 30.4521C30.7533 31.5822 30.7573 32.5865 30.7533 33.5908C30.7393 37.564 27.5967 40.6388 23.6234 40.5689C20.1813 40.509 17.1165 37.58 17.0546 34.1518C16.9867 30.4362 16.9967 26.7185 17.0586 23.0028C17.1105 19.8901 19.9018 16.9132 23.0045 16.7854C26.6702 16.6357 30.348 16.6836 34.0197 16.6716C34.5588 16.6716 35.1099 16.7675 35.639 16.8913C38.8575 17.638 40.9519 20.4871 40.868 23.9692C40.7962 26.9561 38.3204 29.8132 35.2716 30.2924C34.1355 30.4701 32.9615 30.4082 31.8055 30.4482C31.484 30.4601 31.1626 30.4482 30.7533 30.4482" fill="#010101"/>
    </svg>

  );
}

export function RipplesLogoText(props) {
  const { style } = props;

  return (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" width="67" height="77" viewBox="0 0 67 77" fill="none">
      <ellipse cx="33.2993" cy="26.8976" rx="26.5982" ry="26.5636" fill="#E86161" />
      <path
        d="M38.6894 32.0064C38.5466 32.9093 38.3087 33.7172 37.9281 34.5251C36.5006 37.6139 33.3601 39.7524 29.7438 39.7524C26.8888 39.7524 24.3193 38.4218 22.6539 36.3309C21.4643 34.7627 20.703 32.8143 20.703 30.7234L20.703 23.0725C20.703 22.5972 20.7506 22.122 20.7982 21.6468C20.7982 21.5043 20.8458 21.3617 20.8458 21.2666C20.9885 20.6964 21.1788 20.1261 21.4167 19.6034C21.9402 18.3203 22.7967 17.1798 23.8435 16.2769C25.1282 15.1364 26.746 14.3761 28.5066 14.1385L28.5542 14.1385C28.9824 14.0909 29.3631 14.0434 29.7438 14.0434L37.4046 14.0434C42.4009 14.0434 46.4454 18.0827 46.4454 23.0725C46.4454 27.587 43.067 31.3887 38.6894 32.0064ZM37.4046 12.1426L29.7438 12.1426C29.2679 12.1426 28.7445 12.1901 28.2211 12.2376C23.6532 12.9029 19.9417 16.372 19.0376 20.8865C18.99 21.029 18.99 21.2191 18.9424 21.3617C18.8473 21.9319 18.7997 22.5022 18.7997 23.0725L18.7997 30.7234C18.7997 36.7586 23.7007 41.6532 29.7438 41.6532C34.6924 41.6532 39.0225 38.2792 40.3072 33.6222C44.9703 32.3391 48.3487 28.0147 48.3487 23.0725C48.3487 17.0373 43.4477 12.1426 37.4046 12.1426Z"
        fill="white"
      />
      <path
        d="M38.4515 28.2048C38.1184 28.2523 37.7377 28.2998 37.4047 28.2998L34.9779 28.2998L34.9779 30.7234C34.9779 32.4817 34.0738 34.0974 32.6939 35.0003L32.6464 35.0478C32.5988 35.0953 32.5036 35.1428 32.456 35.1904C32.3609 35.2379 32.3133 35.2854 32.2181 35.3329C32.1705 35.3329 32.1229 35.3805 32.0754 35.3805C31.9802 35.428 31.8374 35.4755 31.7423 35.523C31.6471 35.5705 31.5044 35.6181 31.4092 35.6656C31.3616 35.6656 31.314 35.7131 31.2664 35.7131C31.2189 35.7131 31.1713 35.7606 31.1237 35.7606C30.6954 35.8557 30.2196 35.9507 29.7438 35.9507C29.3631 35.9507 29.03 35.9032 28.697 35.8557C26.3178 35.3805 24.5097 33.242 24.5097 30.7234L24.5097 23.0725C24.5097 22.7873 24.5097 22.5022 24.6048 22.2171C24.6048 22.1696 24.6048 22.0745 24.6524 22.027C25.0807 19.8885 26.8412 18.2253 29.0776 17.8927C29.268 17.8451 29.5059 17.8451 29.7438 17.8451L37.4047 17.8451C40.3072 17.8451 42.6388 20.1737 42.6388 23.0725C42.6388 25.5911 40.8306 27.682 38.4515 28.2048ZM37.4047 15.9443L29.7438 15.9443C29.4107 15.9443 29.1252 15.9443 28.7445 16.0393C25.7944 16.4195 23.3677 18.7005 22.7491 21.6468C22.7491 21.7419 22.7015 21.8369 22.7015 21.932C22.6539 22.3121 22.6063 22.6923 22.6063 23.0725L22.6063 30.7234C22.6063 34.6676 25.7944 37.8516 29.7438 37.8516C33.6932 37.8516 36.8812 34.6676 36.8812 30.7234L36.8812 30.2006L37.4047 30.2006C41.354 30.2006 44.5421 27.0167 44.5421 23.0725C44.5421 19.1282 41.3065 15.9443 37.4047 15.9443Z"
        fill="white"
      />
      <path
        d="M1.62013 62.5397C0.787604 63.2367 0.333496 64.166 0.333496 65.3277L0.333496 73.1496L1.54445 73.1496L1.54445 65.3277C1.54445 64.863 1.62013 64.5533 1.84719 64.2435C1.99856 63.9337 2.30129 63.7014 2.60403 63.469C2.90677 63.2367 3.36088 63.1592 3.81499 63.0044C4.26909 62.9269 4.79889 62.8495 5.32868 62.8495L6.84237 62.8495L6.84237 61.6104L5.25299 61.6104C3.66362 61.6104 2.52835 61.9201 1.62013 62.5397Z"
        fill="black"
      />
      <path d="M10.1719 61.6875L8.96094 61.6875L8.96094 73.1493L10.1719 73.1493L10.1719 61.6875Z" fill="black" />
      <path
        d="M21.9798 62.4625C21.5257 62.1528 20.9959 61.9204 20.3905 61.7655C19.785 61.6106 19.1038 61.5332 18.347 61.5332C17.6658 61.5332 16.9847 61.6106 16.3792 61.7655C15.7737 61.9204 15.2439 62.1528 14.7898 62.4625C14.3357 62.7723 13.9573 63.1595 13.7302 63.6242C13.5032 64.0889 13.3518 64.7084 13.3518 65.4054L13.3518 76.7124L14.5628 76.7124L14.5628 72.2206C15.3953 72.9176 16.6062 73.2274 18.2713 73.2274C19.0282 73.2274 19.6336 73.1499 20.3148 72.995C20.9203 72.8402 21.4501 72.6078 21.9042 72.298C22.3583 71.9883 22.661 71.601 22.9637 71.1364C23.1908 70.6717 23.3422 70.0521 23.3422 69.4326L23.3422 65.328C23.3422 64.7084 23.1908 64.0889 22.9637 63.6242C22.7367 63.1595 22.3583 62.7723 21.9798 62.4625ZM22.0555 69.51C22.0555 69.9747 21.9798 70.4394 21.7528 70.7491C21.5257 71.0589 21.2987 71.3687 20.9959 71.5236C20.6932 71.6785 20.2391 71.8334 19.785 71.9108C19.3309 71.9883 18.8011 72.0657 18.2713 72.0657C17.3631 72.0657 16.6819 71.9883 16.1521 71.7559C15.6223 71.5236 15.3196 71.2913 15.0926 71.0589C14.8655 70.7491 14.7141 70.5168 14.6384 70.207C14.5628 69.8973 14.5628 69.6649 14.5628 69.51L14.5628 65.328C14.5628 64.8633 14.6384 64.4761 14.8655 64.1663C15.0169 63.8566 15.3196 63.5468 15.6223 63.3919C15.9251 63.1595 16.3035 63.0821 16.7576 62.9272C17.2117 62.8498 17.7415 62.7723 18.2713 62.7723C18.8011 62.7723 19.3309 62.8498 19.785 62.9272C20.2391 63.0047 20.6175 63.1595 20.9959 63.3919C21.2987 63.6242 21.6014 63.8566 21.7528 64.1663C21.9042 64.4761 22.0555 64.8633 22.0555 65.328L22.0555 69.51Z"
        fill="black"
      />
      <path
        d="M35.0731 62.4625C34.619 62.1528 34.0892 61.9204 33.4837 61.7655C32.8783 61.6106 32.1971 61.5332 31.4402 61.5332C30.7591 61.5332 30.0779 61.6106 29.4725 61.7655C28.867 61.9204 28.3372 62.1528 27.8831 62.4625C27.429 62.7723 27.0505 63.1595 26.8235 63.6242C26.5964 64.0889 26.4451 64.7084 26.4451 65.4054L26.4451 76.7124L27.656 76.7124L27.656 72.2206C28.4886 72.9176 29.6995 73.2274 31.3646 73.2274C32.1214 73.2274 32.7269 73.1499 33.408 72.995C34.0135 72.8402 34.5433 72.6078 34.9974 72.298C35.4515 71.9883 35.7543 71.601 36.057 71.1364C36.2841 70.6717 36.4354 70.0521 36.4354 69.4326L36.4354 65.328C36.4354 64.7084 36.2841 64.0889 36.057 63.6242C35.83 63.1595 35.4515 62.7723 35.0731 62.4625ZM35.2245 69.51C35.2245 69.9747 35.1488 70.4394 34.9217 70.7491C34.6947 71.0589 34.4676 71.3687 34.1649 71.5236C33.8622 71.6785 33.408 71.8334 32.9539 71.9108C32.4998 71.9883 31.97 72.0657 31.4402 72.0657C30.532 72.0657 29.8509 71.9883 29.3211 71.7559C28.7913 71.5236 28.4886 71.2913 28.2615 71.0589C28.0344 70.7491 27.8831 70.5168 27.8074 70.207C27.7317 69.8973 27.7317 69.6649 27.7317 69.51L27.7317 65.328C27.7317 64.8633 27.8074 64.4761 28.0344 64.1663C28.1858 63.8566 28.4886 63.5468 28.7913 63.3919C29.094 63.1595 29.4725 63.0047 29.9266 62.9272C30.3807 62.8498 30.9105 62.7723 31.4402 62.7723C31.97 62.7723 32.4998 62.8498 32.9539 62.9272C33.408 63.0047 33.7865 63.1595 34.1649 63.3919C34.4676 63.6242 34.7704 63.8566 34.9217 64.1663C35.0731 64.4761 35.2245 64.8633 35.2245 65.328L35.2245 69.51Z"
        fill="black"
      />
      <path d="M40.8247 58.4346L39.6138 58.4346L39.6138 73.0717L40.8247 73.0717L40.8247 58.4346Z" fill="black" />
      <path
        d="M52.5559 62.4625C52.1018 62.1528 51.572 61.9204 50.9665 61.7655C50.361 61.6106 49.6799 61.5332 48.9987 61.5332C48.2419 61.5332 47.6364 61.6106 47.0309 61.7655C46.4254 61.9204 45.8957 62.1528 45.4415 62.4625C44.9874 62.7723 44.6847 63.1595 44.4576 63.6242C44.2306 64.0889 44.0792 64.631 44.0792 65.328L44.0792 69.4326C44.0792 70.1296 44.2306 70.6717 44.4576 71.1364C44.6847 71.601 45.0631 71.9883 45.5172 72.298C45.9713 72.6078 46.5011 72.8402 47.1066 72.9951C47.7121 73.1499 48.3932 73.2274 49.0744 73.2274C49.6042 73.2274 50.2097 73.1499 50.6638 73.0725C51.1936 72.9951 51.6477 72.8402 52.0261 72.6853C52.4045 72.5304 52.7829 72.298 53.0857 71.9883C53.3884 71.7559 53.6155 71.3687 53.6912 71.0589L52.5559 70.4394C52.2532 70.904 51.8747 71.2913 51.3449 71.5236C50.8152 71.7559 49.9826 71.9108 48.9987 71.9108C48.4689 71.9108 47.9391 71.8334 47.485 71.7559C47.0309 71.6785 46.6525 71.5236 46.2741 71.3687C45.9713 71.1364 45.6686 70.904 45.5172 70.5943C45.2902 70.2845 45.2145 69.8973 45.2145 69.3551L45.2145 67.8062L53.9182 67.8062L53.9182 65.328C53.9182 64.7084 53.7669 64.0889 53.5398 63.6242C53.3127 63.1595 52.9343 62.7723 52.5559 62.4625ZM52.7073 66.6446L45.2902 66.6446L45.2902 65.328C45.2902 65.1731 45.2902 64.9408 45.3659 64.7084C45.4415 64.3987 45.5172 64.1663 45.7443 63.8566C45.9713 63.5468 46.3498 63.3144 46.8796 63.0821C47.4093 62.8498 48.0905 62.7723 48.9987 62.7723C49.6799 62.7723 50.2854 62.8498 50.7395 63.0047C51.1936 63.1595 51.572 63.3144 51.8747 63.5468C52.1775 63.7791 52.4045 64.0889 52.4802 64.3212C52.6316 64.631 52.7073 64.9408 52.7073 65.2506L52.7073 66.6446Z"
        fill="black"
      />
      <path
        d="M65.1947 67.4186C64.7406 67.1863 64.2108 66.954 63.6053 66.7991C62.9998 66.6442 62.3187 66.6442 61.6375 66.6442C61.1077 66.6442 60.5779 66.6442 60.1238 66.5667C59.6697 66.4893 59.2913 66.4118 58.9886 66.257C58.6858 66.1021 58.3831 65.9472 58.2317 65.7148C58.0803 65.4825 57.929 65.1727 57.929 64.7855C57.929 64.0111 58.2317 63.4689 58.9129 63.2366C59.594 62.9268 60.5022 62.7719 61.6375 62.7719C62.6214 62.7719 63.4539 62.9268 64.1351 63.1592C64.8163 63.4689 65.2704 63.8562 65.4217 64.4757L66.557 63.8562C66.1786 63.0043 65.5731 62.4622 64.7406 61.9975C63.9081 61.6103 62.8485 61.3779 61.6375 61.3779C60.9564 61.3779 60.2752 61.4554 59.6697 61.5328C59.0642 61.6103 58.5345 61.8426 58.0803 62.0749C57.6262 62.3073 57.2478 62.6945 57.0208 63.0817C56.7937 63.4689 56.6423 64.0111 56.6423 64.6306C56.6423 65.2502 56.7937 65.7923 57.0964 66.1795C57.3992 66.5667 57.7776 66.954 58.2317 67.1863C58.6858 67.4186 59.2156 67.5735 59.8211 67.651C60.4266 67.7284 61.032 67.8059 61.6375 67.8059C62.1673 67.8059 62.6214 67.8059 63.0755 67.8833C63.5296 67.9607 63.9081 68.0382 64.2108 68.1931C64.5135 68.348 64.8163 68.5803 64.9676 68.8126C65.119 69.045 65.2704 69.4322 65.2704 69.8194C65.2704 70.5164 64.9676 71.0585 64.3622 71.3683C63.7567 71.6781 62.8485 71.833 61.6375 71.833C60.9564 71.833 60.4266 71.7555 59.9725 71.6781C59.5184 71.6007 59.1399 71.5232 58.8372 71.3683C58.5345 71.2134 58.3074 71.0585 58.156 70.9037C58.0047 70.7488 57.8533 70.5939 57.7776 70.439L56.718 71.0585C57.0208 71.7555 57.6262 72.2202 58.4588 72.6074C59.2913 72.9172 60.3509 73.1496 61.6375 73.1496C62.3187 73.1496 62.9242 73.0721 63.5296 72.9947C64.1351 72.9172 64.6649 72.6849 65.119 72.4526C65.5731 72.2202 65.9515 71.833 66.1786 71.4458C66.4056 71.0585 66.557 70.5164 66.557 69.8969C66.557 69.2773 66.4056 68.8126 66.1786 68.348C65.9515 68.0382 65.6488 67.7284 65.1947 67.4186Z"
        fill="black"
      />
      <path
        d="M9.5674 60.4487C10.1526 60.4487 10.627 59.9633 10.627 59.3645C10.627 58.7657 10.1526 58.2803 9.5674 58.2803C8.9822 58.2803 8.50781 58.7657 8.50781 59.3645C8.50781 59.9633 8.9822 60.4487 9.5674 60.4487Z"
        fill="black"
      />
    </svg>
  );
}

export const ScrollableContent = styled.div`
  background: white;
  flex-grow: 1;
  overflow: auto;

  /* for Firefox */
  min-height: 0;
`;
export const TopLine = styled.div`
  height: 40px;
  width: 100%;
  background-color: #e86161;
`;

export const HeaderWrapper = styled.div`
  height: 30%;
  width: 100%;
  background-color: white;
`;

export const Header = styled.div`
  justify-self: center;
  display: flex;
  min-width: 100%;
  min-height: 100%
  background: url(${(props) => (props.img ? props.img : backgroundImage)}) no-repeat bottom;
  background-size: ${(props) => (props.size ? props.size : "cover")} ;
`;

export const LogoWrapper = styled.div`
  width: 45px;
  height: 45px;
  margin: 0;
  padding: 0;
  display: flex;
`;

export const LogoSpan = styled.div`
  display: flex;
  justify-content: center;

  transform: translateY(-50%);
`;

export const WelcomeContainer = styled.div`
  height: ${() => window.innerHeight * 0.65 + 40}px;
  width: 100%;
  display: block;
  justify-content: center;
  align-items:center;
  position: relative;
  margin-top: ${() => window.innerHeight * 0.05 * ratio * 0.75}px};
`;

export const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const LocationAvatar = styled.div`
  justify-self: center;
  flex-shrink: 0;
  display: flex;
  background: url(${(props) => props.image}) no-repeat center;
  background-size: ${(props) => (props.size ? props.size : "contain")};
  height: 55px;
  width: 55px;

  margin: 0 16px;
`;

export const LocationMiddleContainer = styled.div`
  display: block;
  width:70% ;
`;
export const LocationKmContainer = styled.div`
  width: 30%;
  display: flex;
  align-self: flex-end;
  text-transform: lowercase;
`;
export const LocationInfoContainer = styled.div`
  display: flex;
  align-self: center;
  width:75% ;
`;
export const LocationNameText = styled.div`
  font-family: "Brandon Grotesque regular";
  font-size: 18px;
  align-self: center;
  font-weight: 390;
  // text-align: center;
  margin: 0 auto;
  color: #232323;
`;

export const LocationSubText = styled.div`
  font-family: "Brandon Grotesque regular";
  font-size: 16px;
  font-weight: 390;
  color: #939393;
  align-self: flex-end;
  word-break: break-word;
`;

export const Separator = styled.div`
  background: #e0e0e0;
  height: 1px;
  width: 94%;
  margin-left: 3%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const NoRipplesWrapper = styled.div`
  height: ${() => window.innerHeight - 40}px;
  width: 100%;
  background-color: white;
`;

export const NoRipplesImage = styled.div`
  width: ${() => window.innerWidth}px;
  height: ${() => window.innerHeight / 2.5}px;
  margin-top: 30px;
  background: url(${noRipplesImage}) no-repeat bottom;
  background-size: ${(props) => (props.size ? props.size : "contain")};
`;

export const Label = styled.div`
  font-family: ${(props) => props.data.FontFamily};
  font-size: ${(props) => props.data.FontSize};
  align-self: center;
  font-weight: ${(props) => props.data.FontWeight};
  text-align: center;
  color: ${(props) => props.data.color};
`;

export const WelcomeLabel = styled.div`
  font-size: ${getNavigatorLanguage() !== "en" ? "1.5em" : "1.7em"};
  align-self: center;
  font-weight: "350";
  text-align: center;
  color: #000;
`;

export const GetDrinkPrinterLabel = styled.div`
  font-family: "Brandon Grotesque regular", "Noto Sans JP";
  font-size:${getNavigatorLanguage() !== "en" ? "16px" : "20px"};
  @media ${device.mobileS} {
    font-size: ${getNavigatorLanguage() !== "en" ? "16px" : "22px"};
  }
  align-self: center;
  font-weight: lighter;
  text-align: center;
  color: #000;
`;

export const OoopsLabel = styled.div`
  font-size: 30px;
  align-self: center;
  font-weight: 350;
  text-align: center;
  color: #000;
`;

export const NoRippleMakersLabel = styled.div`
  font-family: "Brandon Grotesque regular", "Noto Sans JP";
  font-size: ${getNavigatorLanguage() !== "en" ? "16px" : "20px"};
  align-self: center;
  font-weight: 390;
  text-align: center;
  color: #333333;
  padding: 15px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  z-index: 20;
  height: 20%;
  margin-top: 20%;
`;

export const SearchButton = styled.div`
  width: 220px;
  height: 45px;
  font-size: 16px;
  font-family: "Brandon Grotesque regular", "Noto Sans JP";
  font-weight: bold;
  color: #fff;
  text-align: center;
  align-self: center;
  background-color: #e86161;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ClosestLocWrapper = styled.div`
  height: ${() => window.innerHeight - 40}px;
  width: 100%;
  background-color: white;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ModalClosestContainer = styled.div`
  width: 80%;
  height: auto;
  padding-bottom: 5%;
  margin: 10%;
  align-self: center;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
`;

export const LocationIcon = styled.div`
  width: 50px;
  height: 50px;
  margin-top: 30px;
  align-self: center;
  background: url(${location_icon}) no-repeat bottom;
  background-size: ${(props) => (props.size ? props.size : "contain")};
`;

export const ClosestLocatinLabel = styled.div`
  font-size: 23px;
  align-self: center;
  font-weight: 390;
  text-align: center;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.6);
  width: 90%;
  margin-top: 10%;
  margin-bottom: 5%;
`;

export const ClosestLocatinAddress = styled.div`
  font-family: "Brandon Grotesque regular";
  font-size: 23px;
  align-self: center;
  font-weight: 390;
  text-align: center;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.7);
  width: 90%;
`;

export const UnderlineLabel = styled.div`
  font-family: "Brandon Grotesque regular";
  font-size: 22px;
  align-self: center;
  font-weight: bold;
  text-align: center;
  color: #000;
  text-decoration: underline;
`;

export const EnterButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  z-index: 20;
  height: 20%;
  margin-top: 5%;
`;

export const LoadingModal = styled.div`
  height: ${() => window.innerHeight}px;
  width: 100%;
  justify-content: center;
  background: url(${(props) => props.backgroundImage}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const LoadingLocations = styled.div`
  font-family: "Brandon Grotesque regular";
  font-size: 20px;
  align-self: center;
  font-weight: 390;
  text-align: center;
  color: #767676;
`;

export const CenteredContainer = styled.div`
  height: 100%;
  width: 100%;
  display: block;
  justify-content: center;
  align-items: center;
  align-self: center;
  align: center;
`;
