import axios from "axios";

export function getFrames(locationId) {
  if (!locationId) {
    return;
  }

  return axios
    .get(`/api/v1/frames/${locationId}`)
    .then((response) => response.data.data)
    .then((data) => {
      if (!data) {
        throw new Error("cant find content");
      }
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
}
