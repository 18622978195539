import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Button, ButtonsRow, MacaronImage, MacaronSurpriseContainer, ModalBody, Title } from './styles';
import CloseIcon from '../icons/CloseIcon';
import { Phrase } from '../../Phrase';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';

const DeleteModal = ({isOpen, onConfirm, onClose, rippleSrc, content, isSurprise}) => {
  const { general = {}, macaron } = content;
    const { colors = {} } = general;
    const {texts = {}} = macaron;

    const deleteThisDesignText = texts.deleteThisDesign || {};

    const yesText = texts.yes || {};
    const noText = texts.no || {};

    const backgroundColor = colors.backgroundColor;
    const textColor = colors.generalTextColor;
    const buttonBackground = content.home.buttons.snap ? content.home.buttons.snap.backgroundColor : content.home.buttons.library.backgroundColor;
    const buttonTextColor = content.home.buttons.snap ? content.home.buttons.snap.textColor : content.home.buttons.library.textColor;

  return (
      <Modal
        open={isOpen}
        onClose={onClose}
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        BackdropProps={{style: {backgroundColor: '#000000cc'}}}
      >
        <ModalBody backgroundColor={backgroundColor} shadowColor={textColor}>
            <CloseIcon fill={textColor} style={{alignSelf: 'flex-end'}} onClick={onClose}/>
            <Title textColor={textColor} style={{marginBottom: '15px'}}>
                <Phrase Key={deleteThisDesignText ? deleteThisDesignText.translationKey : '2d948142-6970-4a45-abce-fcf18dd8870a'} default={deleteThisDesignText.text || ''}/>
            </Title>
            {isSurprise ? <MacaronSurpriseContainer borderColor={textColor}>
               <QuestionMarkIcon fill={textColor}/>
            </MacaronSurpriseContainer> :
            <MacaronImage src={rippleSrc} alt=''/>
            }
            <ButtonsRow style={{marginTop: '20px'}}>
                <Button onClick={onConfirm} backgroundColor={buttonBackground} borderColor={buttonBackground} textColor={buttonTextColor}>
                <Phrase Key={yesText.translationKey} default={yesText.text ||  ''}/>

                </Button>
                <Button onClick={onClose} backgroundColor={backgroundColor} textColor={buttonBackground} borderColor={buttonBackground}>
                <Phrase Key={noText.translationKey} default={noText.text || ''}/>
                </Button>
            </ButtonsRow>
        </ModalBody>
      </Modal>
  );
}

export default DeleteModal;