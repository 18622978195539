import React, { useContext } from "react";
import { MainWrapper } from "./styles/IntroStyle";
import {
  MessageSuccess,
  SocialTab,
  FollowUS,
  GetSocialText,
  PrintWrap,
  DemoPrintButton,
  PoweredByOld,
  MessageContainer,
  ResultImage,
  ImageContainer,
  MsgHeader,
  LastPageLogoWrapper,
  LastPageLogo,
  ResultDemoText,
} from "./styles/successMessage";
import { Phrase } from "./Phrase";
import { ContentContext, MainContext } from "../context";
import { handleHistoryPush, handleHistoryReplace } from "../utils/history";
import { GaEvents, sendGAEvent } from "../utils/googleAnalytics";

const Result = (props) => {
  const { content } = useContext(ContentContext);
  const { number, mobileQData } = props.location.state || {};
  const { main } = useContext(MainContext);

  const handleBackBtn = () => {
    if (main.preview) {
      handleHistoryPush(props.history, "/preview");
      return;
    }

    handleHistoryPush(props.history, "/home");
  };

  if (!content || !number) {
    handleHistoryReplace(props.history, '/home')
    return null;
  }

  // get style
  const social = content.results.social || null;
  const followAddress = social.followMe.instagram || null;
  const getSocialStyle = social.texts.getSocial;

  const showIt = content.results.texts.showOurStaff;
  const printAnotherStyle = content.results.buttons.printAnotherButton;
  const poweredByStyle = content.results.poweredBy || "";
  const logoLocation = (content.general.images && content.general.images.logo) || "";

  const followMe = () => {
    sendGAEvent(GaEvents.ClickOnSocial, {
      eventCategory: "social",
      eventLabel: followAddress,
      locationId: content.locationId,
    });
    window.location.href = followAddress;
  };

  return (
    <>
      <MainWrapper id="successmw" backgroundColor={content.general.colors.backgroundColor}>
        <MsgHeader id="msgHeader">
          {logoLocation.location && (
            <LastPageLogoWrapper>
              <LastPageLogo src={logoLocation.location} alt="" />
            </LastPageLogoWrapper>
          )}
        </MsgHeader>
        <MessageContainer id="msgContainer">
          <ImageContainer>
            <ResultImage src={mobileQData} />
          </ImageContainer>
          <MessageSuccess id="msgSuccess">
            <ResultDemoText style={showIt}>
              <Phrase Key={"9a880147-aada-4214-836d-d2b5ae93a680"} default={"Long press on the image to share with friends"} />
            </ResultDemoText>
          </MessageSuccess>
          <PrintWrap id="PrintWrap">
            <DemoPrintButton id="printButton" onClick={handleBackBtn} data={showIt}>
              <Phrase Key={printAnotherStyle.translationKey} default={printAnotherStyle.text || ''} />
            </DemoPrintButton>
          </PrintWrap>
        </MessageContainer>
        <SocialTab id="socialtab" background={content.general.colors.messageBackgroundColor}>
          <GetSocialText id="getsocialtext" style={getSocialStyle}>
            <Phrase Key={getSocialStyle.translationKey} default={getSocialStyle.text} />
          </GetSocialText>
          <FollowUS data={getSocialStyle} onClick={followMe}></FollowUS>
          <PoweredByOld data={poweredByStyle}></PoweredByOld>
        </SocialTab>
      </MainWrapper>
    </>
  );
};
export default Result;
