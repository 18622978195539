import React, {useEffect, useRef} from 'react';
import Konva from 'konva';
import { TextPath, Arc } from 'react-konva';
import { CUSTOM_FRAME_TYPES } from '../../../../constants';

export default React.forwardRef(({fontSize, reversed, vWidth, customFrameText, ...rest}, ref) => {
    const arcRef = useRef();
    useEffect(() => {
        if (!ref.current) return;
        if (!ref.current.rotateFrame) {
            ref.current.rotateFrame = (customFrameType, val) => {
                const isValValid = val || val === 0;
                const rotation = val - arcRef.current.angle() / 2;
                if (arcRef.current && isValValid) arcRef.current.rotation(customFrameType === CUSTOM_FRAME_TYPES.CIRCLE_TEXT_FRAME_REVERSED ? rotation + 180 : rotation);

                if (isValValid) {
                    ref.current.rotation(val);
                } else {
                    return ref.current.rotation();
                }

            };
        }
        ref.current.letterSpacing(fontSize / 2);
    }, [ref.current]);

    useEffect(() => {
        const tempTextPath = new Konva.TextPath({
            y: 1000,
            x: 1000,
            align: 'center',
            letterSpacing: fontSize * 0.75,
            data: 'M1000,1000 L3000,1000',
            text: customFrameText,
            fontSize: fontSize,
        });

        const clientRect = tempTextPath.getClientRect();
        tempTextPath.destroy();
        const newAngle = clientRect.width / (2 * Math.PI * (vWidth / 2)) * 360;
        const rotation = ref.current.rotation() - newAngle / 2;

        arcRef.current.angle(newAngle);
        arcRef.current.rotation(reversed ? rotation + 180 : rotation);
        arcRef.current.draw();
        arcRef.current.getLayer().draw();
    }, [customFrameText]);

    return (
        <>
            <Arc
                angle="0"
                x={vWidth / 2}
                y={vWidth / 2}
                innerRadius={vWidth / 2 - fontSize * 2}
                outerRadius={vWidth / 2}
                ref={arcRef}
                rotation="0"
                fill="white"
                opacity={0.9}
            />
            <TextPath
                align="center"
                data={reversed ? (
                    `M${fontSize + (vWidth / 2 - fontSize) * 2},${vWidth / 2} a${vWidth / 2 - fontSize},${-(vWidth / 2 - fontSize)} 0 1 0 0,-1Z`
                ) : (
                    `M${fontSize},${vWidth / 2} a${vWidth / 2 - fontSize},${vWidth / 2 - fontSize} 0 1 0 0,-1Z`
                )}
                text={customFrameText}
                fontSize={fontSize}
                fill="black"
                offsetX={vWidth / 2}
                offsetY={vWidth / 2}
                x={vWidth / 2}
                y={vWidth / 2}
                rotation="90"
                ref={ref}
                draggable={false}
                listening={true}
                {...rest}
            />
        </>
    )
});
