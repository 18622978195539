import React from "react";

const SurpriseIcon = ({stroke}) => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.00766 14.8855H3.28105V27.7407C3.28105 27.9833 3.52361 28.1652 3.8268 28.1652H14.4991H17.1672H27.8395C28.1426 28.1652 28.3852 27.9833 28.3852 27.7407V14.8855H29.6586C29.9618 14.8855 30.2043 14.7035 30.2043 14.461V11.4291C30.2043 11.1865 29.9618 11.0046 29.6586 11.0046H19.9565C21.8363 10.1557 24.2012 8.63975 24.6863 7.24508C25.0501 6.15359 24.565 5.12275 23.4735 4.51637C22.1395 3.78871 20.381 3.84935 18.9863 4.57701C17.7735 5.18339 16.5001 7.18444 15.8331 8.70039C15.1661 7.1238 13.8927 5.18339 12.6799 4.57701C11.2853 3.84935 9.46613 3.84935 8.19273 4.51637C7.04061 5.12275 6.61615 6.15359 6.97997 7.24508C7.46508 8.57911 9.8906 10.1557 11.7097 11.0046H2.00766C1.70447 11.0046 1.46191 11.1865 1.46191 11.4291V14.461C1.46191 14.7035 1.70447 14.8855 2.00766 14.8855ZM4.49381 14.8855H14.014V27.3162H4.49381V14.8855ZM15.1661 14.461V11.9142H16.6821V14.461V27.3162H15.1661V14.461ZM27.2937 27.3162H17.7735V14.8855H27.2937V27.3162ZM29.1735 13.9759H27.9001H17.7735V11.8536H29.1735V13.9759ZM16.6214 10.1557C16.7427 9.00358 18.3799 5.91104 19.6533 5.24402C20.8054 4.63765 22.0788 4.75892 22.8671 5.18339C23.5948 5.60785 23.898 6.21423 23.6554 6.94189C23.231 8.27592 19.9565 10.0951 18.3799 10.6408C17.4704 10.944 17.1065 11.0046 16.864 10.944C16.8033 10.944 16.8033 10.944 16.7427 10.944C16.7427 10.944 16.5608 10.8227 16.6214 10.1557ZM8.1321 7.00253C7.88954 6.27487 8.19273 5.60785 8.92039 5.24402C9.34486 5.00147 9.8906 4.8802 10.4363 4.8802C10.9821 4.8802 11.5278 5.00147 12.0736 5.30466C13.347 5.97168 15.0448 9.06422 15.1055 10.2163C15.1661 10.8834 14.9842 11.0046 14.9842 11.0046C14.9842 11.0046 14.9236 11.0046 14.8629 11.0046C14.681 11.0046 14.2565 10.944 13.347 10.7014C11.831 10.1557 8.55656 8.33656 8.1321 7.00253ZM2.61404 11.8536H14.014V13.9759H3.88743H2.61404V11.8536Z" fill={stroke || "white"} stroke={stroke || "white"} strokeWidth="0.2" strokeMiterlimit="10"/>
    </svg>
        
};

export default SurpriseIcon;
