import styled from "styled-components";

export const MacaronSectionContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 90%;
color: ${props => props.textColor || 'black'};
`

export const MacaronSectionText = styled.span`
`

export const AmountSectionContainer = styled.div`
display: flex;
justify-content: space-between;
width: 90%;
`

export const AmountSelection = styled.div`
width: 30%;
height: 45px;
display: flex;
align-items: center;
border: 1px solid ${props => props.borderColor || 'lightgray'};
color: ${props => props.textColor || 'lightgray'};
border-radius: 22px;
justify-content: center;
background-color: ${props => props.backgroundColor || 'white'};
svg {
    margin-left: 5px;
    max-width: 45px;
}
`