import React from "react";

const ThreeMacaronIcon = ({style, stroke}) => {
    return <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M30.9402 35.2495C30.333 41.2545 19.0652 40.5798 19.0652 40.5798C7.39261 40.5798 7.19019 35.6544 7.19019 35.2495C7.12272 34.7772 7.59502 34.3049 7.59502 34.3049C8.74204 35.5869 12.2506 36.464 12.2506 36.464C12.2506 36.464 15.8266 37.3412 18.9977 37.2062C22.1689 37.0038 27.9715 36.5315 30.4679 34.3724C31.0077 34.6423 30.9402 35.2495 30.9402 35.2495Z" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M30.9407 33.2254C30.9407 33.7651 30.4684 34.2374 30.4684 34.2374C27.9719 36.464 22.1694 36.9363 18.9982 37.1387C15.827 37.3411 12.251 36.3965 12.251 36.3965C12.251 36.3965 9.95699 35.8568 8.47261 34.9796C8.40514 34.9122 8.2702 34.8447 8.20272 34.7772C8.20272 34.7772 8.20272 34.7772 8.13525 34.7772C8.13525 34.7772 8.13525 34.7772 8.06778 34.7772L8.00031 34.7097C8.00031 34.7097 8.00031 34.7097 7.93284 34.7097C7.93284 34.5748 7.93284 34.5748 7.86537 34.5073C7.86537 34.5073 7.86537 34.5073 7.79789 34.5073C7.73042 34.4399 7.73042 34.4399 7.66295 34.3724C6.65088 33.2928 7.73042 32.4832 8.00031 32.2808C8.06778 32.2808 8.06778 32.3482 8.13525 32.3482C8.13525 32.3482 9.82204 34.3049 19.1331 34.5073C19.1331 34.5073 25.8803 34.6423 29.9961 32.4157" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M11.1035 25.8711H11.171" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M26.2852 25.8711C26.7575 26.0735 27.1623 26.2759 27.5671 26.4783" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M7.86524 28.7048C7.52788 29.3121 7.25799 30.0543 7.12305 30.9314C7.12305 30.9314 7.12305 31.7411 7.93271 32.2134C8.00018 32.2134 8.00018 32.2808 8.06765 32.2808C8.06765 32.2808 9.75444 34.2375 19.0655 34.4399C19.0655 34.4399 25.8127 34.5749 29.9285 32.3483" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M48.5502 37.476C45.1091 42.4015 35.5281 36.329 35.5281 36.329C25.3399 30.7289 27.5665 26.3432 27.7689 25.8709C27.9713 25.4661 28.5786 25.2637 28.5786 25.2637C28.9834 26.9505 31.6148 29.3794 31.6148 29.3794C31.6148 29.3794 34.3136 31.9434 37.2149 33.2928C40.0487 34.7097 45.379 37.0712 48.6176 36.329C48.8875 37.0037 48.5502 37.476 48.5502 37.476Z" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M48.6855 36.3963C45.4469 37.1385 40.1166 34.7095 37.2828 33.3601C34.449 31.9432 31.6827 29.4468 31.6827 29.4468C31.6827 29.4468 28.9838 26.9503 28.6465 25.331C28.3091 23.8466 29.6585 23.7117 29.9959 23.7117C29.9959 23.7791 30.0634 23.7791 30.0634 23.8466C30.0634 23.8466 30.6031 26.3431 38.6323 31.0661C38.6323 31.0661 44.6372 34.5746 49.3603 34.5071C50.2374 35.9915 48.6855 36.3963 48.6855 36.3963Z" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M29.9955 23.7794C29.9955 23.7794 30.5353 26.2759 38.5644 30.9989C38.5644 30.9989 44.5694 34.5074 49.2924 34.44C49.2924 34.44 50.3719 34.3725 50.6418 33.6978C50.9117 33.023 52.9358 28.0976 43.2874 22.4975C43.2874 22.4975 34.1113 16.425 29.8605 22.1601C29.8605 22.0926 29.3882 22.9023 29.9955 23.7794Z" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M26.8241 24.1841C26.2168 30.1891 14.9491 29.5144 14.9491 29.5144C3.20901 29.5144 3.0066 24.6564 3.0066 24.1841C2.93913 23.7118 3.41143 23.2395 3.41143 23.2395C4.55845 24.5215 8.06697 25.3986 8.06697 25.3986C8.06697 25.3986 11.643 26.2757 14.8141 26.1408C17.9853 25.9384 23.7879 25.4661 26.2843 23.307C26.8241 23.6443 26.8241 24.1841 26.8241 24.1841Z" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M26.3521 23.1721C23.8556 25.3987 18.0531 25.871 14.8819 26.0734C11.7107 26.2758 8.13474 25.3312 8.13474 25.3312C8.13474 25.3312 4.62621 24.4541 3.41172 23.1721C2.39965 22.0926 3.54667 21.2829 3.74908 21.1479C3.81655 21.1479 3.81655 21.2154 3.88402 21.2154C3.88402 21.2154 5.57081 23.1721 14.8819 23.3745C14.8819 23.3745 21.8315 23.5769 25.9473 21.1479C27.5666 22.0926 26.3521 23.1721 26.3521 23.1721Z" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    <path d="M3.88397 21.2154C3.88397 21.2154 5.57076 23.1721 14.8818 23.3745C14.8818 23.3745 21.8314 23.5094 25.9472 21.1479C25.9472 21.1479 26.8918 20.5407 26.7569 19.866C26.6894 19.1913 26.0821 13.7935 14.8818 13.6586C14.8818 13.6586 3.88397 12.7814 3.00684 19.866C3.00684 19.866 3.00684 20.7431 3.88397 21.2154Z" stroke={stroke || "black"} strokeWidth="1.18075" strokeMiterlimit="10"/>
    </svg>
    
    
};

export default ThreeMacaronIcon;
