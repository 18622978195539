import React, { useContext } from "react";
import { MacaronSectionContainer, MacaronSectionText, AmountSectionContainer, AmountSelection } from "./styles";
import { ContentContext } from "../../context";
import { Phrase } from "../Phrase";
import OneMacaronIcon from "./icons/OneMacaronIcon";
import TwoMacaronIcon from "./icons/TwoMacaronIcons";
import ThreeMacaronIcon from "./icons/ThreeMacaronIcon";

const MacaronSection = (props) => {
  const { content = {} } = useContext(ContentContext);
  const { howMany = {} } = content.home.texts.instructions || {};

  const { home, general = {} } = content;
  const btn = (home && home.buttons && home.buttons.snap) || (home && home.buttons && home.buttons.macaron) || {};
  const textColor = (general.colors && general.colors.generalTextColor) || {}
  const backgroundColor = (general.colors && general.colors.backgroundColor) || {}

  let { onSelectAmount } = props;

  const amountOptions = [
    {

      amount: 1, Icon: OneMacaronIcon
    },
    {
      amount: 2, Icon: TwoMacaronIcon
    },
    {
      amount: 3, Icon: ThreeMacaronIcon
    }
  ]

  return (
    <MacaronSectionContainer textColor={textColor}>
      <MacaronSectionText data={howMany} style={{marginBottom: '10px'}}>
      <Phrase Key={howMany.translationKey} default={howMany.text || ''} />
      </MacaronSectionText>
      <AmountSectionContainer>
        {amountOptions.map(({amount, Icon}) => 
        <AmountSelection key={amount} onClick={() => onSelectAmount(amount)} borderColor={btn.backgroundColor} textColor={btn.backgroundColor} backgroundColor={backgroundColor}>
          <MacaronSectionText>{amount }</MacaronSectionText>
          <Icon stroke={btn.backgroundColor}/>
        </AmountSelection>)}
      </AmountSectionContainer>
    </MacaronSectionContainer>
  );
}

export default MacaronSection;