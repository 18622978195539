import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Button, ButtonsRow, MacaronAmountDuplicate, MacaronImage, MacaronSurpriseContainer, ModalBody, Title } from './styles';
import CloseIcon from '../icons/CloseIcon';
import { Phrase } from '../../Phrase';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';

const DuplicateModal = ({isOpen, onConfirm, onClose, rippleSrc, macaronAmount, content = {}, isSurprise}) => {
  const { general = {}, macaron = {} } = content;
    const { colors = {} } = general;
    const macaronTexts = macaron.texts || {}
    
    const duplicateThisDesignText =  macaronTexts.duplicateThisDesign || {};
    const yesText = macaronTexts.yes || {};
    const noText =  macaronTexts.no || {};

    const backgroundColor = colors.backgroundColor;
    const textColor = colors.generalTextColor;
    const buttonBackground = content.home.buttons.snap ? content.home.buttons.snap.backgroundColor : content.home.buttons.library.backgroundColor;
    const buttonTextColor = content.home.buttons.snap ? content.home.buttons.snap.textColor : content.home.buttons.library.textColor;

  return (
      <Modal
        open={isOpen}
        onClose={onClose}
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        BackdropProps={{style: {backgroundColor: '#000000cc'}}}
      >
        <ModalBody backgroundColor={backgroundColor} shadowColor={textColor}>
            <CloseIcon fill={textColor} style={{alignSelf: 'flex-end' }} onClick={onClose}/>
            <Title textColor={textColor}>
                <Phrase Key={duplicateThisDesignText ? duplicateThisDesignText.translationKey : '2a207013-174c-4fdc-9d6c-96c253c52b0c'} default={duplicateThisDesignText.text || ''}/>
            </Title>
            <div style={{position: 'relative', margin: '30px 0'}}>
            {isSurprise ? <MacaronSurpriseContainer borderColor={textColor}>
               <QuestionMarkIcon fill={textColor}/>
            </MacaronSurpriseContainer> :
            <MacaronImage borderColor={textColor} src={rippleSrc} alt=''/>}
            <MacaronAmountDuplicate textColor={textColor} backgroundColor={backgroundColor} borderColor={textColor}>
                {`x${macaronAmount}`}
            </MacaronAmountDuplicate>
            </div>
            <ButtonsRow style={{marginTop: '20px'}}>
                <Button onClick={onConfirm} backgroundColor={buttonBackground} borderColor={buttonBackground} textColor={buttonTextColor}>
                    <Phrase Key={yesText.translationKey} default={yesText.text ||  ''}/>
                </Button>
                <Button onClick={onClose} backgroundColor={backgroundColor} textColor={buttonBackground} borderColor={buttonBackground}>
                <Phrase Key={noText.translationKey} default={noText.text || ''}/>
                </Button>
            </ButtonsRow>
        </ModalBody>
      </Modal>
  );
}

export default DuplicateModal;