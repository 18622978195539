import React from "react";
import { BackButtonContainer } from "../styles/IntroStyle";
import BackIcon from "./icons/BackIcon";
import { Phrase } from "../Phrase";

const BackButton  = ({onBackClick, textColor, backText}) => {
    return <BackButtonContainer onClick={onBackClick} textColor={textColor}>
        <BackIcon fill={textColor}/>
        <Phrase Key={backText.translationKey} default={backText.text || ''}/>
    </BackButtonContainer>
};

export default BackButton;