import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./styles/index.css";
import "./styles/Snapshot.scss";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";

const tagManagerArgs = {
  gtmId: "GTM-TWBVCP2Q",
};

let MEASUREMENT_ID = "G-DK830HX21T";
switch (window.location.host) {
  case "webapp.dev.coffeeripples.com":
  case "localhost:3018":
    MEASUREMENT_ID = "GT-WBLCGCHR"; 
    break;
  case "webapp.stg.coffeeripples.com":
    MEASUREMENT_ID = "G-7YE5SS252E";
    break;
  case "webapp.drinkripples.com":
    MEASUREMENT_ID = "G-DK830HX21T";
    break;
  default:
    MEASUREMENT_ID = "G-DK830HX21T";
}

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(MEASUREMENT_ID);

ReactDOM.render(<App />, document.getElementById("root"));
