import React, { useContext } from "react";

import { HomeFlowButton } from "../styles/IntroStyle";
import { Phrase } from "../Phrase";
import { handleHistoryPush, handleHistoryReplace } from "../../utils/history";
import { GaEvents, sendGAEvent } from "../../utils/googleAnalytics";
import { ContentContext } from "../../context";
import SurpriseIcon from "./icons/SurpriseIcon";

const SurpriseMeFlow = (props) => {
  let { data, disable, isPreview, onSurpriseClick, isOneMacaron, isMacaronFlow } = props || null;
  let { home, general } = data || {};
  let numberOfButtons = props.extra && props.extra.numberOfButtons;
  let gridColumn = numberOfButtons % 2 > 0 ? 3 : 0;
  const btn = (home && home.buttons && home.buttons.snap) || (home && home.buttons && home.buttons.library) || {};
  const btnText = (home && home.buttons && home.buttons.surprise) || {};

  const iconsColor = (general && general.colors && general.colors.iconsColor) || null
  const { content } = useContext(ContentContext);

  const gotoSurpriseMe = () => {
    if (!disable) {
      sendGAEvent(GaEvents.FlowSelection, {
        eventCategory: "flow",
        eventLabel: `${isMacaronFlow ? 'Macaron' : 'Drink'}_surprise_me`,
        value: `${isMacaronFlow ? 'Macaron' : 'Drink'}_surprise_me`,
        locationId: content.locationId,
      });

      if (isPreview) {
        handleHistoryReplace(props.history, "/surprise", {isOneMacaron});
      } else {
        handleHistoryPush(props.history, "/surprise", {isOneMacaron});
      }
    }
  };

  return (
    <HomeFlowButton
      onClick={onSurpriseClick || gotoSurpriseMe}
      data={{
        ...btn,
        gridColumn,
        opacity: disable ? 0.5 : 1,
      }}
      extra={btnText}
    >
      <SurpriseIcon stroke={iconsColor}/>
      <Phrase Key={btnText.translationKey} default={btnText.text || ""} />
    </HomeFlowButton>
  );
};

export { SurpriseMeFlow };
