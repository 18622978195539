import React from "react";

const OneMacaronIcon = ({style, stroke}) => {
    return <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M39.9996 29.7219C39.3114 36.5273 26.5418 35.7626 26.5418 35.7626C13.2369 35.7626 13.0075 30.2571 13.0075 29.7219C12.931 29.1866 13.4663 28.6514 13.4663 28.6514C14.7662 30.1042 18.7423 31.0982 18.7423 31.0982C18.7423 31.0982 22.795 32.0923 26.3888 31.9394C29.9827 31.71 36.5587 31.1747 39.3879 28.7278C39.9996 29.1102 39.9996 29.7219 39.9996 29.7219Z" stroke={stroke || "black"} strokeWidth="1.33814" strokeMiterlimit="10"/>
    <path d="M39.4647 28.575C36.6355 31.0983 30.0595 31.6336 26.4656 31.8629C22.8718 32.0923 18.8191 31.0218 18.8191 31.0218C18.8191 31.0218 14.843 30.0278 13.4666 28.575C12.3196 27.3515 13.6195 26.4339 13.8489 26.281C13.9254 26.281 13.9254 26.3575 14.0019 26.3575C14.0019 26.3575 15.9135 28.575 26.4656 28.8043C26.4656 28.8043 34.3415 29.0337 39.0059 26.281C40.841 27.3515 39.4647 28.575 39.4647 28.575Z" stroke={stroke || "black"} strokeWidth="1.33814" strokeMiterlimit="10"/>
    <path d="M14.0019 26.3574C14.0019 26.3574 15.9135 28.5749 26.4656 28.8043C26.4656 28.8043 34.3415 28.9572 39.0059 26.2809C39.0059 26.2809 40.0764 25.5927 39.9235 24.8281C39.847 24.0634 39.1588 17.9462 26.4656 17.7933C26.4656 17.7933 14.0019 16.7993 13.0078 24.8281C13.0078 24.8281 13.0078 25.8221 14.0019 26.3574Z" stroke={stroke || "black"} strokeWidth="1.33814" strokeMiterlimit="10"/>
    </svg>
    
    
};

export default OneMacaronIcon;
