import React from "react";

const CloseIcon = (props) => {
    const {fill, style, onClick} = props;

    return <svg width="18" height="18" viewBox="0 0 18 18" fill={fill || "none"} xmlns="http://www.w3.org/2000/svg" style={style} onClick={onClick}>
    <path d="M15.3024 16.759L0.631653 1.99984C0.176819 1.54227 0.176819 0.800733 0.631653 0.343171C1.08649 -0.11439 1.82361 -0.11439 2.27844 0.343171L16.9496 15.1023C17.4044 15.5599 17.4044 16.3014 16.9496 16.759C16.4947 17.2165 15.7572 17.2165 15.3024 16.759Z" fill={fill || "black"}/>
    <path d="M0.631653 15.1019L15.3024 0.343171C15.7573 -0.11439 16.4944 -0.11439 16.9492 0.343171C17.4041 0.800733 17.4041 1.54227 16.9492 1.99984L2.27809 16.7586C1.82361 17.2162 1.08613 17.2162 0.631653 16.7586C0.176819 16.3014 0.176819 15.5595 0.631653 15.1019Z" fill={fill || "black"}/>
    </svg>
};

export default CloseIcon;
