import axios from "axios";
import { handleHistoryPush } from "../utils/history";
import { copyImageForSketch } from "../components/edit/ImageMode";
import loadImage from "blueimp-load-image";
const MarvinImage = window.MarvinImage;

export function fileSelectedHandler(event, props) {
  fileSelected(event).then((data) => {
    handleHistoryPush(props.history, "/edit", {
      iFrame: !!props.iFrame,
      isUploadFlow: true,
      target: data,
      macaronId: props.macaronId,
      macarons: props.macarons,
      macaronAmount: props.macaronAmount,
      isOneMacaron: props.isOneMacaron
    });
  });
}

export function fileSelected(event) {
  let image = new MarvinImage();
  return new Promise((resolve) => {
    const file = event.target.files[0];
    loadImage(
      file,
      (canvas, meta) => {
        if (!canvas.toDataURL) {
          resolve({ orgImage: "" });
          return;
        }
        let dataUrl = canvas.toDataURL();
        image.load(dataUrl, () => {
          copyImageForSketch(image).then(()=>{
            resolve({ orgImage: dataUrl });
          });
        });
      },
      { orientation: true, canvas: true, maxWidth: 1700, maxHeight:1700 }
    );
  });
}

export function downloadFile(url, mimeType) {
  if (url.match("^http://")) {
    url = url.replace("http://", "https://");
  }
  return axios
    .get(url, {
      responseType: "blob",
      headers: {
        Accept: mimeType,
      },
    })
    .then(({ data }) => {
      return data;
    });
}
