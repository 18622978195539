import styled from "styled-components";

import "../../styles/Snapshot.scss";

// todo(vmyshko): refac/minify styles

// PRINTED
export const LTextPrinted = styled.div`
  padding-left: ${(props) => props.padding || ""};
  padding-right: ${(props) => props.padding || ""};
  font-style: normal;
  font-weight: normal;
  white-space: pre-line;
  font-size: 30px;
  text-align: center;
  color: ${(props) => props.color};
`;

export const MTextPrinted = styled(LTextPrinted)`
  font-size: 26px;
`;

export const STextPrinted = styled(LTextPrinted)`
  font-size: 18px;
`;

// REGULAR
export const LText = styled.div`
  padding-left: ${(props) => props.padding || ""};
  padding-right: ${(props) => props.padding || ""};
  font-family: "Brandon Grotesque regular";
  white-space: pre-line;
  font-style: normal;
  font-weight: 390;
  font-size: 30px;
  text-align: ${(props) => props.align || "center"};
  color: ${(props) => props.color};
`;

export const MText = styled(LText)`
  font-size: 26px;
`;

export const SText = styled(LText)`
  font-size: 20px;
`;
export const XSText = styled(LText)`
  font-size: 14px;
`;

// BOLD
export const LTextBold = styled.div`
  font-family: "Brandon Grotesque bold";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: ${(props) => props.color};
`;

export const MTextBold = styled(LTextBold)`
  font-size: 26px;
`;

export const STextBold = styled(LTextBold)`
  font-size: 20px;
`;

// MEDIUM
export const LTextMedium = styled.div`
  font-family: "Brandon Grotesque medium";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  color: ${(props) => props.color};
`;

export const MTextMedium = styled(LTextMedium)`
  font-size: 26px;
`;

export const STextMedium = styled(LTextMedium)`
  font-size: 20px;
`;

// wtf? roboto-grotesque?
export const STextRoboto = styled.div`
  font-family: "Brandon Grotesque regular";
  font-style: normal;
  font-weight: ${({ FontWeight }) => FontWeight || "500"};
  font-size: ${({ FontSize }) => FontSize || "20px"};
  text-align: center;
  color: ${({ color }) => color};
  width: max-content;
`;

export const TextWrapper = styled.div`
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DoubleTapToEdit = styled.div`
  display: flex;
  position: absolute;
  z-index: 1000;
  align-self: center;
  user-select: none;
  justify-content: center;
  align-items: center;
`;

function getFontSize({ twoLines }) {
  return twoLines ? 0.4 : 0.5;
}

export const DoubleTapToEditText = styled.div`
  text-align: center;
  color: ${({ fontColor }) => fontColor || "black"};

  font-size: ${getFontSize}em;
  line-height: 30px;
`;
