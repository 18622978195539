import React, { useContext, useEffect } from "react";
import { ItemContainer, LocationAvatar, LocationMiddleContainer, LocationNameText, LocationSubText, LocationKmContainer, LocationInfoContainer } from "../styles/locationsStyle";
import { Phrase } from "../Phrase";
import { GaEvents, sendGAEvent } from "../../utils/googleAnalytics";
import { ContentContext } from "../../context";

export function LocationItem(props) {
  const { name, address, distance, shortId, urlLogo, id, open } = props.item;
  const { content } = useContext(ContentContext);

  function showDistance(meters) {
    return (meters / 1000).toFixed(2) + " ";
  }

  function openLocation() {
    sendGAEvent(GaEvents.SelectLocation, {
      eventCategory: "locations",
      eventLabel: id,
      locationId: content.locationId,
    });
    if (shortId) {
      props.goToHomeScreen({ code: shortId });
    } else {
      let locData = {
        locationId: id,
        organizationId: "1",
        templateId: "1",
      };
      let token = window.btoa(JSON.stringify(locData));
      props.goToHomeScreen({ token: token });
    }
  }

  useEffect(() => {
    if (!!open)
      openLocation();
  }, []);

  return (
    <ItemContainer onClick={openLocation}>
      <LocationAvatar image={urlLogo || "https://res.cloudinary.com/ripples/image/upload/v1599383081/webapp/splash/default_location_avatar.jpg"} />
      <LocationInfoContainer>
        <LocationMiddleContainer>
          <LocationNameText>{name}</LocationNameText>
          <LocationSubText>{address}</LocationSubText>
        </LocationMiddleContainer>

        {!!distance && (
          <LocationKmContainer>
            <LocationSubText>
              {showDistance(distance)}
              <Phrase Key={"d6d16715-5382-4464-8d4d-ba767168bfcf"} default={"km"} />
            </LocationSubText>
          </LocationKmContainer>
        )}
      </LocationInfoContainer>
    </ItemContainer>
  );
}
