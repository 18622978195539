export const hexToRgb = (hex) => {
  let result;
  if (hex.length > 4) {
    result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  } else {
    let hexArray = hex.split("");
    hexArray.shift();
    let combinArray = hexArray.join("");
    let newHex = "#" + combinArray + combinArray;
    result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  }
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const hexToGrayscaleLevel = (input) => {
  if (!input) return null;
  if (input.indexOf("linear") !== -1) {
    return getValueFromGrad(input);
  }
  return hexToRgb(input).reduce((a, e) => a + e) / 3;
};

export const checkIfSameColor = (c1, c2, c3) => {
  let isMatch = hexToGrayscaleLevel(c1) === hexToGrayscaleLevel(c2);
  return c3 ? isMatch && checkIfSameColor(c1, c3) : isMatch;
};

export const getValueFromGrad = (input) => {
  // eslint-disable-next-line no-useless-escape
  let rgb = /\s?\d{1,3}\,\s?\d{1,3}\,\s?\d{1,3}(\,\s?(\d|\d\.\d+))?\s?/.exec(
    input
  );
  let rgbArray = rgb[0].split(",");
  let result = [
    parseInt(rgbArray[0], 16),
    parseInt(rgbArray[1], 16),
    parseInt(rgbArray[2], 16),
  ];
  return result.reduce((a, e) => a + e) / 3;
};
