import { useRef, useCallback } from "react";
import Konva from "konva";

/**
 * @param {number} width
 * @param {number} height
 * @param {{ duration: number, addToScale: number }} options
 */
export default function useKonvaAnimation(width, height, { duration = 0.2, addToScale = 0.5 } = {}) {
  const animationProps = useRef();

  /**
   * @param {Konva.Group} toAnimate
   */
  const animate = useCallback(
    (toAnimate) => {
      if (!toAnimate) {
        return;
      }

      if (animationProps.current) {
        // reset scale and position before animating again
        const { animation, originalScaleAndPosition } = animationProps.current;
        animation.destroy();
        const { scaleX, scaleY, x, y } = originalScaleAndPosition;
        toAnimate.scale({
          x: scaleX,
          y: scaleY,
        });
        toAnimate.x(x);
        toAnimate.y(y);
      }

      const { x, y } = toAnimate.getAbsolutePosition();
      const textScale = toAnimate.getAbsoluteScale();
      const scaleToX = textScale.x + addToScale;
      const scaleToY = textScale.y + addToScale;
      const newX = x - (width * scaleToX - width) / 2;
      const newY = y - (height * scaleToY - height);

      toAnimate.scale({
        x: scaleToX,
        y: scaleToY,
      });
      toAnimate.x(newX);
      toAnimate.y(newY);

      const originalScaleAndPosition = {
        scaleX: textScale.x,
        scaleY: textScale.y,
        x,
        y,
      };

      const animation = new Konva.Tween({
        node: toAnimate,
        duration,
        easing: Konva.Easings.EaseIn,
        ...originalScaleAndPosition,
      });

      animationProps.current = { animation, originalScaleAndPosition };
      animation.onFinish = () => (animationProps.current = null);
      animation.play();
    },
    [width, height, duration, addToScale]
  );

  return animate;
}
