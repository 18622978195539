import React, { useContext, useState } from "react";
import { MainWrapper } from "../styles/IntroStyle";
import SelectRipple from "./SelectRipple";
import { ContentContext } from "../../context";
import { handleHistoryReplace } from "../../utils/history";

export default function Library(props) {
  const { content } = useContext(ContentContext);
  const [libraryLoaded, setLibraryLoaded] = useState(false);

  if (!content) {
    handleHistoryReplace(props.history, '/home')
    return null;
  }

  const backgroundColor = content.general.colors.backgroundColor;

  return (
    <MainWrapper backgroundColor={backgroundColor}>
      <SelectRipple history={props.history} data={{ libraryLoaded }} setLibraryLoaded={setLibraryLoaded} />
    </MainWrapper>
  );
}
