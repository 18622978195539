import Konva from "konva";
import { allFonts } from "../Fonts";

const textMeasuringNode = new Konva.Text();

function calculateFontSizeForWidth(text, font, desiredWidth) {
  const testFontsize = 18;
  textMeasuringNode.fontFamily(font);
  textMeasuringNode.fontSize(testFontsize);
  const { width } = textMeasuringNode.measureSize(text);
  if (width === 0) return testFontsize;
  return Math.round((testFontsize * desiredWidth) / width);
}

function getFontOption(font) {
  return allFonts.find((fontOption) => fontOption.fontFamily === font);
}

export function getFontPadding(font) {
  const fontOption = getFontOption(font);

  if (!fontOption || !fontOption.metrics) {
    return 0;
  }

  return fontOption.metrics.padding || 0;
}

export function getFontLowerCase(font) {
  const fontOption = getFontOption(font);

  if (!fontOption || !fontOption.metrics) {
    return false;
  }

  return fontOption.metrics.LowerCase || false;
}

export function calculateFontHeight(font, fontSize) {
  const fontOption = getFontOption(font);

  if (!fontOption || !fontOption.metrics || !fontOption.metrics.capitalLetterHeight) {
    return fontSize;
  }

  return (fontOption.metrics.capitalLetterHeight / 1000) * fontSize;
}

export function getFontMarginTop(font) {
  const fontOption = getFontOption(font);

  if (!fontOption || !fontOption.metrics || !fontOption.metrics.marginTop) {
    return 0;
  }

  return fontOption.metrics.marginTop;
}

export function getFontSizes(wordsArr, fontFamily, desiredWidth, desiredHeight) {
  let fontSizes;
  let totalHeight = desiredHeight + 1;
  const mapWordsArrToFontSizes = (word) => calculateFontSizeForWidth(word, fontFamily, desiredWidth);
  const mapWordsArrToLineHeights = (fontSize) => calculateFontHeight(fontFamily, fontSize);

  while (totalHeight > desiredHeight) {
    fontSizes = wordsArr.map(mapWordsArrToFontSizes);
    totalHeight = fontSizes.map(mapWordsArrToLineHeights).reduce((acc, value) => acc + value, 0);
    desiredWidth -= 1;
  }

  return fontSizes;
}
