export let privacyObject = {
  name: "Privacy Shoutout",
  type: "texualWithLink",
  general: {
    hasCloseIcon: true,
  },
  title: {
    text: {
      en: "We value your Privacy",
    },
    locize: {
      default: "We value your Privacy",
      Key: "ce72ac96-bda5-43d0-8fff-984c778a9382",
    },
  },
  description: {
    text: {
      en: "Our privacy and cookie policy can be found",
    },
    locize: {
      default: "Our privacy and cookie policy can be found",
      Key: "6766fcf6-28ab-4623-9b94-7578f8a0c5a4",
    },
    textSize: "M",
    link: {
      text: "here",
      locize: {
        default: "here",
        Key: "9181eca2-49a6-427f-8f4a-1fccf25e6b33",
      },
      href: "https://www.drinkripples.com/privacy-policy",
    },
  },
  buttons: {
    list: [
      {
        text: {
          en: "OK",
        },
        locize: {
          default: "OK",
          Key: "461d520c-7fd4-424b-8648-b51ce2897f67",
        },
        action: "privacy",
        textSize: "S",
        buttonType: "filled",
      },
    ],
  },
  options: "",
  image: "",
};
