import React from "react";

const BackIcon = ({fill}) => {
    return <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.52009 2.9H10.3802C12.54 2.9 14.2905 4.60108 14.2905 6.7C14.2905 8.79896 12.5394 10.5 10.378 10.5H0.702325C0.474896 10.5 0.290527 10.3209 0.290527 10.1C0.290527 9.87909 0.474896 9.7 0.702325 9.7H10.378C12.0845 9.7 13.4669 8.35714 13.4669 6.7C13.4669 5.04284 12.0851 3.7 10.3802 3.7H2.52009L4.2879 5.41716C4.44871 5.57337 4.44871 5.82662 4.2879 5.98283C4.12708 6.13904 3.86634 6.13904 3.70552 5.98283L1.23474 3.58283C1.07392 3.42662 1.07392 3.17337 1.23474 3.01716L3.70552 0.617157C3.86634 0.460948 4.12708 0.460948 4.2879 0.617157C4.44871 0.773367 4.44871 1.02662 4.2879 1.18283L2.52009 2.9Z" fill={fill || "black"}/>
    </svg>
    
};

export default BackIcon;
