import ReactGA from "react-ga4";

export const sendGAEvent = (eventName, { eventCategory, eventLabel, locationId }) => {
  ReactGA.event(eventName, { locationId, category: eventCategory, label: eventLabel });
};

export const GaEvents = {
  SelectFrame: "SelectFrame",
  SelectLocation: "SelectLocation",
  SelectFilter: "SelectFilter",
  SelectTextStyle: "SelectTextStyle",
  OpenLocation: "OpenLocation",
  OpenLocationName: "OpenLocationName",
  FlowSelection: "FlowSelection",
  CanvasSelection: "CanvasSelection",
  SearchLocation: "SearchLocation",
  MacaronAmount: "MacaronAmount",
  ClickOnSocial: "ClickOnSocial",
  DesignSelection: "DesignSelection",
  MacaronCreateMyOwn: "MacaronCreateMyOwn",
  DrinkCreateMyOwn: "DrinkCreateMyOwn",
};
