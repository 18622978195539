import { useCallback, useLayoutEffect, useRef } from 'react';
import _ from 'lodash';

const useSaveLeftScrollPosition = (scroll, setScroll) => {
    const containerRef = useRef();

    const handleScroll = (target) => {
        setScroll(target.scrollLeft);
    };

    const debouncedScrollHandler = _.debounce(handleScroll, 300);

    const debouncedHandleScroll = (e) => {
        const { target } = e;

        // extracting the event target is necessary as react reuses synthetic events, and after the debounce,
        // the synthetic event is already nullified - https://reactjs.org/docs/events.html#event-pooling
        debouncedScrollHandler(target);
    };

    const onScroll = useCallback(debouncedHandleScroll, [setScroll]);

    useLayoutEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollLeft = scroll;
        }
    }, [containerRef.current]);

    return { containerRef, onScroll };
}

export default useSaveLeftScrollPosition;