import React from "react";

const GalleryIcon = ({fill}) => {
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.007 6.85718C19.549 6.85718 23.0962 6.85718 26.6382 6.85718C27.7942 6.85718 28.6811 7.57377 28.908 8.67785C28.945 8.85832 28.9503 9.04941 28.9503 9.22989C28.9503 14.0602 28.9555 18.8853 28.9503 23.7156C28.9503 25.1435 28.0054 26.0937 26.5854 26.0937C19.5331 26.0937 12.4755 26.0937 5.42325 26.0937C4.00329 26.0937 3.05841 25.1435 3.05841 23.7156C3.05841 18.8959 3.06897 14.0762 3.04785 9.25643C3.04785 7.97718 3.89772 7.08012 4.87427 6.89964C5.08014 6.86249 5.29656 6.85718 5.50243 6.85718C9.00747 6.85718 12.5072 6.85718 16.007 6.85718ZM16.0017 24.9737C19.5226 24.9737 23.0382 24.9737 26.559 24.9737C27.3825 24.9737 27.8312 24.5225 27.8312 23.6997C27.8312 18.8853 27.8312 14.0762 27.8312 9.26174C27.8312 8.43368 27.3825 7.9878 26.559 7.9878C19.5384 7.9878 12.5178 7.9878 5.49715 7.9878C4.61562 7.9878 4.18276 8.41775 4.18276 9.3042C4.18276 14.0974 4.18276 18.8959 4.18276 23.6891C4.18276 24.5331 4.62617 24.979 5.47076 24.979C8.98107 24.9737 12.4914 24.9737 16.0017 24.9737Z" fill={fill || "white"}/>
    <path d="M19.413 20.239C19.6769 19.9577 19.925 19.687 20.1784 19.4269C20.7115 18.8748 21.2552 18.3334 21.7884 17.7814C22.0048 17.5584 22.3796 17.5531 22.596 17.7708C23.8787 19.0712 25.1614 20.3664 26.4441 21.6669C26.5022 21.7253 26.5603 21.7784 26.6025 21.8421C26.7556 22.065 26.7239 22.3569 26.5286 22.5427C26.3491 22.7179 26.0588 22.7497 25.8476 22.6064C25.7685 22.5533 25.6998 22.479 25.6312 22.41C24.491 21.2635 23.3561 20.1222 22.2318 18.9863C21.5561 19.6764 20.8857 20.3558 20.2048 21.0512C20.7749 21.6244 21.3819 22.2402 21.9942 22.8506C22.1684 23.0258 22.2529 23.2434 22.1473 23.4504C22.0681 23.6043 21.8939 23.7689 21.7356 23.8007C21.5772 23.8326 21.3555 23.7424 21.2235 23.6256C20.891 23.3389 20.5954 23.0098 20.2839 22.6967C17.993 20.393 15.7073 18.0945 13.4164 15.7908C13.3425 15.7165 13.2897 15.621 13.1736 15.467C13.0733 15.6104 13.031 15.7006 12.9624 15.7696C10.7665 17.9778 8.57058 20.1859 6.37465 22.3941C6.30075 22.4684 6.22685 22.548 6.14239 22.6064C5.92069 22.7551 5.62508 22.7126 5.44561 22.5215C5.26613 22.3304 5.24502 22.0544 5.39282 21.8368C5.45089 21.7518 5.53007 21.6775 5.60397 21.6032C7.91074 19.2836 10.2281 16.9745 12.519 14.6337C13.0469 14.0976 13.3003 14.0869 13.844 14.639C15.5965 16.4437 17.3807 18.2113 19.1543 19.9948C19.2388 20.0745 19.3074 20.1382 19.413 20.239Z" fill={fill || "white"}/>
    <path d="M21.0765 11.3692C21.0818 12.6166 20.0841 13.6304 18.8436 13.6463C17.5979 13.6622 16.5791 12.6378 16.5791 11.3745C16.5791 10.1218 17.582 9.11322 18.8225 9.10791C20.0577 9.11322 21.0712 10.1271 21.0765 11.3692ZM18.8225 12.5104C19.4454 12.5104 19.9522 12.0061 19.9522 11.3851C19.9522 10.764 19.4454 10.2598 18.8225 10.2598C18.1997 10.2598 17.6982 10.7587 17.6982 11.3851C17.6982 12.0061 18.1997 12.5104 18.8225 12.5104Z" fill={fill || "white"}/>
    </svg>
    
    
};

export default GalleryIcon;
