import React, { useRef, useEffect, useState } from "react";
import { fileSelected } from "../../../actions";
import { EditText, MovePhoto, AddImgWrapper, EditSelection, FilterSelectionTabs, ImageWrapper, FilterText, AddImgBorderContainer } from "../../styles/editStyle";
import useSaveLeftScrollPosition from "../../../utils/useSaveScrollLeftPosition";
import { STextBold, XSText } from "../../styles/textStyle";
import { Phrase } from "../../Phrase";
import { RangeSilder } from "./RangeSlider";
import DetectRTC from "detectrtc";
import CameraIcon from "../../home/icons/CameraIcon";
import AddIcon from "../../home/icons/AddIcon";

export function ImageModeSettings({ data }) {
  let {
    image,
    images,
    general,
    uploadFlow,
    editImageStyle,
    movePhotoStyle,
    filter,
    printStatus,
    imageScroll,
    setImageScroll,
    imagePosition,
    imageRef,
    setNewImage,
    dImageWidth,
    offset,
    filterSize,
    changeFilter,
    fromLibrary,
    imageChange,
    textInstructions,
    onContrastChange,
    customContrast,
    newColors,
    filters,
    setContrastTool,
    contrastTool,
    workflows,
    home,
    addImage = {},
    openCameraText = {}
  } = data;

  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  const { containerRef, onScroll } = useSaveLeftScrollPosition(imageScroll, setImageScroll);
  const { chooseFilter } = textInstructions;

  const buttonBackground = home.buttons.snap ? home.buttons.snap.backgroundColor : home.buttons.library.backgroundColor;

  useEffect(() => {
    if (!imageRef.current) return;
    changeScaleOrRotation();
  }, [imagePosition, imageChange]);

  const changeScaleOrRotation = () => {
    let imageScale = imageRef.current.scaleX();
    let newRot = imageRef.current && imageRef.current.rotation();
    setScale(imageScale);
    setRotation(newRot);
  };

  const GalleryInput = useRef();
  const fileInputCamera = useRef();

  const makePhotoPickerSupported = () => {
    if (DetectRTC.osName === 'Android') {
      if (['14'].includes(DetectRTC.osVersion)) {
        GalleryInput.current.accept += ',text/plain';
      }
    }
  }

  const openGallery = () => {
    makePhotoPickerSupported();
    GalleryInput.current.click();
  };

  const openCamera = () => {
    if (DetectRTC.isGetUserMediaSupported) {
      fileInputCamera.current.click();
    }
  }

  const inputHandler = async (e) => {
    e.persist();
    setScale(1);
    let res = await fileSelected(e);
    setNewImage(res.orgImage);
  };

  const onFilterClick = (e) => {
    if (filter === e.name) {
      setContrastTool(true);
      return;
    }
    changeFilter(e.name);
  };

  const onRangeChange = (value) => {
    onContrastChange(filter, value);
  };

  const getAllFilters = () => {
    return filters.map((e) => {
      return (
        <div key={e.name}>
          <EditSelection
            key={e.name}
            hasText={!!e.name}
            disabled={!printStatus}
            isActive={filter === e.name}
            colors={general.colors}
            onClick={
              printStatus
                ? () => {
                    onFilterClick(e);
                  }
                : null
            }
          >
            <ImageWrapper size={filterSize}>
              {imageRef.current && offset.x !== null && offset.y !== null && (
                <img
                  alt=""
                  id={e.name}
                  src={images.find(({id}) => e._id === id).src}
                  width={`${dImageWidth}px`}
                  style={{
                    transform: `translate(${offset.x}px, ${offset.y}px) scale(${scale}) rotate(${rotation}deg)`,
                    // filter: e.regular ? `grayscale(${e.grayscaleCSS}) brightness(${e.brightnessCSS}%) contrast(${e.contrastCSS}%)` : "",
                  }}
                />
              )}
            </ImageWrapper>
          </EditSelection>
          <FilterText colors={general.colors} isActive={filter === e.name}>
            <XSText id={"text"}>{e.name}</XSText>
          </FilterText>
        </div>
      );
    });
  };

  return (
    <>
      {image && (
        <>
          <EditText id="editText" style={editImageStyle}>
            {contrastTool ? (
              <>
                <MovePhoto style={movePhotoStyle}>
                  <Phrase Key={"8f0189e4-3874-4ce9-bf49-bd1a15ab0355"} default={"Contrast"} />
                </MovePhoto>
                <RangeSilder min={0} max={100} newColors={newColors} value={customContrast[filter]} onChange={onRangeChange} />
              </>
            ) : (
              <>
                <MovePhoto style={movePhotoStyle}>
                  <Phrase
                    Key={chooseFilter && chooseFilter.translationKey === "" ? "" : "8f0189e4-3074-4ce9-bf49-bd1a05ab0344"}
                    default={chooseFilter ? chooseFilter.text : ""}
                  />
                </MovePhoto>
                <FilterSelectionTabs size={filters.length} onScroll={onScroll} ref={containerRef}>
                  {getAllFilters()}
                </FilterSelectionTabs>
              </>
            )}
          </EditText>
        </>
      )}

      { (!image && !fromLibrary && !uploadFlow) && (
        <>
          {
            workflows.isGalleryEnabled ?
              <AddImgWrapper disabled={!printStatus} style={editImageStyle} onClick={openGallery}>
                <input style={{ display: "none" }} type="file" accept="image/*" onChange={inputHandler} ref={GalleryInput} disabled={!printStatus} />
                <AddImgBorderContainer borderColor={buttonBackground} textColor={buttonBackground}>
                <AddIcon style={{fill: buttonBackground || 'black'}}/>
                <STextBold>
                  <Phrase Key={addImage.translationKey === "" ? "" : "fd4c8a1c-8397-44d6-a90c-816d55b3d4b6"} default={addImage.text || ""} />
                </STextBold>
                </AddImgBorderContainer>
              </AddImgWrapper>
              :
              <AddImgWrapper disabled={!printStatus} style={editImageStyle} onClick={openCamera}>
                <input value="" style={{ display: "none" }} type="file" accept="image/*" capture="user" onChange={inputHandler} ref={fileInputCamera} />
                <AddImgBorderContainer borderColor={buttonBackground} textColor={buttonBackground}>
                <CameraIcon fill={buttonBackground}/>
                <STextBold>
                  <Phrase Key={openCameraText.translationKey} default={openCameraText.text || "Open Camera"} />
                </STextBold>
                </AddImgBorderContainer>
              </AddImgWrapper>
          }
        </>
      )}
    </>
  );
}
