import React from 'react'
import { UserLoginButton, LoginButtonWrapper } from '../styles/commonStyle';
// import { handleHistoryPush } from '../../utils/history';
// import axios from 'axios'

export const LoginButtonComponent = (props) => {
    const { colors } = props;

    const onLoginClicked = async () => {
        window.location.replace('/portal')
    }

    return(
        <LoginButtonWrapper>
            <UserLoginButton
                colors={colors || {}}
                onClick={onLoginClicked}
            />
        </LoginButtonWrapper>
    )
}