import React from "react";
import { NoRipplesWrapper, NoRipplesImage, ButtonWrap, SearchButton, OoopsLabel, NoRippleMakersLabel } from "../styles/locationsStyle";
import { LoginButtonComponent } from "../common/LoginButton";
import { Phrase } from "../Phrase";

function NoLocationsFound(props) {
  const { errorCode, onSearch } = props;

  return (
    <NoRipplesWrapper>
      <NoRipplesImage />
      <OoopsLabel id="Ooops">
        <Phrase Key={"8472eb76-1633-418c-b80e-610d73e2d171"} default={"Ooops..."} />
      </OoopsLabel>
      {getErrorMsg(errorCode, onSearch)}
      <LoginButtonComponent colors={{ main: "#000000", backgroundColor: "#FFFFFF" }} history={props.history} />
    </NoRipplesWrapper>
  );
}

function getErrorMsg(errorCode, onSearch) {
  switch (errorCode) {
    case 1:
    case 2:
      return (
        <NoRippleMakersLabel id="NoRippleMakersNearby">
          <Phrase Key={"3ee6e240-0b5b-4971-ab0f-ee4eb8e73fcf"} default={"Please turn on the location service \n to find nearby ripple makers"} />
        </NoRippleMakersLabel>
      );
    case 3:
      return (
      <NoRippleMakersLabel id="NoRippleMakersNearby">
        <Phrase Key={"f42222e9-a4a5-4857-8a32-96dbbd71b5ed"} default={"No active locations"} />
      </NoRippleMakersLabel>
      )
    case 4:
      return (
        <NoRippleMakersLabel id="NoRippleMakersNearby">
          <Phrase Key={"f451171d-761c-4671-b4d7-951f3e269e30"} default={"Connection error. Please try again later."} />
        </NoRippleMakersLabel>
      );

    default:
      return (
        <>
          <NoRippleMakersLabel id="NoRippleMakersNearby">
            <Phrase Key={"e00941b6-d156-4fc5-9192-3ab699ca9d5a"} default={"No Ripple Makers nearby"} />
          </NoRippleMakersLabel>
          <ButtonWrap id="ButtonWrap">
            <SearchButton id="SearchButton" onClick={onSearch}>
              <Phrase Key={"c19e1d49-844f-48f8-bc15-2b9824c30bff"} default={"SEARCH AGAIN"} />
            </SearchButton>
          </ButtonWrap>
        </>
      );
  }
}

export { NoLocationsFound };
