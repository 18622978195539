import React from "react";
import styled from "styled-components";

import { Button, OuterButton } from "../styles/buttonsStyle";
import { Loader } from "../styles/loader";
import { ButtonsTypes, TextTypes, TextSizes } from "../../primitives/types/Shoutout";
import { PopupButtonsStyle } from "../styles/popupStyle";
import { ShoutoutTextWrapper } from ".";

const PopupButton = styled(Button)`
  min-width: 200px;
  white-space: nowrap;
`;

export function ButtonsWrapper({ data, actions, colors }) {
  const { buttons, isLoading, selectedBtn } = data;
  const { buttonClick, getDataByLocal } = actions;
  const { buttonTextColor, buttonBackground, loaderColor } = colors;

  return (
    <PopupButtonsStyle>
      {buttons.list.map((btn, i) => {
        // todo(vmyshko): propbably there is no outline buttons for survey, since we can't switch buttonType on portal
        const isFilledButton = btn.buttonType === ButtonsTypes.FILLED;

        const CustomButton = isFilledButton ? PopupButton : OuterButton;

        return (
          <CustomButton
            key={i}
            bgColor={buttonBackground}
            borderColor={buttonTextColor}
            onClick={() => {
              selectedBtn === null && buttonClick(btn, i);
            }}
          >
            {isLoading && selectedBtn === i ? (
              <Loader color={loaderColor} />
            ) : (
              <ShoutoutTextWrapper
                actions={{ getDataByLocal }}
                data={{
                  //
                  textSize: TextSizes.SMALL,
                  textType: isFilledButton ? TextTypes.BOLD : TextTypes.REGULAR,
                  ...colors,
                  ...btn,
                  isButton: true,
                }}
              />
            )}
          </CustomButton>
        );
      })}
    </PopupButtonsStyle>
  );
}
