import React from "react";

const DuplicateIcon = (props) => {
    const {fill, stroke} = props;

    return <svg width="24" height="24" viewBox="0 0 24 24" fill={fill || "none"} xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6655 8.96875H10.9468C9.87673 8.96875 9.00928 9.8362 9.00928 10.9063V19.625C9.00928 20.6951 9.87673 21.5625 10.9468 21.5625H19.6655C20.7356 21.5625 21.603 20.6951 21.603 19.625V10.9063C21.603 9.8362 20.7356 8.96875 19.6655 8.96875Z" stroke={stroke || "#E86161"} strokeWidth="1.1625" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.13428 14.7812H4.16553C3.65167 14.7812 3.15886 14.5771 2.79551 14.2138C2.43216 13.8504 2.22803 13.3576 2.22803 12.8438V4.125C2.22803 3.61114 2.43216 3.11833 2.79551 2.75498C3.15886 2.39163 3.65167 2.1875 4.16553 2.1875H12.8843C13.3981 2.1875 13.8909 2.39163 14.2543 2.75498C14.6176 3.11833 14.8218 3.61114 14.8218 4.125V5.09375" stroke={stroke || "#E86161"} strokeWidth="1.1625" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
};

export default DuplicateIcon;
