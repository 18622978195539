import React from "react";

import { TextSizes, TextTypes } from "../../primitives/types/Shoutout";
import {
  //
  LText,
  MText,
  SText,
  STextBold,
  MTextBold,
  LTextBold,
  MTextPrinted,
  LTextPrinted,
  STextPrinted,
  MTextMedium,
  LTextMedium,
  STextMedium,
} from "../styles/textStyle";
import { Phrase } from "../Phrase";
import { hexToGrayscaleLevel } from "../../utils/colors";

export function ShoutoutTextWrapper(props) {
  const { getDataByLocal } = props.actions;
  const {
    //
    backgroundColor,
    buttonTextColor,
    generalTextColor,
    iconsColor,
    textType,
    textSize,
    text,
    padding,
    link = false,
    locize = null,
    isButton = false,
  } = props.data;

  function getChangeColor() {
    const textForBK = hexToGrayscaleLevel(backgroundColor) < 200 ? "#ffffff" : "#000000";
    const checkColorIfButton = hexToGrayscaleLevel(iconsColor) < 200 ? "#ffffff" : "#000000";
    if (isButton) {
      return buttonTextColor || checkColorIfButton || textForBK;
    } else if (generalTextColor) {
      return generalTextColor;
    } else {
      return textForBK;
    }
  }

  let changeColor = getChangeColor();

  let getLinkText = () => {
    if (link.locize) {
      return <Phrase Key={link.locize.Key} default={link.locize.default} />;
    }
    return link.text;
  };

  let openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  let getTextData = () => {
    return (
      <>
        {locize ? (
          <Phrase
            Key={locize.Key}
            default={locize.default}
            param={{
              data: (
                <span style={{ textDecoration: "underline" }} onClick={() => openInNewTab(link.href)}>
                  {getLinkText()}
                </span>
              ),
              changeWith: "$here",
            }}
          />
        ) : (
          getDataByLocal(text)
        )}
      </>
    );
  };

  // todo(vmyshko): wtf is this?
  if (textType === TextTypes.PRINTED) {
    switch (textSize) {
      case TextSizes.LARGE:
        return (
          <LTextPrinted color={changeColor} padding={padding}>
            {getTextData()}
          </LTextPrinted>
        );
      case TextSizes.SMALL:
        return (
          <STextPrinted color={changeColor} padding={padding}>
            {getTextData()}
          </STextPrinted>
        );
      default:
      case TextSizes.MEDIUM:
        return (
          <MTextPrinted color={changeColor} padding={padding}>
            {getTextData()}
          </MTextPrinted>
        );
    }
  } else if (textType === TextTypes.BOLD) {
    switch (textSize) {
      case TextSizes.MEDIUM:
        return (
          <MTextBold color={changeColor} padding={padding}>
            {getTextData()}
          </MTextBold>
        );
      case TextSizes.LARGE:
        return (
          <LTextBold color={changeColor} padding={padding}>
            {getTextData()}
          </LTextBold>
        );
      default:
      case TextSizes.SMALL:
        return (
          <STextBold color={changeColor} padding={padding}>
            {getTextData()}
          </STextBold>
        );
    }
  } else if (textType === TextTypes.MEDIUM) {
    switch (textSize) {
      case TextSizes.MEDIUM:
        return (
          <MTextMedium color={changeColor} padding={padding}>
            {getTextData()}
          </MTextMedium>
        );
      case TextSizes.LARGE:
        return (
          <LTextMedium color={changeColor} padding={padding}>
            {getTextData()}
          </LTextMedium>
        );
      default:
      case TextSizes.SMALL:
        return (
          <STextMedium color={changeColor} padding={padding}>
            {getTextData()}
          </STextMedium>
        );
    }
  } else {
    // REGULAR
    switch (textSize) {
      case TextSizes.MEDIUM:
        return (
          <MText color={changeColor} padding={padding}>
            {getTextData()}
          </MText>
        );
      case TextSizes.LARGE:
        return (
          <LText color={changeColor} padding={padding}>
            {getTextData()}
          </LText>
        );
      default:
      case TextSizes.SMALL:
        return (
          <SText color={changeColor} padding={padding}>
            {getTextData()}
          </SText>
        );
    }
  }
}
