import React from "react";
import styled from "styled-components";

export const BackBtnStyle = styled.div`
  display: flex;
  justify-content: center;
  flex: ${({ grow }) => grow || "none"};
  width: 10%;
  fill: ${(props) => (props.colors ? props.colors.generalTextColor : "")};
`;

export const QuestionsStyle = styled.div`
  display: flex;
  justify-content: flex-end;

  padding: 16px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  height: ${(props) => props.titleHeight}px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`;

export const ShadowLayer = styled.div`
  position: absolute;
  height: 100%;
  width: 25%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  left: 75%;
  pointer-events: none;
`;

export const LibraryHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

export const SurpriseMeContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%
  align-items: center;
`;

export const NewTitle = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  color: ${(props) => props.colors.generalTextColor};
`;

export const ChannelImageWrapper = styled.div`
  display: flex;
  min-width: ${(props) => (props.size < window.innerWidth ? window.innerWidth : props.size)}px;
  border-bottom: 4px solid #ccc;
  margin-bottom: 10px;
`;
export const LibraryChannels = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-track {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
  }
  ::-webkit-overflow-scrolling {
    width: 0;
    height: 0;
  }
`;

export const ChannelImage = ({ src, active, onClick, channelWidth, channelPadding, channelBorder }) => {
  return (
    <div
      onClick={onClick}
      style={{
        padding: `${channelPadding}px`,
        marginBottom: `-${channelBorder}px`,
        borderBottom: active && `${channelBorder}px solid black`,
        opacity: active ? 1 : 0.5,
        height: `${channelWidth}px`,
        maxHeight: `${channelWidth}px`,
        position: "relative",
        zIndex: 1,
      }}
    >
      <img
        alt={"Channel"}
        src={src}
        style={{
          width: `${channelWidth}px`,
          height: `${channelWidth}px`,
        }}
      />
    </div>
  );
};

export const LibraryRipples = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  // overscroll-behavior-y: contain;
  overflow-y: ${() => (window.screen.availWidth > window.screen.availHeight) ? 'hidden' : 'scroll'};
  overflow-x: ${() => (window.screen.availWidth > window.screen.availHeight) ? 'hidden' : 'scroll'};
  grid-auto-flow: row;
  height: 100%;
  background-attachment: local;
  justify-items: center;
  grid-row-gap: 15px;
  grid-template-rows: ${({ rippleHeight, nRipples }) => `repeat(${Math.floor(nRipples / 3)}, max-content)`};
`;
const minValue = Math.min(window.innerWidth, window.innerHeight);
const rWidth = () => Math.floor(minValue / 3) - 25;
export const LibraryRipple = ({ src, onClick, onLoad }) => (
  <div
    onClick={onClick}
    style={{
      display: "grid",
      height: `${rWidth()}px`,
      width: `${rWidth()}px`,
      justifyContent: `center`,
      border: "1px solid rgb(0, 0, 0, 0.1)",
      borderRadius: "50%",
    }}
  >
    <img
      alt={"ripple"}
      src={src}
      onLoad={onLoad}
      style={{
        borderRadius: "50%",
        height: "inherit",
        perspective: "1px",
      }}
    />
  </div>
);
