import React, { useEffect, useState } from "react";
import { TextualPopup, TextualWithLinkPopup } from "./popup";
import { ModalLayout } from "./styles/messageStyle";
import { getLocal } from "../actions";

const getDataByLocal = (item) => {
  let origin = getLocal();
  return item[origin] || item["en"];
};

export default function ShoutoutPreview(props) {
  const defaultColors = {
    // todo(vmyshko): remove unused colors
    backgroundColor: "#fff",
    generalColor: "#000000",
    messageBackgroundColor: "#fff",
    messageColor: "#000000",
    iconsColor: "#000",
    poweredBy: "#000",
    generalTextColor: "#000",
    overlayColor: "#aaa",
    loaderColor: "#000",
    cropperCoverColor: "rgb(0,0,0,0.8)",

    buttonBackground: "#dddddd",
    buttonTextColor: "#4c4c4c",
  };

  let initData: any = {};
  let initColors: any = {};
  const [data, setData] = useState(initData);
  const [colors, setColors] = useState(initColors);

  useEffect(() => {
    window.addEventListener("message", handleReceivedData);
    const message = { ping: "shoutout_preview_page_loaded" };
    window.parent.postMessage(JSON.stringify(message), "*");
    return () => window.removeEventListener("message", handleReceivedData);
    //eslint-disable-next-line
  }, []);

  const handleReceivedData = (event: any) => {
    const rawData = JSON.parse(decodeURIComponent(event.data));

    const { shoutout, colors } = rawData;
    if (!colors) {
      setColors(defaultColors);
    } else {
      setColors(colors);
    }

    const { hasCloseIcon } = shoutout.general || {};

    setData({ ...shoutout, hasCloseIcon });
  };

  const { overlayColor } = colors;

  return (
    <ModalLayout overlayColor={overlayColor}>
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "8px",
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "center",
        }}
      >
        <PopUp data={data} colors={colors} actions={{ getDataByLocal }} />
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 10,
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        />
      </div>
    </ModalLayout>
  );
}

function PopUp({ data, colors, actions = {} }) {
  let { type } = data || {};

  switch (type) {
    case "textualWithLink":
      return <TextualWithLinkPopup data={data} actions={actions} colors={colors} />;
    case "textual":
    default:
      return <TextualPopup data={data} actions={actions} colors={colors} />;
  }
}
