import React from "react";

const RightIcon = ({style}) => {
    return <svg width="103" height="121" viewBox="0 0 103 121" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M54.9997 120C48.4371 108.971 40.7398 76.081 40.1704 59.3142C39.6011 42.5473 39.9808 32.9266 47.7199 8.15209" stroke="white" strokeLinecap="round"/>
    <path d="M50.1003 22.4595C49.619 18.0257 49.8627 14.2097 50.3192 9.89773C50.469 8.48197 50.658 7.09886 50.4235 5.63769C50.2273 4.4156 48.8812 5.56206 48.525 5.7244C44.881 7.38526 41.2141 9.00574 37.5471 10.5816" stroke="white" strokeLinecap="round"/>
    </svg>
       
};

export default RightIcon;