import React from "react";

import { FlowButton } from "../styles/IntroStyle";
import { Phrase } from "../Phrase";
import DrinkIcon from "./icons/DrinkIcon";

const DrinkFlow = (props) => {
  const { data, onDrinkFlowClick} = props;
  const { home } = data || {};

  const btn = (home.buttons.snap) || (home && home.buttons && home.buttons.drink) || {};
  const btnText = (home && home.buttons && home.buttons.drink) || {};

  return (
    <FlowButton onClick={onDrinkFlowClick} data={btn}>
      <DrinkIcon stroke={btn.iconColor}/>
    <Phrase Key={btnText.translationKey} default={ btnText.text || ''} />
    </FlowButton>
  );
}

export default DrinkFlow;