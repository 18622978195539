import React, { useRef } from 'react';
import { Macaron, MacaronContainer, MacaronImage, SurpriseMacaron } from './styles';
import QuestionMarkIcon from './icons/QuestionMarkIcon';
import { MoreVert } from '@material-ui/icons';
import SurpriseLabel from './SurpriseLabel';
import MacaronOptionsPopover from './MacaronOptionsPopover';

const MacaronButton = (props) => {
  const {macaronInfo, macaronId, isOptionsOpen, content, Icon, onOptionsClick, onMacaronDelete, onOptionsClose, onMacaronClick, onDuplicate, renderCondition = true} = props;

  const { general = {}, macaron = {}} = content;

  const button = content.home.buttons.snap || content.home.buttons.library;
  const surpriseLabelText = macaron.texts && macaron.texts.surprise ? macaron.texts.surprise : {}
  const generalColor =  general.colors.generalTextColor;
  const backgroundColor = general.colors.backgroundColor;

  const optionsIconStyle = {
    position: 'absolute', 
    borderRadius: '50%', 
    width: '28px', 
    height: '28px', 
    right: '5px', 
    backgroundColor: button.backgroundColor, 
    color: button.textColor,
  }

    const macaronRef = useRef();
    
    return <MacaronContainer>
        {
            renderCondition && <>
             {(macaronInfo && macaronInfo.image) ? <>
            {macaronInfo.isSurprise ? 
            <SurpriseMacaron borderColor={generalColor}>
              <QuestionMarkIcon fill={generalColor}/>
              </SurpriseMacaron> : 
              <MacaronImage src={macaronInfo.image} alt=""/>}
            <MoreVert ref={macaronRef} onClick={onOptionsClick} style={optionsIconStyle}/>
            {macaronInfo.isSurprise && <SurpriseLabel borderColor={generalColor} textColor={generalColor} backgroundColor={backgroundColor} surpriseLabelText={surpriseLabelText}/>}
              <MacaronOptionsPopover macaronId={macaronId} onDelete={onMacaronDelete} isOpen={isOptionsOpen} anchorRef={macaronRef.current} onClose={onOptionsClose} onDuplicate={onDuplicate} content={content}/>
            </> :
            <Macaron onClick={onMacaronClick} borderColor={generalColor}>
                <Icon fill={generalColor}/>
            </Macaron>
            }
            </>
        }
        </MacaronContainer>
}

export default MacaronButton;