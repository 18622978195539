import React from "react";

const PoweredByIcon = (props) => {
    const {fill, style} = props;

    return <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <ellipse cx="5.41844" cy="5.46249" rx="5.40183" ry="5.39481" fill={fill || "black"} fillOpacity="0.7"/>
    <path d="M6.51304 6.50001C6.48405 6.68338 6.43573 6.84745 6.35842 7.01152C6.06851 7.63884 5.43071 8.07314 4.69627 8.07314C4.11645 8.07314 3.59462 7.80291 3.25639 7.37826C3.0148 7.05978 2.86018 6.66408 2.86018 6.23943L2.86018 4.6856C2.86018 4.58909 2.86984 4.49258 2.87951 4.39607C2.87951 4.36712 2.88917 4.33816 2.88917 4.31886C2.91816 4.20305 2.95682 4.08724 3.00513 3.98107C3.11143 3.72049 3.28538 3.48887 3.49798 3.3055C3.7589 3.07387 4.08746 2.91945 4.44502 2.8712H4.45468C4.54166 2.86155 4.61897 2.85189 4.69627 2.85189L6.25212 2.85189C7.26681 2.85189 8.08822 3.67224 8.08822 4.6856C8.08822 5.60246 7.4021 6.37455 6.51304 6.50001ZM6.25212 2.46585L4.69627 2.46585C4.59964 2.46585 4.49334 2.4755 4.38704 2.48515C3.45933 2.62027 2.70556 3.3248 2.52195 4.24165C2.51229 4.27061 2.51229 4.30921 2.50262 4.33816C2.4833 4.45398 2.47363 4.56979 2.47363 4.6856L2.47363 6.23943C2.47363 7.46512 3.46899 8.45919 4.69627 8.45919C5.70129 8.45919 6.58069 7.77396 6.84161 6.82815C7.78865 6.56757 8.47477 5.68932 8.47477 4.6856C8.47477 3.45991 7.47941 2.46585 6.25212 2.46585Z" fill="white"/>
    <path d="M6.46473 5.72793C6.39708 5.73758 6.31977 5.74723 6.25213 5.74723L5.75928 5.74723L5.75928 6.23943C5.75928 6.59652 5.57567 6.92466 5.29542 7.10803L5.28576 7.11768C5.2761 7.12734 5.25677 7.13699 5.24711 7.14664C5.22778 7.15629 5.21811 7.16594 5.19879 7.17559C5.18912 7.17559 5.17946 7.18524 5.1698 7.18524C5.15047 7.19489 5.12148 7.20454 5.10215 7.2142C5.08282 7.22385 5.05383 7.2335 5.0345 7.24315C5.02484 7.24315 5.01518 7.2528 5.00551 7.2528C4.99585 7.2528 4.98619 7.26245 4.97652 7.26245C4.88955 7.28175 4.79291 7.30106 4.69628 7.30106C4.61897 7.30106 4.55132 7.2914 4.48368 7.28175C4.00049 7.18524 3.63327 6.75094 3.63327 6.23943L3.63327 4.68561C3.63327 4.6277 3.63327 4.56979 3.6526 4.51189C3.6526 4.50224 3.6526 4.48293 3.66227 4.47328C3.74924 4.03898 4.10679 3.70119 4.56099 3.63364C4.59964 3.62399 4.64796 3.62399 4.69628 3.62399L6.25213 3.62399C6.84161 3.62399 7.31513 4.09689 7.31513 4.68561C7.31513 5.19712 6.94791 5.62176 6.46473 5.72793ZM6.25213 3.23794L4.69628 3.23794C4.62863 3.23794 4.57065 3.23794 4.49334 3.25724C3.89419 3.33445 3.40135 3.79771 3.27572 4.39607C3.27572 4.41538 3.26606 4.43468 3.26606 4.45398C3.25639 4.53119 3.24673 4.6084 3.24673 4.68561L3.24673 6.23943C3.24673 7.04048 3.89419 7.6871 4.69628 7.6871C5.49836 7.6871 6.14583 7.04048 6.14583 6.23943L6.14583 6.13327L6.25213 6.13327C7.05421 6.13327 7.70167 5.48665 7.70167 4.68561C7.70167 3.88457 7.04455 3.23794 6.25213 3.23794Z" fill="white"/>
    </svg>    
};

export default PoweredByIcon;
