import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-konva";
import CustomFrame from "./customFrames";
import useKonvaAnimation from "../../../utils/useKonvaAnimation";
import { EditTextPortal, onTextAreaMounted } from "../EditTextPortal";

const initText = "Double Tap to change me";

const Frame = React.forwardRef(
  ({ frameIsCustom, editContainerSize, frameImage, drawingAreaProps = {}, circleNode, textAreaRef, customFrameType, fontSize: defaultFontSize, vWidth, ...rest }, ref) => {
    const { editMode, setEditTextMode } = drawingAreaProps;
    const [customFrameText, setCustomFrameText] = useState();
    const regularFrameRef = useRef();
    const frameAnimation = useKonvaAnimation(vWidth, editContainerSize.height / 2, { addToScale: 0.1 });
    const [fontSize, setFontSize] = useState(defaultFontSize);
    const [showPortal, setShowPortal] = useState(false);

    const saveFrameTextOnEdit = () => {
      setShowPortal(false);
      setEditTextMode(false);
      if (!textAreaRef.current) return;
      const text = textAreaRef.current.value.trim().replace(/\r?\n/, "");
      setCustomFrameText(text);
    };

    const updateTextAreaSize = (event) => {
      const { target } = event;
      target.style.height = "";
      target.style.height = target.scrollHeight + "px";
    };

    useEffect(() => {
      if (frameImage) {
        frameAnimation(regularFrameRef.current);
      }
    }, [frameImage, frameAnimation]);

    return (
      <>
        {frameIsCustom ? (
          <CustomFrame
            ref={ref}
            customFrameType={customFrameType}
            fontSize={fontSize}
            vWidth={vWidth}
            customFrameText={customFrameText || initText}
            onDblTap={() => {
              if (!showPortal && editMode === "frames") {
                setShowPortal(true);
                setEditTextMode(true);
              }
            }}
            onDblClick={() => {
              if (!showPortal && editMode === "frames") {
                setShowPortal(true);
                setEditTextMode(true);
              }
            }}
            {...rest}
          />
        ) : (
          frameImage && (
            <Image
              image={frameImage}
              absolutePosition={{
                x: circleNode.position().x - circleNode.radius(),
                y: circleNode.position().y - circleNode.radius() - 1,
              }}
              width={circleNode.size().width + 3}
              height={circleNode.size().height + 3}
              listening={false}
              name={"frame"}
              ref={regularFrameRef}
            />
          )
        )}
        {editMode === "frames" && showPortal && frameIsCustom && (
          <EditTextPortal
            vWidth={vWidth}
            fontSize={fontSize}
            defaultFontSize={defaultFontSize}
            setFontSize={setFontSize}
            customFrameRef={ref}
            defaultText={customFrameText}
            onTextAreaMounted={onTextAreaMounted(textAreaRef)}
            onBlur={saveFrameTextOnEdit}
            onTouchMove={saveFrameTextOnEdit}
            onFocus={updateTextAreaSize}
            onInput={updateTextAreaSize}
          />
        )}
      </>
    );
  }
);

export { Frame };
