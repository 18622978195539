import React from "react";

const CreateIcon = ({stroke, style}) => {
    return <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M6.41538 23.3347C6.04615 23.3347 5.8 23.15 5.8 22.8423C5.8 22.7808 5.67692 21.427 4.32308 20.1346C3.03077 18.8423 1.61538 18.7193 1.55385 18.7193C1.24615 18.7193 1 18.4116 1 18.1039C1 17.7962 1.24615 17.4885 1.55385 17.4885C1.55385 17.4885 2.96923 17.3039 4.32308 16.0116C5.61538 14.7193 5.8 13.3039 5.8 13.2423C5.86154 12.9346 6.10769 12.6885 6.41538 12.6885C6.72308 12.6885 7.03077 12.9346 7.03077 13.2423C7.03077 13.2423 7.15385 14.6577 8.50769 16.0116C9.8 17.3039 11.2769 17.4885 11.2769 17.4885C11.5846 17.4885 11.8308 17.7962 11.8308 18.1039C11.8308 18.4116 11.5846 18.7193 11.2769 18.7193C11.2154 18.7193 9.8 18.9039 8.50769 20.1962C7.21538 21.4885 7.03077 22.8423 7.03077 22.9039C7.03077 23.15 6.78462 23.3347 6.41538 23.3347ZM6.35385 15.3346C6.04615 15.8885 5.67692 16.3808 5.18462 16.8731C4.75385 17.3039 4.2 17.7346 3.64615 18.0423L3.52308 18.1039L3.64615 18.1654C4.2 18.4731 4.69231 18.8423 5.18462 19.3346C5.61538 19.7654 6.04615 20.3193 6.35385 20.8731L6.41538 20.9962L6.47692 20.8731C6.78462 20.3193 7.15385 19.827 7.64615 19.3346C8.07692 18.9039 8.63077 18.4731 9.18462 18.1654L9.30769 18.1039L9.18462 18.0423C8.63077 17.7346 8.13846 17.3654 7.64615 16.8731C7.21538 16.4423 6.78462 15.8885 6.47692 15.3346L6.41538 15.2116L6.35385 15.3346Z" stroke={stroke || "white"} strokeWidth="0.05"fill={stroke || "white"}/>
    <path d="M9.86148 10.7806C9.7384 10.7806 9.67686 10.7191 9.55378 10.6575C9.36917 10.5344 9.30763 10.3498 9.36917 10.1652L9.7384 8.13444L8.26148 6.71905C8.1384 6.59597 8.07686 6.41136 8.1384 6.22674C8.19994 6.04213 8.32301 5.91905 8.56917 5.85751L10.5999 5.54982L11.523 3.70366C11.523 3.64212 11.7076 3.51904 11.8922 3.51904C12.0769 3.51904 12.2615 3.64212 12.323 3.82674L13.2461 5.67289L15.2769 5.98059C15.4615 5.98059 15.6461 6.1652 15.7076 6.34982C15.7692 6.53444 15.7076 6.71905 15.5846 6.84213L14.1076 8.25752L14.4769 10.2883C14.5384 10.4729 14.4153 10.6575 14.2922 10.7806C14.2307 10.8421 14.1076 10.9037 13.9846 10.9037C13.923 10.9037 13.7999 10.9037 13.7384 10.8421L11.9538 9.91906L10.1692 10.8421C10.0461 10.7806 9.92301 10.7806 9.86148 10.7806ZM11.8922 8.68829C11.9538 8.68829 12.0769 8.68829 12.1384 8.74983L13.2461 9.36521L12.9999 8.13444C12.9999 7.94982 12.9999 7.82675 13.123 7.70367L14.0461 6.78059L12.8769 6.59597C12.6922 6.59597 12.5692 6.4729 12.5076 6.34982L11.9538 5.18059L11.3999 6.34982C11.3384 6.4729 11.1538 6.59597 11.0307 6.59597L9.7384 6.78059L10.6615 7.70367C10.7846 7.82675 10.8461 8.01136 10.7846 8.13444L10.5384 9.36521L11.6461 8.74983C11.7076 8.74983 11.8307 8.68829 11.8922 8.68829Z" stroke={stroke || "white"} strokeWidth="0.05" fill={stroke || "white"}/>
    <path d="M32.385 6.04215C31.5234 5.18061 30.2311 5.18061 29.185 6.04215L20.6311 13.1806C19.8926 13.796 18.7234 14.9653 18.108 15.7653C17.4926 16.5037 15.708 18.6576 15.2157 19.9499C15.1542 19.9499 15.1542 19.9499 15.0926 19.9499C14.1696 19.9499 13.2465 20.3806 12.4465 21.1807C11.8926 21.7345 11.708 22.473 11.5234 23.273C11.4003 23.7037 11.3388 24.073 11.1542 24.5037C10.7234 25.673 9.43111 26.5345 9.43111 26.5345C9.24649 26.6576 9.12341 26.9037 9.18495 27.0884C9.24649 27.3345 9.43111 27.4576 9.67726 27.5191C9.7388 27.5191 10.6003 27.6422 11.708 27.6422C12.385 27.6422 13.0003 27.5807 13.6157 27.5191C14.6003 27.3961 16.4465 27.0268 17.3696 26.1037C18.2311 25.2422 18.6003 24.196 18.4773 23.1499C19.8311 22.596 22.108 20.6883 22.6003 20.2576C23.4003 19.6422 24.5696 18.473 25.185 17.7345L32.3234 9.24216C33.185 8.196 33.2465 6.90369 32.385 6.04215ZM16.5696 25.3037C16.0773 25.796 14.908 26.2268 13.4311 26.4114C12.8773 26.473 12.3234 26.5345 11.6465 26.5345C11.4003 26.5345 11.2157 26.5345 11.0311 26.5345C11.4619 26.1037 11.8926 25.5499 12.1388 24.873C12.3234 24.3807 12.4465 23.9499 12.508 23.5191C12.6926 22.8422 12.8157 22.2883 13.185 21.9191C13.7388 21.3653 14.4157 21.0576 15.0311 21.0576C15.5234 21.0576 16.0157 21.2422 16.4465 21.673L16.508 21.7345C16.5696 21.796 16.5696 21.796 16.6311 21.8576C17.6157 22.8422 17.6157 24.3191 16.5696 25.3037ZM17.4311 21.0576L17.308 20.9345L17.2465 20.873C16.9388 20.5653 16.6311 20.3191 16.2619 20.196C16.5696 19.5191 17.5542 18.1653 18.5388 16.9345L21.4311 19.8268C20.3234 20.6883 18.9696 21.7345 18.1696 22.1037C17.985 21.7345 17.7388 21.3653 17.4311 21.0576ZM31.5234 8.5037L24.3234 17.0576C23.8311 17.673 22.9696 18.5345 22.2311 19.1499L19.2157 16.1345C19.8311 15.4576 20.6926 14.596 21.308 14.0422L29.8619 6.90369C30.2926 6.53446 31.0311 6.28831 31.585 6.84215C32.2619 7.51908 31.708 8.25754 31.5234 8.5037Z" stroke={stroke || "white"} strokeWidth="0.1" fill={stroke || "white"}/>
    </svg>
    
};

export default CreateIcon;
