import styled from "styled-components";

export const PopupMessageStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;

  max-height: 90%;
  min-width: 300px;
  width: fit-content;
  margin: 0px 12px;
  background-color: ${({ background = "black" }) => background};
  background-image: ${({ background = "black" }) => background};
  border: ${({ background }) => (["white", "#fff", "#ffffff"].includes(background) ? "1px solid black" : "none")};
  border-radius: 20px;
  z-index: 1001;
`;

export const PopupTitleStyle = styled.div`
  display: flex;
  justify-content: center;
  max-height: 20%;
  padding: 0 20px;
`;

export const PopupDescriptionStyle = styled.div`
  display: flex;
  justify-content: center;
  max-height: 45%;
`;

export const PopupButtonsStyle = styled.div`
  align-self: center;

  display: grid;
  grid-gap: 12px;
  flex-direction: column;

  margin: 20px;
  margin-top: 10px;
  margin-bottom: 50px;
  gap: 12px;
`;

export const PopupButtonsVerticalStyle = styled(PopupButtonsStyle)`
  width: 100%;
  height: 40%;
`;
