import React from 'react';
import { PoweredByContainer } from '../styles/IntroStyle';
import { Divider } from '@material-ui/core';
import PoweredByIcon from './icons/PoweredByIcon';
import { Phrase } from '../Phrase';

const LocationLabel = ({locationName, textColor}) => {
    return <PoweredByContainer textColor={textColor}>
        <span style={{textAlign: 'center', flexGrow: 1}}>
            {locationName}
        </span>
        <Divider style={{width: '1px', height: '60%', backgroundColor: textColor, margin: '0 10px'}}/>
        <PoweredByIcon fill={textColor} style={{marginRight: '5px', width: '18px', height: '17px'}}/>
        <span style={{flexShrink: 0}}>
            <Phrase Key={'aa97b8e8-8928-4394-9234-5f16bafecf3e'} default={'Powered by Ripples'}/>
        </span>
    </PoweredByContainer>
};

export default LocationLabel;