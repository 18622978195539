import axios from "axios";
import qs from 'qs';

export const getFilters = async (filterIds) => {
  return axios
    .get(`/api/v1/filtersByIds?${qs.stringify({ids: filterIds})}`)
    .then((response) => response.data.data)
    .then((data) => {
        if (!data) {
          throw new Error("cant find filters");
        }
        return data;
      })
    .catch((err) => {
      console.log(err);
    });
}
