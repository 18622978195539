export function downloadBase64File(base64Data, filename) {
  let element = document.createElement("a");
  element.setAttribute("href", base64Data);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function base64StringtoFile(base64String, filename) {
  let arr = base64String.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function canvasToDataStream(canvas) {
  const stream = canvas.toDataURL();
  const binary = atob(stream.split(",")[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/png" });
}

export function image64toCanvasRef(canvasRef, image64, pixelCrop) {
  const canvas = canvasRef; // document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");
  const image = new Image();
  image.src = image64;
  image.onload = function () {
    ctx.drawImage(image, pixelCrop.x, pixelCrop.y, 360, 270);
  };
}

export const getNavigatorLanguage = () => {
  let langs = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage;
  let origin = langs.split("-")[0];
  return origin;
};

export function imageCanvasToGray(canvasRef, image64, pixelCrop) {
  const canvas = canvasRef;
  const ctx = canvas.getContext("2d");
  ctx.draggable = true;
  const imageData = ctx.getImageData(0, 0, 360, 360 * 0.75);

  for (let i = 0; i < imageData.data.length; i += 4) {
    imageData.data[i + 0] = imageData.data[i + 1] = imageData.data[i + 2] = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
  }
  ctx.putImageData(imageData, 0, 0);
}
// todo(vmyshko): some functions are duplicates from /src/utils/Utils.js
export function scaleImageOnCanvas(canvasRef, scaleX, scaleY) {
  const canvas = canvasRef;
  const context = canvas.getContext("2d");
  context.scale(scaleX, scaleY);
}

export function fetch_retry(url, options, n) {
  return fetch(url, options).catch(function (error) {
    if (n === 1) throw error;
    return fetch_retry(url, options, n - 1);
  });
}

export function debounce(fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}


export function enableStaticScreen() {
    document.addEventListener('gesturestart', function(e: any) {
      e.preventDefault();
    });

    document.addEventListener('gesturechange', function(e: any) {
        e.preventDefault();
    });

    document.addEventListener('gestureend', function(e: any) {
        e.preventDefault();
    });
}

export function disableStaticScreen() {
    document.removeEventListener('gesturestart', function(e: any) {
      e.preventDefault();
    });

    document.removeEventListener('gesturechange', function(e: any) {
        e.preventDefault();
    });

    document.removeEventListener('gestureend', function(e: any) {
        e.preventDefault();
    });
}