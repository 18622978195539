const Axios = require("axios");

export function blobToBase64(blob) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
}

export function downloadImageToBlob(url) {
  return Axios.get(url, {
    responseType: "arraybuffer",
    timeout: 20000,
  }).then((response) => (`data:image/png;base64,` + Buffer.from(response.data, "binary").toString("base64")));
}
