import React from 'react';
import { SurpriseLabelContainer } from './styles';
import { Phrase } from '../Phrase';

const SurpriseLabel = (props) => {
    const {surpriseLabelText = {}} = props;
    return <SurpriseLabelContainer {...props}>
        <Phrase Key={surpriseLabelText.translationKey} default={surpriseLabelText.text || ''}/>
    </SurpriseLabelContainer>
};

export default SurpriseLabel