import axios from "axios";

export function getCampaignByCampaignShortId(campaignShortId) {
  return new Promise(async (resolve, reject) => {
    axios
      .get(`/api/v1/campaigns/byCampaignShortId/${campaignShortId}`, {
        campaignShortId,
      })
      .then((result) => {
        if (result && result.status === 200 && result.data && result.data.data) {
          resolve(result.data.data);
        } else {
          reject({ code: 4 });
        }
      })
      .catch((e) => {
        console.log(`${getCampaignByCampaignShortId.name} error`, e);
        reject({ code: 4 });
      });
  });
}
