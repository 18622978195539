import Konva from "konva";

const getImageFromUrl = (image) => {
  return new Promise((resolve) => {
    Konva.Image.fromURL(image, resolve);
  });
};

let whiteHeartImageCache = null;
let blackHeartImageCache = null;
const getHeartImage = async (fontColor) => {
  const isWhiteHeart = ["white", "#fff", "#ffffff"].indexOf(fontColor) !== -1;
  const heartImg = isWhiteHeart ? "/amatic_style_white_heart.png" : "/amatic_style_black_heart.png";

  if (isWhiteHeart) {
    if (!whiteHeartImageCache) {
      whiteHeartImageCache = await getImageFromUrl(heartImg);
    }

    return whiteHeartImageCache;
  } else {
    if (!blackHeartImageCache) {
      blackHeartImageCache = await getImageFromUrl(heartImg);
    }

    return blackHeartImageCache;
  }
};

export async function addAmaticHearts(width, height, fontColor, textGroupNode) {
  const heartMargin = 15;
  const heartSize = 20;

  const heartImage = await getHeartImage(fontColor);
  const heartImageLeft = heartImage.clone();
  heartImageLeft.setAttrs({
    x: width / 2,
    y: height / 2,
    width: heartSize,
    height: heartSize,
    //
    offsetX: width / 2 + heartMargin + heartSize,
    offsetY: heartSize / 2,
  });

  const heartImageRight = heartImage.clone();
  heartImageRight.setAttrs({
    x: width / 2,
    y: height / 2,
    width: heartSize,
    height: heartSize,
    //
    offsetX: -width / 2 - heartMargin,
    offsetY: heartSize / 2,
  });

  textGroupNode.add(heartImageLeft);
  textGroupNode.add(heartImageRight);

  const sizeWidth = width + 2 * heartMargin + 2 * heartSize;
  const sizeHeight = heartSize;

  return { width: sizeWidth, height: sizeHeight };
}
