import axios from 'axios';

export const getLocal = () => {
  let langs = navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage);
  let origin = langs.split('-')[0];
  return origin;
}

export function getTranslations() {
  return new Promise(async (resolve, reject) => {
    let origin = getLocal()
    axios.get(`/api/v1/getTranslations/${origin}`)
      .then((result) => {
        if (result && result.status === 200 && result.data && result.data.data) {
          resolve(result.data.data)
        } else {
          resolve({})
        }
      }).catch(() => {
        resolve({})
      });
  })
}
