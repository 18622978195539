export function rotateAnimation(nodes, sensitivity, angle) {
  nodes.forEach((node) => {
    node.rotate(angle * sensitivity);
  });
}

export function getChildren(konvaNode) {
  return [...konvaNode.getChildren()];
}

export function translateChildrenOffset(konvaNode, stageWidth, stageHeight) {
  const konvaNodeSize = konvaNode.getClientRect();
  getChildren(konvaNode).forEach((node) => {
    const isImage = !!node.getAttr("image");
    if (isImage) {
      return;
    }

    node.offsetY(konvaNodeSize.y + konvaNodeSize.height / 2 - node.y());
    node.offsetX(node.getWidth() / 2);
    node.x(stageWidth / 2);
    node.y(stageHeight / 2);
  });
}

export function addChildren(konvaNode, childNode, translateOffset = false, stageWidth, stageHeight) {
  konvaNode.add(childNode);

  if (translateOffset && stageWidth && stageHeight) {
    konvaNode.draw();
    translateChildrenOffset(konvaNode, stageWidth, stageHeight);
  }
}

export function toggleDraggable(konvaNode, draggable) {
  if (konvaNode) konvaNode.draggable(draggable);
}
