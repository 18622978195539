import React, { useEffect } from "react";
import { Stage as KonvaStage } from "react-konva";
import { getChildren, rotateAnimation, toggleDraggable } from "../../../utils/konvaUtils";
import { getPointAngle, getTouchesDistance, lineIntersectRect } from "../../../utils/touchesMath";

let customFrameRotationDelta = 0;
let distanceDelta = 0;
let touchPrevAngle = 0;

const Stage = React.forwardRef(
  (
    {
      children,
      vWidth,
      customFrameNode,
      setIsRotate,
      drawingAreaProps = {},
      textGroupRef,
      originalLayerRef,
      activeElement,
      customFrameType,
      frameIsCustom,
      imageStatus,
      imageRef,
      setActiveElementMode,
      editable,
      stageWidth,
      onImageChange,
    },
    ref
  ) => {
    const { editMode, cText } = drawingAreaProps;
    const getTouches = (evt) => {
      return [...evt.targetTouches].map((touch) => {
        const rect = touch.target.getBoundingClientRect();
        return {
          x: touch.clientX - rect.left,
          y: touch.clientY - rect.top,
        };
      });
    };

    const startCustomFrameRotate = (e) => {
      const touches = getTouches(e.evt);
      if (!customFrameNode) return;
      const rotation = +customFrameNode.rotateFrame(customFrameType) || 360; // 0 || 360
      const touch = touches[0];
      customFrameRotationDelta = rotation - getPointAngle({ x: vWidth / 2, y: vWidth / 2 }, touch);
    };

    function getActiveNode() {
      return activeElement === "texts" ? textGroupRef.current : imageRef.current;
    }

    function rotate(touches, nodes) {
      let touchesAngles = (getPointAngle(touches[1], touches[0]) + getPointAngle(touches[0], touches[1])) / 2;
      let newAngle = touchesAngles - touchPrevAngle;
      if (newAngle > 50 || newAngle < -50) {
        touchPrevAngle = touchesAngles;
        return;
      }

      const touchesDistance = getTouchesDistance(touches);

      touchPrevAngle = touchesAngles;
      rotateAnimation(nodes, touchesDistance / (vWidth / 100) / 100, newAngle, originalLayerRef);
    }

    const scale = (touches, nodes) => {
      const activeNode = getActiveNode();
      const newTouchesDistance = getTouchesDistance(touches);
      const newScale = newTouchesDistance * distanceDelta;

      nodes.forEach((node) => {
        if (node.height() * scale < node.height() / 2) return;
        node.scaleX(newScale);
        node.scaleY(newScale);
      });

      activeNode.getLayer().batchDraw();
      onImageChange();
    };

    const updateNode = (e, nodes) => {
      if (!activeElement) {
        return;
      }

      const touches = getTouches(e.evt);
      const activeNode = getActiveNode();
      const activeNodePosition = activeNode.getClientRect({ relativeTo: ref });
      const canInteractWithNode = lineIntersectRect(touches, activeNodePosition);

      if (!canInteractWithNode) {
        return;
      }

      scale(touches, nodes);
      rotate(touches, nodes);
    };

    const customFrameRotate = (e) => {
      const touches = getTouches(e.evt);
      if (!customFrameNode) return;
      const touch = touches[0];
      const newRotationAngle = getPointAngle({ x: vWidth / 2, y: vWidth / 2 }, touch);
      const newAngle = newRotationAngle + customFrameRotationDelta;
      customFrameNode.rotateFrame(customFrameType, newAngle);
      customFrameNode.draw();
      customFrameNode.getLayer().draw();
    };

    const stageTouchMove = (e) => {
      const touchesLength = e.evt.targetTouches && e.evt.targetTouches.length;
      if (touchesLength === 2) {
        if (activeElement && activeElement !== "edit" && editMode !== "edit") {
          updateNode(e, [...getChildren(textGroupRef.current)]);
        }

        if (activeElement && activeElement !== "texts" && editMode !== "texts" && imageStatus === "loaded") {
          updateNode(e, [imageRef.current]);
        }
      } else if (touchesLength === 1 && editMode === "frames" && frameIsCustom) {
        if (activeElement === "frames") customFrameRotate(e);
      }
    };

    useEffect(() => {
      customFrameRotationDelta = 0;
      if (!customFrameNode) return;

      customFrameNode.rotateFrame(customFrameType, 90);
      customFrameNode.draw();
      customFrameNode.getLayer().draw();
    }, [customFrameType, customFrameNode]);

    return (
      <KonvaStage
        width={stageWidth}
        height={vWidth}
        ref={ref}
        onTouchStart={(e) => {
          if (!editable) return;
          const touchesLength = e.evt.targetTouches && e.evt.targetTouches.length;

          if (touchesLength === 1 && editMode === "frames" && activeElement === "frames" && frameIsCustom) {
            startCustomFrameRotate(e);
            return;
          }

          if (touchesLength !== 2) return;

          if (editMode === "texts" && !cText) return;
          if (editMode === "edit" && imageStatus !== "loaded") return;
          if (editMode === "frames" && imageStatus !== "loaded" && !cText) return;

          const activeNode = getActiveNode();

          setIsRotate(true);
          toggleDraggable(imageRef.current, false);
          toggleDraggable(textGroupRef.current, false);

          const touches = getTouches(e.evt);
          touchPrevAngle = (getPointAngle(touches[1], touches[0]) + getPointAngle(touches[0], touches[1])) / 2;

          let scale;
          if (activeElement === "texts") {
            scale = activeNode.getChildren()[0].scaleX();
          } else {
            scale = activeNode.scaleX();
          }

          const touchesDistance = getTouchesDistance(touches);
          distanceDelta = scale / touchesDistance;
        }}
        onTouchMove={editable && stageTouchMove}
        onTouchEnd={() => {
          if (!editable) return;
          setIsRotate(false);
          toggleDraggable(imageRef.current, true);
          toggleDraggable(textGroupRef.current, true);
        }}
        onDragStart={() => {
          if (!editable) return;
          // todo(vmyshko): unsure about mode here
          setActiveElementMode("frames");
        }}
      >
        {children}
      </KonvaStage>
    );
  }
);

export { Stage };
