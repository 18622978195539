/**
 * font metrics are from https://opentype.js.org/font-inspector.html
 */
export const textOptionsAllLang = [
  {
    location: "/8.jpg",
    fontFamily: "NesspressoLucas",
    metrics: {
      capitalLetterHeight: 611,
      padding: 20,
      marginTop: -10,
      LowerCase: true,
    },
  },
  {
    location: "/1.jpg",
    fontFamily: "Playfair",
    metrics: {
      capitalLetterHeight: 708,
      padding: 30,
      marginTop: -10,
      LowerCase: true,
    },
  },
  {
    location: "/2.jpg",
    fontFamily: "Saira",
    metrics: {
      capitalLetterHeight: 688,
      padding: 15,
    },
  },
  {
    location: "/3.jpg",
    fontFamily: "Amatic",
    metrics: {
      capitalLetterHeight: 758, //sCapHeight
      padding: 20,
    },
  },
  {
    location: "/4.jpg",
    fontFamily: "LifeisMessy",
    metrics: {
      capitalLetterHeight: 1002,
      padding: 8,
      marginTop: 18,
    },
  },
  {
    location: "/5.jpg",
    fontFamily: "Rustico",
    metrics: {
      capitalLetterHeight: 700, //sCapHeight
      padding: 40,
      marginTop: 10,
    },
  },
  {
    location: "/6.jpg",
    fontFamily: "actionj",
  },
  {
    location: "/7.jpg",
    fontFamily: "burnstown-dam",
    metrics: {
      capitalLetterHeight: 611,
      padding: 20,
      marginTop: -18,
    },
  },
];

export const textOptionsJP = [
  {
    location: "/empty_font.png",
    fontFamily: "",
  },
  {
    location: "/motoyaMaru.jpg",
    fontFamily: "KosugiMaru",
  },
  {
    location: "/notoSerifJP.png",
    fontFamily: "NotoSerifJP",
  },
  {
    location: "/notoSansJP.png",
    fontFamily: "NotoSansJP",
  },
];

export const allFonts = [...textOptionsJP, ...textOptionsAllLang];

export default {
  textOptionsAllLang,
  textOptionsJP,
  allFonts,
};
