export const onTextAreaMounted = (textAreaRef) => (ref) => {
  if (!ref) {
    return;
  }

  textAreaRef.current = ref;
  // focus on text area, on the end of the text
  const value = ref.value;
  ref.focus();
  ref.value = "";
  ref.value = value;
};
