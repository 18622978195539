import React from "react";
import { ModalMsg } from "./ModalMsg";
import { SText, STextPrinted, TextWrapper } from "../styles/textStyle";
import { Phrase } from "../Phrase";
import { MsgClose } from "../styles/IntroStyle";

export function BadConnectionMsg(props) {
  const { data, back, setError } = props;
  const { instuction1, instuction2 } = data;

  return (
    <ModalMsg back={back}>
      <MsgClose
        onClick={() => {
          setError("");
        }}
        color={instuction1.color}
      />
      <TextWrapper>
        <STextPrinted color={instuction1.color} style={{ textAlign: "left", paddingLeft: "10px" }}>
          <Phrase Key={instuction1.translationKey} default={instuction1.text} />
        </STextPrinted>
        <SText color={instuction2.color} style={{ textAlign: "left", paddingLeft: "10px" }}>
          <Phrase Key={instuction2.translationKey} default={instuction2.text} />
        </SText>
      </TextWrapper>
    </ModalMsg>
  );
}
