import React from "react";

const MacaronIcon = ({stroke}) => {
    return <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.6647 35.8188C8.69294 40.5473 6.42648 33.1922 6.23625 32.4692C5.94274 31.7734 6.47552 30.8602 6.47552 30.8602" stroke={stroke || "white"} strokeWidth="1.57849" strokeMiterlimit="10"/>
    <path d="M26.3323 35.9063C44.304 31.1778 42.6573 23.6597 42.467 22.9367C42.3801 22.1865 41.4669 21.6537 41.4669 21.6537" stroke={stroke || "white"} strokeWidth="1.57849" strokeMiterlimit="10"/>
    <path d="M25.1756 30.5785C25.1756 30.5785 11.4272 33.8449 6.44845 30.757C4.46436 29.512 5.89412 27.8106 6.14962 27.5225" stroke={stroke || "white"} strokeWidth="1.57849" strokeMiterlimit="10"/>
    <path d="M24.9463 30.6389C24.9463 30.6389 38.5219 26.7162 41.3364 21.5779C42.4508 19.5177 40.3689 18.7402 40.0047 18.6152" stroke={stroke || "white"} strokeWidth="1.57849" strokeMiterlimit="10"/>
    <path d="M24.0888 26.447C9.75382 29.8873 6.3836 27.5714 6.3836 27.5714C4.85066 27.2016 4.49738 25.8589 4.49738 25.8589C3.17004 15.3574 20.1756 11.5738 20.1756 11.5738" stroke={stroke || "white"} strokeWidth="1.57849" strokeMiterlimit="10"/>
    <path d="M23.8699 26.5045C38.0418 22.4445 39.8359 18.7699 39.8359 18.7699C40.9884 17.6936 40.6351 16.3509 40.6351 16.3509C36.6223 6.55587 19.9567 11.6314 19.9567 11.6314" stroke={stroke || "white"} strokeWidth="1.57849" strokeMiterlimit="10"/>
    </svg>
    
};

export default MacaronIcon;
