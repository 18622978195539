import React from 'react';
import {
    ClosestLocWrapper, WelcomeLabel, GetDrinkPrinterLabel, ModalClosestContainer, EnterButtonWrap,
    SearchButton, LocationIcon, ClosestLocatinLabel, ClosestLocatinAddress, UnderlineLabel
} from '../styles/locationsStyle';
import { LoginButtonComponent } from '../common/LoginButton';
import { Phrase } from '../Phrase';


let ClosestLocation = (props) => {
    const location = props.location;

    const openLocation = () => {
        if (location.shortId) {
            props.goToHomeScreen({ code: location.shortId });
        } else {
            let locData = {
                locationId: location.id,
                organizationId: '1',
                templateId: '1'
            }
            let token = window.btoa(JSON.stringify(locData))
            props.goToHomeScreen({ token: token });
        }
    }

    return (
        <>
            <ClosestLocWrapper>
                <WelcomeLabel id="Welcome">
                    <Phrase Key={"5f76fa5b-198a-481c-89c5-bf79cd0464dd"} default={"Welcome"} />
                </WelcomeLabel>
                <GetDrinkPrinterLabel id="getPrinted">
                    <Phrase Key={"f599568f-49a8-4929-a536-5b026323f309"} default={"Go get your drink printed"} />
                </GetDrinkPrinterLabel>

                <ModalClosestContainer>
                    <LocationIcon />
                    <ClosestLocatinLabel>{location.name}</ClosestLocatinLabel>
                    <ClosestLocatinAddress>{location.address}</ClosestLocatinAddress>

                    <EnterButtonWrap id="ButtonWrap">
                        <SearchButton id="EnterButton" onClick={openLocation}>
                            <Phrase Key={"f70568fc-7307-4b83-b75b-278273b85adc"} default={"ENTER"} />
                        </SearchButton>
                    </EnterButtonWrap>
                </ModalClosestContainer>

                <UnderlineLabel id="ImNotAtThisLocation" onClick={() => props.setShowClosestLocation(false)}>
                    <Phrase Key={"ec6c81fe-b80f-4fa1-ab5a-e0bb9ac92a2e"} default={"I'm not at this location"} />
                </UnderlineLabel>
            <LoginButtonComponent colors={{ main: '#000000', backgroundColor: '#FFFFFF' }} history={props.history}/>
            </ClosestLocWrapper>
        </>
    )
}

export { ClosestLocation };