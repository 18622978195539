import React, { useContext, useRef } from "react";
import { TabActionHelper, WalkthroughContainer } from "./styles";
import { PrintButton } from "../../styles/editStyle";
import { Phrase } from "../../Phrase";
import RightIcon from "./icons/RightIcon";
import UpIcon from "./icons/UpIcon";
import LeftIcon from "./icons/LeftIcon";
import { ContentContext } from "../../../context";

const Walkthrough = ({ onClose, tabActionsRefs }) => {
    const { content } = useContext(ContentContext);
    const instructions = content.edit.texts.instructions;

    const takePhoto = instructions.takePhoto || {};
    const addText = instructions.addText || {};
    const addFrame = instructions.addFrame || {};
    const gotIt = instructions.gotIt || {};

    const editTabRef = useRef();
    const textTabRef = useRef();
    const framesTabRef = useRef();

    const tabActionsHelpersRefs = {
        'edit': editTabRef,
        'texts': textTabRef,
        'frames': framesTabRef,
    }

    const tabActiosTexts = {
        'edit': {
            Icon: RightIcon,
            translationKey: takePhoto.translationKey,
            text: takePhoto.text || ''
        },
        'texts': {
            Icon: UpIcon,
            translationKey: addText.translationKey,
            text: addText.text || '',
        },
        'frames': {
            Icon: LeftIcon,
            translationKey: addFrame.translationKey,
            text: addFrame.text || '',
        }
    }

    const tabActionsHalpers = () => {

        return Object.keys(tabActiosTexts).reduce((acc, action) => {
            if (!!tabActionsRefs[action] && !!tabActionsRefs[action].current) {
                const position = tabActionsRefs[action].current.getBoundingClientRect();
                return [...acc, { ...tabActiosTexts[action], offsetX: position.left + position.width / 2, offsetY: position.top + position.height + 20, action }]
            }

            return acc;
        }, [])
    }

    return <WalkthroughContainer>
        {
            tabActionsHalpers().map(({ Icon, action, translationKey, text, offsetX, offsetY, width }) => {
                return <TabActionHelper key={text} style={{ top: offsetY, left: offsetX - (tabActionsHelpersRefs[action].current ? tabActionsHelpersRefs[action].current.getBoundingClientRect().width / 2 : 69), width }} ref={tabActionsHelpersRefs[action]}>
                    <Icon />
                    <span style={{ marginTop: '15px' }}>
                        <Phrase Key={translationKey} default={text} />
                    </span>
                </TabActionHelper>
            })
        }
        <PrintButton id="printButton" data={{ backgroundColor: '#ffffff', color: '#000000', FontFamily: 'MuktaRegular', FontWeight: 'bold' }} onClick={onClose} style={{ marginBottom: '120px', marginTop: 'auto', width: '70%' }}>
            <Phrase Key={gotIt.translationKey} default={gotIt.text || ""} />
        </PrintButton>
    </WalkthroughContainer>
};

export default Walkthrough;