import React from 'react';
import { ModalLayout, MessageStyle } from '../styles/messageStyle';

let ModalMsg = (props) => {
    return (
        <>
            <ModalLayout>
                <MessageStyle back={props.back}>
                    {props.children}
                </MessageStyle>
            </ModalLayout>
        </>
    )
}

export { ModalMsg };