import React from "react";
import styled from "styled-components";

import { Phrase } from "./Phrase";

export const OrientationWarning = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background: ${({ data }) => data.messageBackgroundColor || "#e86161"};
  :before {
    content: "";
    display: block;
    background: url("orientation.svg") no-repeat center;
    width: 88px;
    height: 88px;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -30%);
  }
  p {
    width: 285px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 126.06%;
    text-align: center;
    color: ${({ data }) => data.generalTextColor || "#FFFFFF"};
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -60%);
  }

  .locationName {
    width: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 126.06%;
    text-align: center;
    color: ${({ data }) => data.generalTextColor || "#FFFFFF"};
    top: 60%;
    left: 50%;
  }
`;

export function Orientation(props) {
  return (
    <OrientationWarning data={props.data || ""}>
      <p>
        <Phrase Key="603447ec-b9e2-49a2-b95e-a59a623216ce" default="WEB APP WORKS IN PORTRAIT MODE ONLY" />
      </p>
      {props.locationName && (
        <p className={"locationName"}>
          <Phrase Key="26d9b510-9816-40a1-81ce-1a3556d35622" default="Location name" />
          {`: ${props.locationName}`}
        </p>
      )}
    </OrientationWarning>
  );
}
