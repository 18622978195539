import React, { useState, useEffect, useLayoutEffect, useRef, useCallback, useContext } from "react";
import { Layer, Rect, Circle, Group } from "react-konva";
import useImage from "use-image";

import { Phrase } from "../../Phrase";
import useDoubleTap from "../../../utils/useDoubleTap";
import { DoubleTapToEdit, DoubleTapToEditText } from "../../styles/textStyle";
import { Frame } from "../FramesMode";
import { TextGroup } from "../TextMode";
import { Stage } from "./Stage";
import { Image, getVWidth, getEditContainerSize } from "../ImageMode";
import { useFrame } from "../../../services/hooks";
import useActiveElement from "./hooks/useActiveElement";
import { ContentContext, ActiveFrameContext, TranslationsContext } from "../../../context";
import { rotate } from "../../../utils/Utils";
import { handleHistoryReplace } from "../../../utils/history";

export function DrawingArea(props) {
  // todo(vmyshko): wtf is that? it was outside the component
  let isRotate = false;

  const { activeFrame } = useContext(ActiveFrameContext);

  const { isMacaroonFrame } = activeFrame || {};

  const { frameUrl } = props;

  const { content } = useContext(ContentContext);
  const { translations } = useContext(TranslationsContext);
  const vWidth = getVWidth();
  const editContainerSize = getEditContainerSize();
  const stageRef = useRef();
  const [zoom, setZoom] = useState(1);
  const [image, imageStatus] = useImage(props.image, "Anonymous");
  const [frameImage, customFrameType, frameIsCustom, frameImageStatus] = useFrame(frameUrl);

  const customFrameRef = useRef();
  const circleRef = useRef();
  const textGroupRef = useRef();
  const textAreaRef = useRef();
  const {
    colors,
    setEditMode,
    editMode,
    cText,
    editTextMode,
    setEditTextMode,
    setImageStatus,
    editable,
    printStatus,
    setFrameImageStatus,
    imageRef,
    originalLayerRef,
    setError,
    fromLibrary,
    textClicksDemo,
    fontColor,
    onImageChange,
  } = props;
  const submitLoading = printStatus;
  const [activeElement, setActiveElementMode] = useActiveElement(editMode);
  const minFont = 28;
  const doubleTapText = content.edit.texts.instructions.doubleTap || "";
  const yourText = content.edit.texts.instructions.yourText || "";
  const stageWidth = window.innerWidth;

  useEffect(() => {
    setFrameImageStatus(frameImageStatus);
  }, [frameImageStatus]);

  useEffect(() => {
    setImageStatus(imageStatus);
    if (imageStatus === "failed") {
      setError(2);
    }
  }, [imageStatus]);

  const turnOnEditMode = useCallback(() => {
    setEditTextMode(true); 
    setEditMode("texts");
    const message = JSON.stringify({
      type: "edit_text_open",
    });
    window.parent.postMessage(message, "*");
  }, [setEditTextMode]);

  useLayoutEffect(() => {}, [textClicksDemo]);

  const doubleTapToEditProps = useDoubleTap(turnOnEditMode);
  props.updatePreviewLayer(originalLayerRef.current, vWidth);
  
  if (!content) {
    handleHistoryReplace(props.history, '/home')
    return null;
  }
  // todo(vmyshko): rename to edit area?

  function getDefaultCircleProps() {
    return {
      x: stageWidth / 2,
      y: vWidth / 2,
      radius: vWidth / 2,
    };
  }

  function getMacaroonCircleProps() {
    // no-macaroon?
    // rad= 1; // rot = 0;
    // macaroon mini-circle is 41% size from big one
    const macRadiusScaleFactor = 0.41;
    // macaroon mini-circle is rotated by 150deg from top
    const macRotationFactor = -150;

    // rotate(stageWidth / 2, vWidth / 2);
    const macRadius = (vWidth / 2) * macRadiusScaleFactor;

    const macBaseX = stageWidth / 2;
    const macBaseY = macRadius;

    const [macX, macY] = rotate(stageWidth / 2, vWidth / 2, macBaseX, macBaseY, macRotationFactor);

    const result = {
      x: macX,
      y: macY,
      radius: macRadius,
    };

    return result;
  }

  const editableAreaCircleProps = isMacaroonFrame ? getMacaroonCircleProps() : getDefaultCircleProps();

  const textRectProps = {
    width: editableAreaCircleProps.radius * 2,
    height: editableAreaCircleProps.radius * 2,
    left: editableAreaCircleProps.x - editableAreaCircleProps.radius,
    top: editableAreaCircleProps.y - editableAreaCircleProps.radius,
  };

  const placeholderTextProps = {
    ...textRectProps,
    fontSize: textRectProps.height / 4,
  };

  function onFrameInteraction() {
    setActiveElementMode("frame");
  }

  return (
    <>
      <Stage
        editable={editable && submitLoading}
        drawingAreaProps={props}
        vWidth={vWidth}
        customFrameNode={customFrameRef.current}
        setIsRotate={(value) => {
          isRotate = value;
        }}
        customFrameType={customFrameType}
        textGroupRef={textGroupRef}
        originalLayerRef={originalLayerRef}
        activeElement={activeElement}
        frameIsCustom={frameIsCustom}
        imageStatus={imageStatus}
        imageRef={imageRef}
        setActiveElementMode={setActiveElementMode}
        stageWidth={stageWidth}
        onImageChange={onImageChange}
      >
        <Layer ref={originalLayerRef}>
          <Circle
            // bg circle
            listening={false}
            x={stageWidth / 2}
            y={vWidth / 2}
            radius={vWidth / 2 - 1}
            fill={"white"}
          />
          {/* uploaded image from photo mode */}
          <Image
            drawingAreaProps={props}
            imageStatus={imageStatus}
            image={image}
            stageRef={stageRef}
            setZoom={setZoom}
            ref={imageRef}
            circleRef={circleRef}
            zoom={zoom}
            vWidth={vWidth}
            isDragable={submitLoading}
            activeElement={activeElement}
            isRotate={isRotate}
            setActiveElementMode={setActiveElementMode}
            editable={editable && submitLoading}
          />
          <Group
            clipFunc={(ctx) => {
              ctx.arc(
                // clip everything outside editable area
                editableAreaCircleProps.x,
                editableAreaCircleProps.y,
                editableAreaCircleProps.radius,
                0,
                Math.PI * 2,
                false
              );
            }}
          >
            {/* user-defined text */}
            <TextGroup
              ref={textGroupRef}
              circleNode={circleRef.current}
              circleProps={editableAreaCircleProps}
              textRectProps={textRectProps}
              //
              isMacaroonFrame={isMacaroonFrame}
              isEditable={submitLoading}
              drawingAreaProps={props}
              text={cText}
              frameIsCustom={frameIsCustom}
              setActiveElementMode={setActiveElementMode}
              activeElement={activeElement}
              vWidth={vWidth}
              isRotate={isRotate}
              minFont={minFont}
              editContainerSize={editContainerSize}
              translations={translations}
            />
          </Group>
          <Frame
            drawingAreaProps={props}
            customFrameType={customFrameType}
            textAreaRef={textAreaRef}
            frameIsCustom={frameIsCustom}
            frameImage={frameImage}
            circleNode={circleRef.current}
            fontSize={minFont}
            vWidth={vWidth}
            ref={customFrameRef}
            editContainerSize={editContainerSize}
            // these are passed trough all into TextPath inside CircleTextFrame
            onClick={onFrameInteraction}
            onTap={onFrameInteraction}
            onTouchStart={onFrameInteraction}
          />
        </Layer>
        <Layer imageSmoothingEnabled>
          <Rect
            // todo(vmyshko): what is this?
            width={stageWidth}
            height={vWidth}
            fill={(colors && colors.backgroundColor) || "#fff"}
            opacity={editMode === "edit" && !fromLibrary ? 0.7 : 1}
            listening={false}
          />
          <Circle
            ref={circleRef}
            listening={false}
            // main frame circle mask - crops overflow
            x={stageWidth / 2}
            y={vWidth / 2}
            radius={vWidth / 2 - 1}
            fill={"#fff"}
            globalCompositeOperation={"destination-out"}
          />

          <Circle
            {...editableAreaCircleProps}
            // dashed 'editable' area
            stroke={"#ccc"}
            strokeWidth={2}
            listening={false}
            dash={[10, 10]}
            visible={!fromLibrary}
          />
        </Layer>
      </Stage>

      {submitLoading && editMode === "texts" && !editTextMode && cText.trim() === "" && (
        // default text, appears only when no text set
        <DoubleTapToEdit
          style={{
            cursor: "pointer",
            ...placeholderTextProps,
          }}
          //
          {...doubleTapToEditProps}
          onDoubleClick={turnOnEditMode}
        >
          <DoubleTapToEditText {...doubleTapText} fontColor={fontColor} twoLines={textClicksDemo === 0} isMacaroonFrame={isMacaroonFrame}>
            {textClicksDemo === 0 ? (
              <Phrase Key={yourText && yourText.translationKey === "" ? "" : "48170d4e-5831-4fbb-abb8-646fe4b21cd8"} default={yourText ? yourText.text : ""} />
            ) : (
              <Phrase Key={yourText && yourText.translationKey === "" ? "" : "48170d4e-5831-4fbb-abb8-646fe4b21cd7"} default={yourText ? yourText.text : ""} />
            )}
          </DoubleTapToEditText>
        </DoubleTapToEdit>
      )}
    </>
  );
}
