import React from "react";

import { PopupMessageStyle, PopupTitleStyle, PopupDescriptionStyle } from "../styles/popupStyle";
import { TextTypes } from "../../primitives/types/Shoutout";
import { ShoutoutTextWrapper, ButtonsWrapper } from ".";
import { CloseBtn } from "../styles/editStyle";
import { MTextPrinted } from "../styles/textStyle";
import { Phrase } from "../Phrase";
import styled from "styled-components";

const ThanksOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export function TextualPopup(props) {
  let { title, body, buttons, hasCloseIcon, selectedBtn, isLoading, showThanks } = props.data;
  let { getDataByLocal, getButtonAction, setSelectedBtn } = props.actions;
  let { generalTextColor, backgroundColor } = props.colors;

  function buttonClick(btnSelection, btnIndex) {
    setSelectedBtn(btnIndex);
    getButtonAction({ btnSelection, actions: props.actions, data: props.data });
  }

  return (
    <PopupMessageStyle background={backgroundColor}>
      {showThanks && (
        <ThanksOverlay>
          <MTextPrinted color={generalTextColor}>
            <Phrase Key={"582b8c07-8f31-4013-a07f-b0e7f116241c"} default={"Thank You!"} />
          </MTextPrinted>
        </ThanksOverlay>
      )}

      <div style={{ visibility: showThanks ? "hidden" : "visible" }}>
        {hasCloseIcon && (
          <CloseBtn
            position="right"
            color={generalTextColor}
            onClick={() => {
              buttonClick({ action: "close" });
            }}
          />
        )}

        {title && (
          <PopupTitleStyle>
            <ShoutoutTextWrapper actions={{ getDataByLocal }} data={{ ...title, textType: TextTypes.PRINTED, generalTextColor, backgroundColor }} />
          </PopupTitleStyle>
        )}

        {body && body.text && (
          <PopupDescriptionStyle>
            <ShoutoutTextWrapper actions={{ getDataByLocal }} data={{ ...body, textType: TextTypes.REGULAR, generalTextColor, backgroundColor, padding: "33px" }} />
          </PopupDescriptionStyle>
        )}

        {buttons && buttons.list.length > 0 && <ButtonsWrapper data={{ buttons, isLoading, selectedBtn }} actions={{ buttonClick, getDataByLocal }} colors={props.colors} />}
      </div>
    </PopupMessageStyle>
  );
}
