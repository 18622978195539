const maxLineLength = 20;
const maxLines = 5;

const split = (s, i = 0) => {
    /**
     * gets a string 'hello good world', returns an array of all the possible combinations:
     * [['hello', 'good', 'world'], ['hello', 'good world'], ['hello good world']]
     */
    if (s.length === i) {
        return [[s]];
    } else if (s[i] === ' ') {
        const firstword = s.substr(0, i);
        const others = s.substr(i + 1, s.length);

        return [...split(others).map(word => [firstword, ...word]), ...split(s, i + 1)]
            .filter(arr => arr.length <= maxLines)
            .filter(arr => arr.every(line => line.length <= maxLineLength));
    } else {
        return split(s, i + 1);
    }
};

const generateRandomLines = (text) => {
    const lines = text.split(/\r?\n/);
    const minLineLength = 3;

    if (lines.length === 1) {
        const allPossibleCombinations = split(lines[0]);
        const possibleCombinationsWithMinLength = allPossibleCombinations.filter(possibleLines => possibleLines.every(line => line.length >= minLineLength));

        if (possibleCombinationsWithMinLength.length > 0) {
            return possibleCombinationsWithMinLength;
        }

        if (allPossibleCombinations.length === 0) {
            return [[text]];
        }

        return allPossibleCombinations;
    } else {
        return [lines];
    }
};

const parseText = (text) => {
    const lines = text.trim().split(/\r?\n/);
    const onlyFirstFiveLines = lines.splice(0, maxLines);
    return onlyFirstFiveLines.join('\n');
};

export {
    generateRandomLines,
    parseText,
};
