import { useContext, useState, useEffect } from "react";

import { ShoutoutContext, ContentContext, MainContext } from "../../context";
import { getLocal } from "../../actions";
import { privacyObject } from "./privacy";
import { getButtonAction } from ".";

function getDataByLocal(item) {
  let origin = getLocal();
  return item[origin] || item["en"];
}

export function usePopup(props) {
  const { setMain } = useContext(MainContext);
  const { content } = useContext(ContentContext);
  const { shoutout, setShoutout } = useContext(ShoutoutContext);
  const [currentShoutout, setCurrentShoutout] = useState({});
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [isPopUpOpen, setIsPopUpOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBtn, setSelectedBtn] = useState(null);
  const [showThanks, setShowThanks] = useState(false);
  const { privacy } = props;

  useEffect(() => {
    if (privacy) {
      setCurrentShoutout(privacyObject);
      return;
    }
    setCurrentShoutout(shoutout);
  }, [shoutout, privacy]);

  function setUserAnswer() {
    setMain((prevMain = {}) => ({ ...prevMain, isUserAnswer: true }));
  }

  if (!content || (!shoutout && !privacy)) {
    return {};
  }

  let { locationId } = content;

  // from skin
  const {
    //
    generalTextColor,
    generalColor,
    iconsColor,
    backgroundColor,
  } = content.general.colors || {};


  const button = content.home.buttons.snap || content.home.buttons.library;

  const overlayColor = "white";

  const loaderColor = button.textColor;

  // from shoutout
  const { hasCloseIcon } = currentShoutout.general || {};

  return {
    actions: {
      setShoutout,
      setIsPopUpOpen,
      setIsLoading,
      setIsCardVisible,
      getDataByLocal,
      getButtonAction,
      setSelectedBtn,
      setShowThanks,
      setUserAnswer,
    },
    data: {
      ...currentShoutout,
      isLoading,
      isCardVisible,
      isPopUpOpen,
      locationId,
      selectedBtn,
      showThanks,
      hasCloseIcon,
    },
    colors: {
      generalTextColor,
      generalColor,
      iconsColor,
      loaderColor,
      overlayColor,
      backgroundColor,
      buttonBackground : button.backgroundColor,
      buttonTextColor: button.textColor,
    },
  };
}
