import React from "react";

const TwoMacaronIcon = ({style, stroke}) => {
    return <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M30.5124 32.0031C29.8876 38.1816 18.2943 37.4874 18.2943 37.4874C6.21505 37.4874 6.00679 32.4891 6.00679 32.0031C5.93737 31.5172 6.42331 31.0312 6.42331 31.0312C7.60347 32.3502 11.2134 33.2527 11.2134 33.2527C11.2134 33.2527 14.8927 34.1552 18.1555 34.0164C21.4182 33.8081 27.3885 33.3221 29.957 31.1007C30.5124 31.4478 30.5124 32.0031 30.5124 32.0031Z" stroke={stroke || "black"} strokeWidth="1.21487" strokeMiterlimit="10"/>
    <path d="M30.0268 30.962C27.4582 33.2529 21.488 33.7389 18.2252 33.9471C14.9624 34.1554 11.2831 33.1835 11.2831 33.1835C11.2831 33.1835 7.6732 32.281 6.42362 30.962C5.3823 29.8513 6.56246 29.0182 6.77072 28.8794C6.84014 28.8794 6.84014 28.9488 6.90956 28.9488C6.90956 28.9488 8.64509 30.962 18.2252 31.1703C18.2252 31.1703 23.7824 31.3321 28 29.6581" stroke={stroke || "black"} strokeWidth="1.21487" strokeMiterlimit="10"/>
    <path d="M24.9996 22.1371C23.3177 21.5836 21.1138 21.2085 18.2249 21.1737C18.2249 21.1737 6.90931 20.2713 6.00684 27.5605C6.00684 27.491 6.00684 28.4629 6.90931 28.9489C6.90931 28.9489 8.64483 30.9621 18.2249 31.1704C18.2249 31.1704 23.7821 31.2783 27.9996 29.6391" stroke={stroke || "black"} strokeWidth="1.21487" strokeMiterlimit="10" strokeLinejoin="round"/>
    <path d="M25.9308 21.7288C25.9308 21.7288 25.306 21.937 25.0978 22.3535C24.8895 22.8395 22.5986 27.3519 33.1506 33.1138C33.1506 33.1138 42.9389 39.3617 46.4794 34.294C46.4794 34.294 46.8265 33.808 46.5488 33.1138" stroke={stroke || "black"} strokeWidth="1.21487" strokeMiterlimit="10"/>
    <path d="M27.389 20.1321C27.0419 20.1321 25.6534 20.2709 26.0006 21.7982C26.3477 23.4643 29.1245 26.0329 29.1245 26.0329C29.1245 26.0329 31.9708 28.6014 34.8864 30.0593C37.8021 31.4477 43.2864 33.9469 46.6186 33.1832C46.6186 33.1832 48.2153 32.7667 47.3128 31.2394" stroke={stroke || "black"} strokeWidth="1.21487" strokeMiterlimit="10"/>
    <path d="M27.3884 20.2016C27.3884 20.2016 27.9438 22.7702 36.2049 27.6297C36.2049 27.6297 42.3833 31.2396 47.2428 31.1701C47.2428 31.1701 48.3535 31.1007 48.6312 30.4065C48.9089 29.7123 50.9915 24.6446 41.0643 18.8826C41.0643 18.8826 31.6231 12.6347 27.2496 18.5355C27.2496 18.4661 26.833 19.2991 27.3884 20.2016Z" stroke={stroke || "black"} strokeWidth="1.21487" strokeMiterlimit="10"/>
    </svg>
    
};

export default TwoMacaronIcon;
