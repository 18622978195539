import React, { useContext, useRef } from "react";

import {
  //
  TextFontsTabs,
  TextTab,
  ColorChange,
  TextChange,
  ButtonsContainer,
  TextHowTo,
  EditText,
  EditSelection,
  ImageWrapper,
} from "../../styles/editStyle";
import { ContentContext } from "../../../context";
import useSaveLeftScrollPosition from "../../../utils/useSaveScrollLeftPosition";
import { textOptionsAllLang, textOptionsJP } from "./Fonts";

export function TextModeSettings({ data }) {
  let {
    filterButtons,
    printStatus,
    setcTextFont,
    textClicks,
    movePhotoStyle,
    setTextClicks,
    activeFont,
    setActiveFont,
    fontScroll,
    setFontsScroll,
    general,
    setFontColor,
    fontColor,
    newColors,
    cText,
    possibleCombinations,
    textClicksDemo,
    setTextClicksDemo,
  } = data;

  const { content } = useContext(ContentContext);
  const textTabRef = useRef();
  const textModeStyle = { ...movePhotoStyle, translationKey: "48f48269-66f9-40e1-9cbc-8de4e5c1c871", text: "Tap on style to see more!" };
  const { containerRef: tabContainerRef, onScroll } = useSaveLeftScrollPosition(fontScroll, setFontsScroll);

  if (!content || !content.edit) {
    return null;
  }

  const langs = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage;
  const origin = langs.split("-")[0];
  const textOptions = origin === "ja" ? textOptionsJP : textOptionsAllLang;
  const doubleTapText = content.edit.texts.instructions.doubleTap || {};

  function textButtonClick() {
    if (textClicksDemo < 1) {
      setTextClicksDemo(textClicksDemo + 1);
    } else {
      setTextClicksDemo(0);
    }

    if (!printStatus) return;
    if (textClicks < possibleCombinations.length - 1) {
      setTextClicks(textClicks + 1);
      return;
    }

    setTextClicks(0);
  }

  const isRealText = cText.trim() !== "";

  return (
    <EditText style={textModeStyle}>
      <ButtonsContainer>
        {cText.trim() === "" && <TextHowTo colors={newColors} doubleTapText={doubleTapText} />}
        <TextChange textClicks={isRealText ? textClicks : textClicksDemo} colors={newColors} onClick={() => textButtonClick()} />
        <ColorChange
          colors={newColors}
          data={filterButtons ? filterButtons.general : null}
          disabled={!printStatus}
          onClick={() => printStatus && (fontColor === "black" ? setFontColor("white") : setFontColor("black"))}
        />
      </ButtonsContainer>
      <TextFontsTabs size={textOptions.length} ref={tabContainerRef} onScroll={onScroll}>
        {textOptions.map((e, i) => {
          return (
            <div key={e.fontFamily}>
              <EditSelection colors={general.colors} isActive={i === activeFont}>
                <ImageWrapper size={"80"}>
                  <TextTab
                    key={i}
                    src={e.location}
                    ref={textTabRef}
                    // isActive={i === activeFont}
                    data={filterButtons ? filterButtons.general : null}
                    onClick={
                      printStatus
                        ? () => {
                            if (i !== activeFont) {
                              setActiveFont(i);
                              setcTextFont(e.fontFamily);
                            }
                          }
                        : null
                    }
                  />
                </ImageWrapper>
              </EditSelection>
            </div>
          );
        })}
      </TextFontsTabs>
    </EditText>
  );
}
