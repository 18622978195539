import { createContext } from "react";

export const ContentContext = createContext(null);
export const CampaignContext = createContext(null);
export const LocationFramesContext = createContext([]);
export const FramesContext = createContext(null);
export const LibraryContext = createContext(null);
export const MainContext = createContext(null);
export const ActiveFrameContext = createContext(null);
export const ShoutoutContext = createContext(null);
export const TranslationsContext = createContext({});

// todo(vmyshko): make context provider wrapper + init here, and reuse in app
