import React, { useEffect, useState, useContext } from "react";
import {
  MainWrapper,
  ScrollableWrapper,
  Header,
  HeaderWrapper,
  LogoSpan,
  WelcomeContainer,
  Separator,
  WelcomeLabel,
  GetDrinkPrinterLabel,
  ScrollableContent,
  RipplesLogo,
} from "./styles/locationsStyle";

import queryString from "query-string";

import { Phrase } from "./Phrase";
import { getCampaignByCampaignShortId, getLocationsByCampaignShortId, getNearLocationsList } from "../actions";
import { LoginButtonComponent } from "./common/LoginButton";
import { ClosestLocation, NoLocationsFound, LoadingIndicatorFullScreen, LocationItem } from "./locations/index";
import { ContentContext } from "../context";
import { GaEvents, sendGAEvent } from "../utils/googleAnalytics";
import { sample } from "lodash";

export default function Locations(props) {
  const { setContent } = useContext(ContentContext);
  const [locationsList, setLocationsList] = useState([]);

  const [showClosestLocation, setShowClosestLocation] = useState(false);
  const [errorCode, setErrorCode] = useState(0);
  const [closestLocation, setClosestLocation] = useState(false);
  const [loading, setLoading] = useState(true);
  const queryStrings = queryString.parse(props.location.search);

  const [campaignIsNotStarted, setCampaignIsNotStarted] = useState(false);
  const [campaignIsFinished, setCampaignIsFinished] = useState(false);
  const [campaignName, setCampaignName] = useState(null);

  const headersList = [
    "https://res.cloudinary.com/ripples/image/upload/v1599381280/webapp/splash/locations_top_background3.jpg",
    "https://res.cloudinary.com/ripples/image/upload/v1599381280/webapp/splash/locations_top_background2.jpg",
    "https://res.cloudinary.com/ripples/image/upload/v1599381280/webapp/splash/locations_top_background1.jpg",
  ];

  const [randomHeaderImage] = useState(sample(headersList));

  function goToHomeScreen(data) {
    const { addr, token, demo, code: shortId, campaign: campaignShortId, withCode } = data;

    props.history.push({
      pathname: "/home",
      state: {
        addr,
        token,
        shortId,
        campaignShortId,
        demo,
        withCode,
      }
    });
  }

  function goToHomeScreenFromIFrame(data) {
    const { locationId, templateId, iFrame } = data;

    props.history.push({
      pathname: "/home",
      state: {
        templateId,
        locationId,
        iFrame: !!iFrame,
      },
    });
  }

  function handlePositionCoords(pos) {
    setLoading(true);

    getNearLocationsList(pos.coords.latitude, pos.coords.longitude)
      .then((data) => {
        setLoading(false);
        if (!data || !data.locations || !data.locations.length) {
          return;
        }
        let locationListRes = data.locations;
        setLocationsList(locationListRes);
        renderClosestLocation(locationListRes);
      })
      .catch((e) => {
        setLoading(false);
        setErrorCode(e.code);
      });
  }

  function onError(e) {
    setLoading(false);
    setErrorCode(e.code);
  }

  const optionsForGettingCoordinates = {
    enableHighAccuracy: true,
    timeout: 7000,
    maximumAge: 0,
  };

  function searchLocations() {
    navigator.geolocation.getCurrentPosition(handlePositionCoords, onError, optionsForGettingCoordinates);
  }

  async function processCampaign(campaignShortId) {
    setLoading(true);

    const campaign = await getCampaignByCampaignShortId(campaignShortId).catch((err) => {
      setErrorCode(err.code);
    });

    if (!campaign) {
      setLoading(false);
      return;
    }

    const isNotStarted = Date.now() < new Date(campaign.startDate);
    const isFinished = Date.now() > new Date(campaign.endDate);
    setCampaignIsNotStarted(isNotStarted);
    setCampaignIsFinished(isFinished);
    setCampaignName(campaign.name);

    if (isNotStarted || isFinished) {
      setLoading(false);
      return;
    }

    navigator.geolocation.getCurrentPosition((pos) => {
      const latitude = pos.coords.latitude; 
      const longitude = pos.coords.longitude;
      getLocationsByCampaignShortId(campaignShortId, longitude, latitude, false)
      .then((locations) => {
        setLoading(false);

        if (!locations || !locations.length) {
          return;
        }

        if (isAllLocationsInActive(locations)) {
          setErrorCode(3)
          return;
        }

        // todo(vmyshko): if only one location -- redirect to it?
        const activeLocations = locations.filter(({status}) => status === 'active');
        setLocationsList(activeLocations);
        renderClosestLocation(activeLocations);
      })
      .catch((e) => {
        setLoading(false);
        setErrorCode(e.code);
      });
    }, onError, optionsForGettingCoordinates);
  }

  function isAllLocationsInActive(locations) {
    return locations.every(({ status }) => status === 'inactive')
  }

  useEffect(() => {
    sendGAEvent(GaEvents.SearchLocation, {
      eventCategory: "locations",
      eventLabel: "visitGenericPage",
    });

    setContent({});

    if (queryStrings["coord"]) {
      const pos = queryStrings["coord"].split(",").map((e) => parseFloat(e));
      handlePositionCoords({ coords: { latitude: pos[0], longitude: pos[1] } });
      return;
    }

    if (queryStrings["campaign"]) {
      const campaignShortId = queryStrings["campaign"];

      processCampaign(campaignShortId);
      return;
    }

    if (Object.keys(queryStrings).length > 0) {
      return;
    }

    searchLocations();
  }, []);

  function renderClosestLocation(locationsList) {
    if (!locationsList.length) {
      throw new Error("no locations");
    }

    let closestLocation = locationsList.filter((location) => location.distance < 100);

    if (closestLocation.length === 0) {
      return;
    }

    setClosestLocation(closestLocation[0]);
    setShowClosestLocation(true);
  }

  if (Object.keys(queryStrings).length > 0 && queryStrings["iFrame"]) {
    goToHomeScreenFromIFrame(queryStrings);
  } else if (
    Object.keys(queryStrings).length > 0 &&
    //
    !queryStrings["coord"] &&
    !queryStrings["campaign"]
  ) {
    goToHomeScreen({...queryStrings, withCode: true});
  }

  let overlay = document.getElementById("root");
  overlay.setAttribute("style", `background-color:#fff`);

  const campaignIsNotAvailable = campaignIsFinished || campaignIsNotStarted;

  function campaignStub() {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{ margin: "60px 0", fontSize: 35, fontFamily: "MuktaRegular", textAlign: "center" }}>{campaignName || "-"}</div>
        <div style={{ fontSize: 22, fontFamily: "Brandon Grotesque regular" }}>
          {campaignIsNotStarted && <Phrase Key={"93b8e616-4718-405c-a160-59a0fad42442"} default={"Campaign is about to start!"} />}
          {campaignIsFinished && <Phrase Key={"a7670cfc-b97f-45ff-ab16-895b0d9b1437"} default={"Campaign finished!"} />}
        </div>
      </div>
    );
  }

  return (
    <MainWrapper id="mainLoc">
      {loading ? (
        <LoadingIndicatorFullScreen />
      ) : errorCode || (locationsList.length === 0 && !campaignIsNotAvailable) ? (
        <NoLocationsFound onSearch={searchLocations} errorCode={errorCode} history={props.history} />
      ) : showClosestLocation ? (
        <ClosestLocation history={props.history} location={closestLocation} goToHomeScreen={goToHomeScreen} setShowClosestLocation={setShowClosestLocation} />
      ) : (
        <ScrollableWrapper>
          <HeaderWrapper>
            <Header id="hdrLocations" img={randomHeaderImage} />
            <LogoSpan>
            <RipplesLogo width={60} height={60} />
            </LogoSpan>
            <LoginButtonComponent colors={{ main: "#000000", backgroundColor: "#FFFFFF" }} history={props.history} />
          </HeaderWrapper>

          {campaignIsNotAvailable ? (
            campaignStub()
          ) : (
            <WelcomeContainer id="welcomeCont">
              <WelcomeLabel id="Welcome">
                <Phrase Key={"5f76fa5b-198a-481c-89c5-bf79cd0464dd"} default={"Welcome"} />
              </WelcomeLabel>
              <GetDrinkPrinterLabel id="getPrinted">
                <Phrase Key={"f599568f-49a8-4929-a536-5b026323f309"} default={"Go get your drink printed"} />
              </GetDrinkPrinterLabel>
              <ScrollableContent>
                {locationsList.map((location) => {
                  return (
                    <React.Fragment key={location.id}>
                      <Separator />
                      <LocationItem open={locationsList.length === 1} item={location} goToHomeScreen={goToHomeScreen} />
                    </React.Fragment>
                  );
                })}
              </ScrollableContent>
            </WelcomeContainer>
          )}
        </ScrollableWrapper>
      )}
    </MainWrapper>
  );
}
